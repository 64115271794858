import React from 'react'
import { Input } from '@enterprise-ui/canvas-ui-react'

interface IMakeAssetPrivatePublic {
  makeAssetPublic: any
  assetPublic: boolean
}
const AssetPublicPrivate = (props: IMakeAssetPrivatePublic) => {
  const { makeAssetPublic, assetPublic } = props

  const PublicPrivateAssetOptions = [
    {
      label: 'Public asset (all Gallery users can access)',
      value: 'Public',
    },
    {
      label: 'Private asset (only users with permission can access)',
      value: 'Private',
    },
  ]

  return (
    <>
      <Input.Label className="bold-asset-text">Asset permissions</Input.Label>
      <Input.Radio
        id="asset-permissions"
        data-testid="new-asset-permissions"
        value={assetPublic ? 'Public' : 'Private'}
        onUpdate={(id: any, value: any) => {
          makeAssetPublic(value === 'Public')
        }}
        options={PublicPrivateAssetOptions}
      />
    </>
  )
}

export default AssetPublicPrivate
