import { configureStore, createAction, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {
  user,
  crumbReducer,
  taxonomy,
  modal,
  toast,
  collections,
  dialog,
  tags,
  asset,
  files,
  search,
  featureFlags,
  env,
} from 'store'
import { featureFlagMiddleware } from './featureFlag/middleware'
import rootSaga from './rootSaga'

export const startGallery = createAction('START_GALLERY')
// Collections, Assets, Crumbs, Taxonomies (Categories ), User, Toast Reducers

const sagaMiddleWare = createSagaMiddleware({})
const store = configureStore({
  reducer: {
    user,
    crumbs: crumbReducer,
    taxonomy,
    dialog,
    toast,
    modal,
    asset,
    collections,
    tags,
    files,
    search,
    featureFlags,
    env,
  },
  // TODO: Create a way for each slice to opt into the ignoredActions option easier.
  middleware: [...getDefaultMiddleware({ serializableCheck: false }), sagaMiddleWare, featureFlagMiddleware],
  devTools: true,
})

sagaMiddleWare.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
