import React from 'react'

import { Form, Card, Input, ExpandableSection, Divider, Avatar, Grid } from '@enterprise-ui/canvas-ui-react'
import { some } from 'lodash'
import { convertToTitle, convertToProperCase, capitalizeAcronymInSentence } from 'utils/string'
import './FilterItem.scss'
import { useSearch, useFilter, IFilterItemUI, IFilterValueUI } from 'store'

type FilterItemProps = {
  filterItemUI: IFilterItemUI
  startExpanded: boolean
  showInput: boolean
}

const FilterItem: React.FC<FilterItemProps> = ({ filterItemUI, startExpanded, showInput }) => {
  const { setFilterInput, filterInput, filteredCheckboxes, numberOfFilters } = useFilter(filterItemUI)
  const { selectFilter } = useSearch()
  return (
    <Card elevation={0} className="hc-pr-normal filter-card">
      <div className="hc-pa-normal">
        <Grid.Container direction="column" spacing="normal">
          {
            <ExpandableSection startExpanded={startExpanded} className="filter-drawer">
              {
                <Grid.Container spacing="dense">
                  <Grid.Item>
                    <p data-test-id="filter-category" className="filter-category-title">
                      {convertToTitle(filterItemUI.filterKeyUI, '_')}
                    </p>
                  </Grid.Item>
                  {numberOfFilters > 0 && (
                    <Grid.Item>
                      <Avatar aria-label="number of filters" data-testid="filter-avatar" className="filter-avatar">
                        {numberOfFilters}
                      </Avatar>
                    </Grid.Item>
                  )}
                </Grid.Container>
              }
              <ExpandableSection.Content>
                <Form>
                  {showInput && (
                    <Grid.Item xs={12}>
                      <Input.Text
                        data-testid="filter-input"
                        type="text"
                        value={filterInput}
                        placeholder={`${convertToTitle(filterItemUI.filterKeyUI, '_')} ID or Name`}
                        expanded={true}
                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                          setFilterInput(event.currentTarget.value)
                        }}
                      />
                    </Grid.Item>
                  )}

                  {some(filteredCheckboxes) && (
                    <Grid.Item xs={12} className="hc-ov-auto hc-ml-min hc-mt-dense checkbox-container">
                      {filteredCheckboxes!!.map((filterValueUI: IFilterValueUI) => (
                        <Input.Checkbox
                          key={`${filterItemUI.filterKeyUI}-${filterValueUI.filterValue.value}`}
                          // Perhaps we return an id with every combination in response? For now the tag value will be unique for each dropdown section
                          id={`${filterItemUI.filterKeyUI}-${filterValueUI.filterValue.value}`}
                          data-testid="filter-checkbox"
                          label={
                            filterValueUI.filterValue.displayValue
                              ? filterValueUI.filterValue.displayValue
                              : capitalizeAcronymInSentence(convertToProperCase(filterValueUI.filterValue.value))
                          }
                          className="filter-checkbox"
                          checked={filterValueUI.selected}
                          onChange={() => {
                            selectFilter(filterItemUI.filterKeyUI, filterValueUI.filterValue.value)
                          }}
                        />
                      ))}
                    </Grid.Item>
                  )}
                </Form>
              </ExpandableSection.Content>
            </ExpandableSection>
          }
        </Grid.Container>
      </div>
      <Divider />
    </Card>
  )
}

export default FilterItem
