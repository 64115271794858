import React, { useEffect, useState } from 'react'

import {
  Anchor,
  Button,
  Form,
  Grid,
  GridContainer,
  GridItem,
  Input,
  Modal,
  Spinner,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { some } from 'lodash'
import styles from './AddAssetsToExistingCollectionModal.module.scss'
import { useCollectionThumbs } from 'hooks'
import GalleryCollectionImage from 'components/cards/GalleryCardImage'
import { useNavigate } from 'react-router-dom'
import { CollectionSort, IAsset, ICollection, processError, useBreadCrumbs, useCollections } from 'store'
import InfiniteScroll from 'react-infinite-scroll-component'
import { collectionSearchInputSanitizer, sanitize } from 'utils/validation'
import EnterpriseIcon, { TrashIcon } from '@enterprise-ui/icons'
import CollectionSearchBar from '../../pages/CollectionsPage/CollectionSearchBar'

interface IAddAssetsToExistingCollectionValues {
  name: string
  assets_being_added?: IAsset[]
  collection_to_edit_index?: number
  create_collection_opened: boolean
}

type IAddAssetsToExistingCollectionProps = {
  closeModal: () => void
  data?: any
  container: boolean
  visible: boolean
  onCreateNewCollection: () => void
}

const AddAssetsToExistingCollectionModal: React.FC<IAddAssetsToExistingCollectionProps> = ({
  closeModal,
  data,
  container,
  visible,
  onCreateNewCollection,
}) => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [buttonContent, setButtonContent] = useState<String>('')
  const [myCollections, setMyCollections] = useState<ICollection[]>()
  // TODO: Get rid of ownCollections, publicCollections and favoriteCollections in favor of just one set of collections.
  const { getOwnCollections, ownCollections, addAssetsToGallery, resetMyCollection, getCollectionSortOrder } =
    useCollections()
  const [collectionSort, setCollectionSort] = useState<CollectionSort>(CollectionSort.LATEST_DESC)
  const { collectionThumbs } = useCollectionThumbs()
  const { cleanBreadCrumbs } = useBreadCrumbs()

  const onAssetAddedToCollection = (collectionId: string) => {
    cleanBreadCrumbs()
    navigate(`/collection/${collectionId}`, { replace: true })
  }

  const infiniteScrollMethod = () => {
    const { criteria } = ownCollections
    if (criteria) {
      getOwnCollections(criteria?.q!!, criteria?.p!! + 1, criteria?.size, criteria?.sort)
    }
  }

  const handleSearch = (searchTerm: string) => {
    const sortOrder = getCollectionSortOrder(collectionSort)
    const sanitizedSearchTerm = sanitize(searchTerm, collectionSearchInputSanitizer)
    setSearchTerm(sanitizedSearchTerm)
    getOwnCollections(sanitizedSearchTerm, 0, 10, sortOrder)
  }

  useEffect(() => {
    if (some(data)) {
      // Only load collections if the modal was supplied assets.
      getOwnCollections('', 0, 10, [])
    }
    return () => resetMyCollection()
  }, [])

  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm, collectionSort])

  useEffect(() => {
    setMyCollections(ownCollections.feed as ICollection[])
  }, [ownCollections.feed])

  // FORMIK LOGIC
  const initialValues: IAddAssetsToExistingCollectionValues = {
    name: '',
    assets_being_added: data,
    collection_to_edit_index: -1,
    create_collection_opened: false,
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: IAddAssetsToExistingCollectionValues) => {
      const { assets_being_added, collection_to_edit_index } = values
      setIsSubmitting(true)
      let collectionId: string
      let message: string

      if (myCollections && collection_to_edit_index !== undefined && assets_being_added !== undefined) {
        collectionId = myCollections[collection_to_edit_index].id
        // adds 1 or many assets to collection
        await addAssetsToGallery(
          collectionId,
          assets_being_added.map(asset => asset.id),
          onAssetAddedToCollection
        )
      } else {
        message =
          'Something went wrong and collections and/or assets cannot be found.  You may want to report this issue.'
        const error = new Error(message)
        processError(error, error.message, true)
      }
    },
    validate: (values: IAddAssetsToExistingCollectionValues) => {
      const errors: any = {}
      if (values.assets_being_added === undefined || values.assets_being_added.length <= 0) {
        errors.assets_being_added = 'Must have assets being added, maybe you want to create a new collection instead.'
      }
      if (values.collection_to_edit_index === undefined || values.collection_to_edit_index < 0) {
        errors.collection_to_edit_index = 'A collection must be selected before you can submit'
      }
      return errors
    },
    validateOnMount: false,
    isInitialValid: false,
  })

  const { collection_to_edit_index, assets_being_added } = formik.values

  const formValidation = async () => {
    formik.validateForm()
  }

  useEffect(() => {
    formValidation()
  }, [collection_to_edit_index, assets_being_added, visible])

  useEffect(() => {
    ;(assets_being_added || []).length > 1 ? setButtonContent('Add assets') : setButtonContent('Add asset')
  }, [assets_being_added])

  let getContainer = () => {
    return (
      <div className="hc-pa-normal">
        <div className={`hc-pa-normal ${styles['add-to-collection-container']} hc-pa-none`}>
          <Form
            onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
              e.preventDefault()
              handleSearch(searchTerm)
            }}
          >
            <GridContainer className={`${styles['add-to-collection-select']} hc-pa-normal`}>
              <GridItem xs={7} id={styles['collection-search-container']}>
                <CollectionSearchBar
                  placeholderText="Search collection"
                  setCollectionSort={setCollectionSort}
                  setSearchQuery={setSearchTerm}
                  className="modal-search"
                ></CollectionSearchBar>
                {myCollections && (
                  <div
                    className={`hc-mt-md ${styles['add-to-collection-list']} hc-ov-auto`}
                    data-test-id="add-to-collection-selection-list"
                    id="collections-container"
                  >
                    {/* Loading and zero state when collections is empty */}
                    {!myCollections.length && (
                      <Grid.Container className="hc-pv-normal hc-clr-grey03" justify="center">
                        {!!ownCollections.isLoading && (
                          <div data-testid="fetch-collections-spinner">
                            <Spinner className="spinner" />
                          </div>
                        )}
                        {!ownCollections.isLoading && (
                          <h2 data-testid="modal-no-collections" className={styles['no-assets-h2']}>
                            No collections were found.
                          </h2>
                        )}
                      </Grid.Container>
                    )}
                    {/* Rendered infinite scroll collections */}
                    {!!myCollections.length && (
                      <InfiniteScroll
                        dataLength={myCollections.length}
                        next={infiniteScrollMethod}
                        hasMore={ownCollections.total!! > myCollections.length}
                        loader={
                          <Grid.Container className="hc-pv-normal" justify="center">
                            <Spinner className={styles.spinner} />
                          </Grid.Container>
                        }
                        scrollableTarget="collections-container"
                        style={{ overflow: 'hidden' }}
                        data-testid="modal-collections-results"
                      >
                        {myCollections.map((collection: ICollection, index: number) => {
                          if (collection) {
                            let selected = collection_to_edit_index === index
                            let imageFiles = collectionThumbs(collection)
                            let radioClassNames = `hc-mb-dense ${styles['collection-select-box']} ${
                              selected ? `${styles['border-select']}` : `${styles['border-not-select']}`
                            }`
                            return (
                              <div
                                key={index}
                                data-test-id="collection-select"
                                className={radioClassNames}
                                onClick={() => {
                                  if (selected) {
                                    formik.setFieldValue('collection_to_edit_index', -1)
                                    formik.setFieldValue('name', undefined)
                                  } else {
                                    formik.setFieldValue('collection_to_edit_index', index)
                                    formik.setFieldValue('name', collection.name)
                                  }
                                }}
                              >
                                <Grid.Container direction="row" justify="space-between" noWrap={true} align="center">
                                  <Grid.Item>
                                    <Grid.Container direction="row" noWrap={true} justify="none" align="center">
                                      <Grid.Item className="hc-pr-none"></Grid.Item>
                                      <Grid.Item
                                        style={{ maxWidth: '200px' }}
                                        className="hc-ta-center hc-pl-dense hc-ws-no-wrap hc-ov-hidden hc-to-ellipsis"
                                      >
                                        {collection?.name}
                                      </Grid.Item>
                                    </Grid.Container>
                                  </Grid.Item>
                                  {imageFiles.imageCount > 0 && (
                                    <Grid.Item className="hc-pr-xl">
                                      <Grid.Container direction="row" noWrap={true} align="center">
                                        {imageFiles.imageFiles.map((imageFile, id) => (
                                          <Grid.Item
                                            key={id}
                                            className="hc-pr-none hc-pl-dense"
                                            data-testid={`${collection.name}-image-${id}`}
                                          >
                                            <GalleryCollectionImage
                                              key={id}
                                              file={imageFile}
                                              thumbnailLayoutNumber={1}
                                              id={id}
                                              width={'60px'}
                                              height={'60px'}
                                            />
                                          </Grid.Item>
                                        ))}
                                      </Grid.Container>
                                    </Grid.Item>
                                  )}
                                </Grid.Container>
                              </div>
                            )
                          } else {
                            return undefined
                          }
                        })}
                      </InfiniteScroll>
                    )}
                  </div>
                )}
                {!myCollections && (
                  <div className={`hc-mt-md ${styles['add-to-collection-list']}`}>
                    <Spinner className={styles.spinner} />
                  </div>
                )}
              </GridItem>
              <GridItem xs={5}>
                <Input.Label className="hc-fs-sm" data-test-id="create-collection-assets-header">
                  <strong> Assets being added ({assets_being_added ? assets_being_added.length : 0}) </strong>
                </Input.Label>
                {assets_being_added && assets_being_added.length > 0 && (
                  <div className={`${styles['asset-list']} hc-pr-dense hc-ov-auto`}>
                    <Table>
                      <Table.Body>
                        {(assets_being_added || []).map((asset, index) => (
                          <Table.Row key={asset.id} align="center">
                            <Table.Data
                              xs={11}
                              className="hc-pb-dense hc-fs-xs"
                              data-test-id="create-collection-asset-name"
                            >
                              {asset.name}
                            </Table.Data>
                            <Table.Data xs={1}>
                              <Button
                                data-test-id="create-collection-remove-asset-button"
                                iconOnly
                                disabled={isSubmitting}
                                className="hc-bg-black"
                                size="dense"
                                onClick={() => {
                                  const newCollectionAssets = assets_being_added.filter(
                                    collectionAsset => collectionAsset.id !== asset.id
                                  )
                                  formik.setFieldValue('assets_being_added', newCollectionAssets)
                                }}
                              >
                                <EnterpriseIcon icon={TrashIcon} />
                              </Button>
                            </Table.Data>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                )}
              </GridItem>
            </GridContainer>
          </Form>
        </div>
        <Grid.Container align="center" justify="space-between" className="hc-ph-md hc-pt-normal">
          <Grid.Item className="hc-ph-none">
            <Anchor data-test-id="create-new-collection-anchor" onClick={onCreateNewCollection}>
              Create a new collection
            </Anchor>
          </Grid.Item>
          <Grid.Item className="hc-pa-normal">
            <Grid.Container>
              <Button
                className="hc-fs-xs hc-mr-dense"
                onClick={() => closeModal()}
                data-test-id="cancel-collection-creation-button"
              >
                Cancel
              </Button>
              <Button
                className={`hc-fs-xs hc-ml-dense ${styles['add-asset-button']}`}
                onClick={() => formik.submitForm()}
                disabled={isSubmitting || !formik.isValid}
                data-test-id="update-collection-button"
                type="primary"
              >
                {isSubmitting && <Spinner size="dense" />}
                {!isSubmitting && buttonContent}
              </Button>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  }

  if (!container) {
    return (
      <Modal
        className={`${styles['add-to-collection-modal']} hc-pb-dense`}
        headingText="Add to collection"
        isVisible={visible}
        onRefuse={() => closeModal()}
        onApproveModal={() => {}}
      >
        {getContainer()}
      </Modal>
    )
  }
  if (!visible) return <></>
  return getContainer()
}

export default AddAssetsToExistingCollectionModal
