import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'
import { useSelector, useDispatch } from 'react-redux'
import {
  taxonomyLoadingSelector,
  taxonomiesSelector,
  subcategoryPageAssetsSelector,
  carouselItemsSelector,
  subcategoryPageAssetsIsLoadingSelector,
  swatchGenerationTaxonomyIdsSelector,
} from './selectors'
import {
  getCarouselItemsReducer,
  getCategoryAssets,
  setCarouselItems,
  setCategoryAssets,
  setCategoryAssetStatus,
  setCategoryId,
  setSwatchGenerationTaxonomyIds,
} from './slice'
import { ImageStatus, ITaxonomyMethods } from './types'
import { useState, useEffect } from 'react'
import { useEnv } from '@praxis/component-runtime-env'

export const findCategory = async (
  taxonomy: TaxonomyNodeView,
  name: string,
  paths: TaxonomyNodeView[] = []
): Promise<TaxonomyNodeView[]> => {
  const getChildCategories = (subTaxonomy: TaxonomyNodeView, newPaths: TaxonomyNodeView[]) => {
    return findCategory(subTaxonomy, name, newPaths)
  }

  const evaluateCurrentTaxonomy = async (res: (tax: TaxonomyNodeView[]) => void, rej: (msg: any) => void) => {
    if (taxonomy && name && taxonomy.name.toLowerCase() === name.toLowerCase()) {
      paths = [...paths, taxonomy]
      res(paths)
      return
    }

    const { children } = taxonomy

    if (children?.length !== 0) {
      // Waits for one of any of the child taxonomy names to resolve
      const tax = await Promise.race(
        children!.map(tax => {
          return getChildCategories(tax, paths)
        })
      )

      // If it resolves and it's not asset, add the parent taxonomy to the path and pass it higher up the tree to resolve parent.
      if (tax.length > 0) {
        if (taxonomy.name !== 'Asset') {
          paths = [...tax, taxonomy]
          res(paths)
        } else {
          res(tax)
        }
        return
      }
    }
  }

  return new Promise((resolve, reject) => {
    evaluateCurrentTaxonomy(resolve, reject)
  })
}

export const findCategoryById = async (
  taxonomy: TaxonomyNodeView,
  id: string,
  paths: TaxonomyNodeView[] = []
): Promise<TaxonomyNodeView[]> => {
  const getChildCategories = (subTaxonomy: TaxonomyNodeView, newPaths: TaxonomyNodeView[]) => {
    return findCategoryById(subTaxonomy, id, newPaths)
  }

  const evaluateCurrentTaxonomy = async (res: (tax: TaxonomyNodeView[]) => void, rej: (msg: any) => void) => {
    if (taxonomy && id && taxonomy.id === id) {
      paths = [...paths, taxonomy]
      res(paths)
      return
    }

    const { children } = taxonomy

    if (children?.length !== 0) {
      // Waits for one of the child taxonomy names to resolve
      const tax = await Promise.race(
        children!.map(tax => {
          return getChildCategories(tax, paths)
        })
      )

      // If it resolves and it's not asset, add the parent taxonomy to the path and pass it higher up the tree to resolve parent.
      if (tax.length > 0) {
        if (taxonomy.name !== 'Asset') {
          paths = [...tax, taxonomy]
          res(paths)
        } else {
          res(tax)
        }
        return
      }
    }
  }

  return new Promise((resolve, reject) => {
    evaluateCurrentTaxonomy(resolve, reject)
  })
}

export const useTaxonomy = (): ITaxonomyMethods => {
  const { swatchGenerationSubcategories, apiServer, apiKey } = useEnv()
  const dispatch = useDispatch()

  const taxonomies = useSelector(taxonomiesSelector)
  const swatchGenerationTaxonomyIds = useSelector(swatchGenerationTaxonomyIdsSelector)
  const isLoading = useSelector(taxonomyLoadingSelector)
  const subcategoryPageAssets = useSelector(subcategoryPageAssetsSelector)
  const assetsAreLoading = useSelector(subcategoryPageAssetsIsLoadingSelector)
  const carouselItems = useSelector(carouselItemsSelector)
  const [tabs, setTabs] = useState<string[]>([])

  // fetches taxonomy and descendents, if includeParents true also returns object of parent and siblings
  const getCategory = async (name: string, includeParents?: boolean) => {
    if (isLoading && !taxonomies) {
      throw new Error('No Taxonomies Present')
    }

    if (taxonomies.length > 0) {
      const taxonomy = await findCategory(taxonomies[0], name)
      return includeParents ? taxonomy : (taxonomy[0] as TaxonomyNodeView)
    }
  }

  // fetches taxonomy and descendents, if includeParents true also returns object of parent and siblings
  const getCategoryById = async (id: string, includeParents?: boolean) => {
    if (isLoading && !taxonomies) {
      throw new Error('No Taxonomies Present')
    }
    if (taxonomies.length > 0) {
      const taxonomy = await findCategoryById(taxonomies[0], id)
      return includeParents ? taxonomy : (taxonomy[0] as TaxonomyNodeView)
    }
  }

  // Set tabs for search
  const loadCategories = async (name: string) => {
    const items = (await getCategory(name)) as TaxonomyNodeView
    const options = []
    if (items?.children) {
      const asset = 'All'
      options.push(asset)
      items.children.forEach(child => {
        options.push(child.name)
      })
      setTabs(options)
    }
  }

  const getTaxonomyNodeById = (taxonomies: TaxonomyNodeView[], taxonomyId: String) => {
    let parentNode: TaxonomyNodeView | undefined
    for (const taxonomy of taxonomies) {
      if (parentNode !== undefined) {
        return parentNode
      }
      if (taxonomy.id === taxonomyId) {
        parentNode = taxonomy
        break
      } else {
        if (taxonomy.children !== undefined) {
          parentNode = getTaxonomyNodeById(taxonomy.children, taxonomyId)
        }
      }
    }
    return parentNode!!
  }

  // Takes in list of taxonomies for which to add id and all descendents' ids to list for tagging swatch generation
  const setSwatchGenerationTaxonomiesFromConfig = async (swatchGenerationSubcategories: string[]) => {
    const currentSwatchIds = [...swatchGenerationTaxonomyIds]
    const addChildrenTaxonomies = (taxonomy: TaxonomyNodeView) => {
      if (taxonomy.children?.length) {
        taxonomy.children.forEach((child: TaxonomyNodeView) => {
          currentSwatchIds.push(child.id)
          addChildrenTaxonomies(child)
        })
      }
    }
    for (const swatchGenerationName of swatchGenerationSubcategories) {
      const nameAndChildrenIds = (await getCategory(swatchGenerationName)) as TaxonomyNodeView
      currentSwatchIds.push(nameAndChildrenIds.id)
      addChildrenTaxonomies(nameAndChildrenIds)
    }
    currentSwatchIds.sort().filter((v, i, a) => a.indexOf(v) === i)
    dispatch(setSwatchGenerationTaxonomyIds(currentSwatchIds))
  }

  const resetCategoryAssets = () => {
    dispatch(setCategoryAssets([]))
    dispatch(setCategoryId(''))
    dispatch(setCategoryAssetStatus(ImageStatus.IDLE))
  }

  const getImages = (taxonomyId: string): void => {
    dispatch(setCategoryId(taxonomyId))
    dispatch(getCategoryAssets(taxonomyId))
  }

  const getCarouselItems = (taxonomyId: string): void => {
    const payload = { taxonomyId, server: apiServer, key: apiKey }
    dispatch(getCarouselItemsReducer(payload))
  }

  const resetCarouselItems = () => {
    dispatch(setCarouselItems([]))
  }

  useEffect(() => {
    if (taxonomies) {
      loadCategories('Asset')
      if (swatchGenerationSubcategories) {
        setSwatchGenerationTaxonomiesFromConfig(swatchGenerationSubcategories)
      }
    }
  }, [taxonomies])

  return {
    getImages,
    getCategory,
    getCategoryById,
    getCarouselItems,
    resetCategoryAssets,
    resetCarouselItems,
    isLoading,
    taxonomies,
    subcategoryPageAssets,
    assetsAreLoading,
    carouselItems,
    tabs,
    getTaxonomyNodeById,
  }
}
