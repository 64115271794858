import { createSlice } from '@reduxjs/toolkit'
import { UserView } from 'gallerydigitalassets-v1-client'
import { IUserIdentifiers } from './types'

export interface IUserState {
  userDetails: UserView | null
  userIdentifiers: IUserIdentifiers | null
  userUnauthorized: boolean
  adGroupMissing: boolean
}

const initialState: IUserState = {
  userDetails: null,
  userIdentifiers: null,
  userUnauthorized: false,
  adGroupMissing: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserByLanId() {},
    setUserDetails(state, action) {
      state.userDetails = action.payload
    },
    initLogOut(state, action) {},
    setUserIdentifiers(state, action) {
      state.userIdentifiers = action.payload
    },
    setUserAuth(state, action) {
      state.userUnauthorized = action.payload
    },
    setAdGroupMissing(state, action) {
      state.adGroupMissing = action.payload
    },
  },
})

export const { initLogOut, getUserByLanId, setUserDetails, setUserIdentifiers, setUserAuth, setAdGroupMissing } =
  userSlice.actions
export default userSlice.reducer
