import Axios from 'axios'
import { TaxonomyAssetCountsResponse } from 'gallerydigitalassets-v1-client'
import { useState, useEffect } from 'react'

export interface CarouselItemProps extends TaxonomyAssetCountsResponse {
  imageUrl: string
}

// if thumbanil image does not exist in public folder, use defaultImageUrl
const checkForImage = async (subCategory: TaxonomyAssetCountsResponse, imageUrl: string) => {
  const defaultImageUrl = `${process.env.PUBLIC_URL}/Images/SubcategoryImages/no_image_thumbnail.png`
  try {
    await Axios.get(imageUrl)
    return {
      ...subCategory,
      imageUrl: imageUrl,
    }
  } catch {
    return {
      ...subCategory,
      imageUrl: defaultImageUrl,
    }
  }
}

const useCarousel = (subCategories: TaxonomyAssetCountsResponse[], carouselWidth: number) => {
  const [translation, setTranslation] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(0)
  // furthest translation from 0 that the carousel can travel
  const [minimumTranslation, setMinimumTranslation] = useState<number>(0)
  const [numberOfItems, setNumberOfItems] = useState<number>(subCategories.length)
  const itemWidth: number = 176
  // subcategories updated with imageUrl for carousel
  const [carouselItems, setCarouselItems] = useState<(CarouselItemProps | undefined)[]>([])

  const pageLeft = () => {
    let newTranslation = translation + itemWidth * itemsPerPage
    // prevents carousel from going back past the beginning of the slide deck
    if (newTranslation > 0) {
      newTranslation = 0
    }
    // repositions to make sure the first item isnt cut off when moving back from the end
    if (newTranslation % itemWidth !== 0) {
      newTranslation -= newTranslation % itemWidth
    }
    setTranslation(newTranslation)
  }

  const pageRight = () => {
    let newTranslation = translation - itemWidth * itemsPerPage
    // prevents carousel from going past the end of the slide deck
    if (newTranslation < minimumTranslation) {
      newTranslation = minimumTranslation
    }
    setTranslation(newTranslation)
  }

  // sets total allowed translation and resets carousel to start
  useEffect(() => {
    setItemsPerPage(Math.floor(carouselWidth / itemWidth))
    setMinimumTranslation(-(itemWidth * numberOfItems - carouselWidth))
    setTranslation(0)
  }, [numberOfItems, carouselWidth])

  useEffect(() => {
    setNumberOfItems(subCategories.length)
    // returns array of Promises with imageUrl set
    const checkForImagePromises = subCategories.map((subCategory: TaxonomyAssetCountsResponse) => {
      return checkForImage(
        subCategory,
        `/Images/SubcategoryImages/${subCategory.name!!.replace(' ', '').toLowerCase()}.png`
      )
    })

    // creates single promise of all CarouselItems
    const updatedCarouselItemsPromise = Promise.allSettled(checkForImagePromises).then(results =>
      results.map(result => {
        if (result.status === 'fulfilled') {
          return result.value
        } else return undefined
      })
    )
    Promise.resolve(updatedCarouselItemsPromise).then(result => setCarouselItems(result))
  }, [subCategories])

  return { translation, minimumTranslation, pageLeft, pageRight, carouselItems }
}

export default useCarousel
