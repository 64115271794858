import { AssetOrganizationView, AssetUserView, FileView, PermissionView } from 'gallerydigitalassets-v1-client'
import { IUser, ITag } from 'store'
import { PageStatus } from 'store'

export interface IAssetState {
  assetDetailPageAsset: IAsset | undefined
  assetDetailPageStatus: PageStatus
  userAssetPermissions: IUserAssetPermissions
  assetUsers: IUser[]
  assetCreationFileValues: ICreateAssetFileValues[]
  assetActivity: IAssetActivity[]
  assetActivityIsLoading: boolean
}

export interface IUserAssetPermissions {
  userCanEditAndDeleteAsset: boolean
  userIsAssetManager: boolean
}

// TODO: MOVE OVER TO USING OPENAPI GENERATED TYPES
export interface IAsset {
  active: boolean
  assetOrganizations: Array<AssetOrganizationView>
  assetTags: ITag[]
  assetType: 'NONE' | 'MODEL_3D_LOW' | 'MODEL_3D_HIGH' | 'MODEL_3D_RAW' | 'TEXTURE' | 'RESOURCE_FILE'
  assetUsers: Array<AssetUserView>
  id: string
  files: FileView[]
  heroImageFile?: FileView
  name: string
  createdAt: number
  updatedAt: number
  permissions: Array<PermissionView>
  sourceId: string
  assetTaxonomy: IAssetTaxonomy
  linkedAssets?: IAsset[]
  taxonomyId: string
}

// TODO: MOVE OVER TO USING OPENAPI GENERATED TYPES
export interface ITagESSearch {
  key: string
  value: string
}
export interface IAssetCreatorESSearch {
  lanId: string
  userId: string
}
export interface IAssetFileSourceESSearch {
  accessUrl: string
  fileClassification: string
  fileExtension: string
  fileName: string
  heroImage: boolean
  s3Bucket: string
  s3Key: string
}
export interface IAssetFileESSearch {
  fileTags: ITagESSearch[]
  sources: IAssetFileSourceESSearch[]
}
export interface IAssetESSearch {
  id: string
  creator: IAssetCreatorESSearch[]
  assetTags: ITagESSearch[]
  files: IAssetFileESSearch[]
  managers: string[]
  name: string
  orgs: string[]
  taxonomies: string[]
  tcinThumbnail: IAssetFileSourceESSearch | undefined
}

export interface ICreateAssetFileValues {
  name: string
  file: File
  status: 'QUEUED' | 'UPLOADING' | 'UPLOADED' | 'WAITING'
}

export interface IEditAssetModalFile extends FileView {
  type?: string
  size?: number
  file?: File
}

export interface IAssetTaxonomy {
  id: string
  name: string
  services?: IAssetTaxonomyService[]
  parent?: IAssetTaxonomy
}
interface IAssetTaxonomyService {
  id: string
  name: string
}

export interface IAssetActivity {
  actionAt: string
  activityMessage: String
  actionOrder: number
}

export enum ZeroStateToShowEnum {
  SEARCHPAGE = 'SEARCH_PAGE',
  COLLECTIONDETAILPAGE = 'COLLECTION_DETAIL_PAGE',
  SUBCATEGORYPAGE = 'SUBCATEGORY_PAGE',
}
