import { createSelector } from 'reselect'
import { RootState } from 'store'

export const collectionsSelector = createSelector(
  (state: RootState) => state.collections,
  state => state
)
export const ownCollectionsSelector = createSelector(collectionsSelector, state => state.myCollectionsFeed)
export const publicCollectionsSelector = createSelector(collectionsSelector, state => state.publicCollectionsFeed)
export const favoriteCollectionsSelector = createSelector(collectionsSelector, state => state.favoriteCollectionsFeed)

export const ownCollectionsFeedSelector = createSelector(collectionsSelector, state => state.myCollectionsFeed?.feed)
export const publicCollectionsFeedSelector = createSelector(
  collectionsSelector,
  state => state.publicCollectionsFeed?.feed
)
export const favoriteCollectionsFeedSelector = createSelector(
  collectionsSelector,
  state => state.favoriteCollectionsFeed?.feed
)

export const collectionDetailsSelector = createSelector(
  collectionsSelector,
  state => state.collection.collectionDetails
)
export const collectionAssetsSelector = createSelector(collectionsSelector, state => state.collection.collectionAssets)
export const collectionIsLoadingSelector = createSelector(collectionsSelector, state => state.loading)
export const collectionUsersSelector = createSelector(
  collectionsSelector,
  state => state.collectionUsersFeed.collectionUsers
)
export const collectionDetailPageStatusSelector = createSelector(
  collectionsSelector,
  state => state.collection.collectionDetailStatus
)
export const featuredCollectionSelector = createSelector(collectionsSelector, state => state.featuredCollection)
