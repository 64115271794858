import { ISearchCriteria, IAsset, IUser } from 'store'
import { PageStatus } from 'store/client'
import {
  CollectionOrganizationView,
  AssetFileView,
  CollectionTagView,
  PermissionView,
} from 'gallerydigitalassets-v1-client'

export interface ICollectionResult {
  criteria?: ISearchCriteria
  feed: ICollection[]
  total?: number
  isLoading?: boolean
}
export interface ICollectionUser {
  id: string
  access: PermissionView.AccessEnum
  createdAt: number
  updatedAt: number
  user: IUser
}

export interface ICollectionUsers {
  collectionUsers: IUser[]
}

export interface ICollectionState {
  collectionAssets: ICollectionAssets
  collectionDetails: ICollectionDetails
  collectionDetailStatus: PageStatus
}

export interface ICollectionAssets {
  paginationCriteria: ISearchCriteria
  totalAssets: number
  assets: IAsset[]
  isLoading: boolean
}

export interface ICollectionDetails {
  id: string
  name: string
  description: string
  galleryUsers: ICollectionUser[]
  createdAt: number
  updatedAt: number
  galleryEmpty?: boolean
  permissions: PermissionView[]
  collectionOrganizations?: CollectionOrganizationView[]
  collectionTags?: CollectionTagView[]
  creatorFirstName: String
  creatorLastName: String
  creatorLanId: String
  thumbnailFileUrls: String[]
  orgs: String[]
  favoriteUsers: String[]
}

export interface ICollection extends ICollectionDetails {
  galleryAssets: IAsset[] | undefined
}

export interface IFeedCollection extends ICollection {
  assets?: AssetFileView[]
}

// Collection Sort
export interface ICollectionSort {
  value: SortValueEnum
  direction: SortDirectionEnum
}

export enum SortValueEnum {
  NAME = 'name',
  CREATEDAT = 'createdAt',
}

export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const PUBLIC_COLLECTIONS_FEED_ID = 'publicCollections'
export const MY_COLLECTIONS_FEED_ID = 'myCollections'

export enum CollectionSet {
  FAV = 'favorite',
  MY = 'my',
  PUB = 'public',
}

export enum CollectionSort {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  LATEST_ASC = 'LATEST_ASC',
  LATEST_DESC = 'LATEST_DESC',
}
