import * as React from 'react'
import { useEffect } from 'react'

import { Grid, Spinner, Heading } from '@enterprise-ui/canvas-ui-react'
import SubCategoryResult, { ISubcategory } from './SubCategoryResult'
import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'
import { currentCategorySelector, ImageStatus, useTaxonomy } from 'store'
import { useSelector } from 'react-redux'

type ICategoryFeedProps = {
  taxonomy: TaxonomyNodeView
  numberOfAssets?: number
}

const CategoryFeed: React.FC<ICategoryFeedProps> = ({ taxonomy, numberOfAssets }) => {
  const data = useSelector(currentCategorySelector)

  const { status, assets } = data
  // const dispatch = useDispatch()
  const { getImages } = useTaxonomy()

  useEffect(() => {
    if (assets.length <= 0 && status === ImageStatus.IDLE) {
      getImages(taxonomy.id)
    }
  }, [assets, status])

  return (
    <Grid.Container
      className={'categoryFeed'}
      spacing="expanded"
      justify={assets && assets.length <= 0 ? 'center' : ''}
      direction="row"
    >
      {(status === ImageStatus.LOADING || status === ImageStatus.IDLE) && (
        <Grid.Container data-testid="load-indicator" justify="center" className="hc-pt-xl">
          <Spinner />
        </Grid.Container>
      )}

      {status === ImageStatus.ERROR && (
        <Grid.Item xs={4} className="hc-ta-center">
          <div className="hc-pa-normal hc-pa-xl">
            <Heading className="hc-clr-grey03 hc-pv-md" size={4}>
              Something went wrong in Gallery. Please contact Gallery admin.
            </Heading>
          </div>
        </Grid.Item>
      )}

      {assets && assets.length <= 0 && status === ImageStatus.SUCCESS && (
        <Grid.Item xs={4} className="hc-ta-center">
          <div className="hc-pa-normal hc-pa-xl">
            <Heading className="hc-clr-grey03 hc-pv-md" size={4}>
              There are currently no Subcategories
            </Heading>
          </div>
        </Grid.Item>
      )}

      {!!assets &&
        assets.map((val, id) => {
          const subcategory = taxonomy.children?.find(sub => sub.id === val.id)
          const result: ISubcategory = {
            taxonomyId: subcategory!.id,
            name: subcategory!.name,
            imageFiles: val.imageFiles,
          }

          return <SubCategoryResult key={id} subCategory={result} />
        })}
    </Grid.Container>
  )
}

export default CategoryFeed
