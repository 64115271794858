import { useEffect } from 'react'
import { FileView } from 'gallerydigitalassets-v1-client'
import { useSelector, useDispatch } from 'react-redux'
import { FileViewUI, checkedFilesSelector, selectAllSelector, fileDrawersSelector, IFileDrawer } from 'store'
import {
  updateFileReducer,
  deleteFileReducer,
  updateAssetThumbnailReducer,
  downloadMultipleFilesReducer,
  downloadFileReducer,
  setCheckedFilesReducer,
  updateSelectAllReducer,
  resetFileStoreReducer,
  setFileDrawers,
} from './slice'
import { useEnv } from '@praxis/component-runtime-env'
import { findStringInArray } from 'utils/array'
import { matchStrings } from 'utils/string'

export const useFiles = () => {
  const { fileBucketingConfig, bucketType, ignoredCollageFiles, apiBaseUrl } = useEnv()
  const ignoredClassifications: String[] = fileBucketingConfig.ignoredFileClassifications
  const ignoredFiles: String[] = ignoredCollageFiles
  const checkedFiles = useSelector(checkedFilesSelector)
  const selectAll = useSelector(selectAllSelector)
  const fileDrawers = useSelector(fileDrawersSelector)
  const dispatch = useDispatch()

  const getFileThumbnailUrl = (file: FileView): string => {
    if (file) {
      return file.fileSources?.[0]?.accessUrl || `${apiBaseUrl}/files/${file.id}`
    } else {
      return '/no_image_thumbnail.png'
    }
  }

  //// FILE SELECT ////

  // Redux //
  const updateSelectAll = (files: FileViewUI[]) => {
    const filteredFiles = files.filter(
      (file: FileViewUI) =>
        ignoredClassifications.indexOf(file.classification.toString()) < 0 && ignoredFiles.indexOf(file.name) < 0
    )
    const percentSelected = filteredFiles.filter((file: FileViewUI) => file.selected).length / filteredFiles.length
    const payload = {
      status: percentSelected,
      indeterminate: percentSelected < 1 && percentSelected > 0,
      filesSelected: files.filter((file: FileViewUI) => file.selected).length,
    }
    dispatch(updateSelectAllReducer(payload))
  }

  const setCheckedFiles = (files: FileViewUI[]) => {
    dispatch(setCheckedFilesReducer(files))
    updateSelectAll(files)
  }

  const splitFilesIntoBuckets = (): IFileDrawer[] => {
    const buckets: IFileDrawer[] = []
    checkedFiles.forEach((file: FileViewUI) => {
      const fileClassification = file.classification
      const fileType = file.fileSources[0].fileExtension.toLowerCase()
      let bucket: IFileDrawer
      if (
        !fileBucketingConfig.ignoredFileClassifications.includes(fileClassification) &&
        !fileBucketingConfig.ignoredFiles.includes(`${file.name}.${fileType}`)
      ) {
        switch (fileType.toUpperCase()) {
          case 'FBX':
            bucket = buckets.filter((bucket: any) => bucket.type === bucketType.FBX)[0]
            if (bucket) {
              bucket.files.push(file)
            } else {
              buckets.push(createNewBucket(file, bucketType.FBX, 1))
            }
            break
          case 'CLO':
            bucket = buckets.filter((bucket: any) => bucket.type === bucketType.CLO)[0]
            if (bucket) {
              bucket.files.push(file)
            } else {
              buckets.push(createNewBucket(file, bucketType.CLO, 2))
            }
            break
          default:
            if (findStringInArray(fileBucketingConfig.styledFileClassification, fileClassification.toString(), true)) {
              bucket = buckets.filter((bucket: any) => bucket.type === bucketType.STYLED)[0]
              if (bucket) {
                bucket.files.push(file)
              } else {
                buckets.push(createNewBucket(file, bucketType.STYLED, 8))
              }
            } else if (
              findStringInArray(fileBucketingConfig.native3DFileTypes, fileType, true) ||
              matchStrings(fileClassification.toString(), fileBucketingConfig.native3DFileClassification, true)
            ) {
              bucket = buckets.filter((bucket: any) => bucket.type === bucketType.NATIVE_ASSET)[0]
              if (bucket) {
                bucket.files.push(file)
              } else {
                buckets.push(createNewBucket(file, bucketType.NATIVE_ASSET, 3))
              }
            } else if (
              findStringInArray(fileBucketingConfig.realTime3DFileTypes, fileType, true) ||
              findStringInArray(fileBucketingConfig.realTime3DFileClassification, fileClassification.toString(), true)
            ) {
              bucket = buckets.filter((bucket: any) => bucket.type === bucketType.REAL_TIME_ASSET)[0]
              if (bucket) {
                bucket.files.push(file)
              } else {
                buckets.push(createNewBucket(file, bucketType.REAL_TIME_ASSET, 4))
              }
            } else if (
              findStringInArray(fileBucketingConfig.renderFileClassification, fileClassification.toString(), true)
            ) {
              bucket = buckets.filter((bucket: any) => bucket.type === bucketType.RENDERS)[0]
              if (bucket) {
                bucket.files.push(file)
              } else {
                buckets.push(createNewBucket(file, bucketType.RENDERS, 5))
              }
            } else if (
              findStringInArray(fileBucketingConfig.thumbnailFileClassification, fileClassification.toString(), true)
            ) {
              bucket = buckets.filter((bucket: any) => bucket.type === bucketType.THUMBNAILS)[0]
              if (bucket) {
                bucket.files.push(file)
              } else {
                buckets.push(createNewBucket(file, bucketType.THUMBNAILS, 6))
              }
            } else if (
              findStringInArray(fileBucketingConfig.textureFileClassification, fileClassification.toString(), true)
            ) {
              bucket = buckets.filter((bucket: any) => bucket.type === bucketType.TEXTURES)[0]
              if (bucket) {
                bucket.files.push(file)
              } else {
                buckets.push(createNewBucket(file, bucketType.TEXTURES, 7))
              }
            } else {
              bucket = buckets.filter((bucket: any) => bucket.type === bucketType.OTHERS)[0]
              if (bucket) {
                bucket.files.push(file)
              } else {
                buckets.push(createNewBucket(file, bucketType.OTHERS, 99))
              }
            }
            break
        }
      }
    })
    buckets.sort((bucketA: IFileDrawer, bucketB: IFileDrawer) => (bucketA.id > bucketB.id ? 1 : -1))
    return buckets
  }

  // FileSelect Methods //
  const initializeCheckedFiles = (files: FileView[]) => {
    setCheckedFiles(
      files.map((file: FileView): FileViewUI => {
        return { ...file, ...{ selected: false } }
      })
    )
  }
  // Selecting individual files
  const selectFile = (id: string) => {
    const updatedFiles = checkedFiles.map((file: FileViewUI) => {
      return file.id === id ? { ...file, ...{ selected: !file.selected } } : file
    })
    setCheckedFiles(updatedFiles)
  }

  const bulkSelectFiles = (ids: string[], select?: boolean) => {
    const updatedFiles = checkedFiles.map((file: FileViewUI) => {
      return ids.find(id => file.id === id) ? { ...file, ...{ selected: select ? select : !file.selected } } : file
    })
    setCheckedFiles(updatedFiles)
  }

  // Selecting all files
  const selectAllFiles = (select: boolean) => {
    const updateAllFiles = checkedFiles.map((file: FileViewUI) => ({
      ...file,
      ...{
        selected:
          selectAll.indeterminate ||
          ignoredClassifications.indexOf(file.classification.toString()) >= 0 ||
          ignoredFiles.indexOf(file.name) >= 0
            ? false
            : select,
      },
    }))
    setCheckedFiles(updateAllFiles)
  }

  const resetFileStore = () => {
    dispatch(resetFileStoreReducer([]))
  }

  // get all checked file ids
  const checkedFileIds = () => {
    return checkedFiles
      .filter((file: FileViewUI) => file.selected)
      .map((file: FileViewUI) => file.id)
      .reduce((fileIds: string, currentFileId: string) => fileIds + ',' + currentFileId)
  }

  const createNewBucket = (file: FileViewUI, type: string, id: number) => {
    return {
      files: [file],
      type: type,
      id: id,
    }
  }

  useEffect(() => {
    dispatch(setFileDrawers(splitFilesIntoBuckets()))
  }, [checkedFiles])

  //// SAGA FUNCTION CALLS ////
  const updateFile = async (assetId: string, fileId: string, fileName?: string, heroImage?: boolean) => {
    const payload = {
      assetId: assetId,
      fileId: fileId,
      fileName: fileName,
      heroImage: heroImage,
    }
    dispatch(updateFileReducer(payload))
  }

  const deleteFile = async (assetId: string, fileIds: string[]) => {
    const payload = { assetId, fileIds }
    dispatch(deleteFileReducer(payload))
  }

  const downloadMultipleFiles = async (assetId: string, fileIds: string) => {
    const payload = { assetId, fileIds }
    dispatch(downloadMultipleFilesReducer(payload))
  }

  const updateAssetThumbnail = async (fileId: string, assetId: string) => {
    const payload = { fileId, assetId }
    dispatch(updateAssetThumbnailReducer(payload))
  }

  const downloadFile = async (fileUrl: string) => {
    const payload = { fileUrl }
    dispatch(downloadFileReducer(payload))
  }

  return {
    updateFile,
    deleteFile,
    updateAssetThumbnail,
    downloadMultipleFiles,
    downloadFile,
    getFileThumbnailUrl,
    checkedFiles,
    selectAll,
    fileDrawers,
    initializeCheckedFiles,
    selectFile,
    bulkSelectFiles,
    selectAllFiles,
    checkedFileIds,
    resetFileStore,
  }
}
