import { Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import BasePage from 'components/pages/BasePage/BasePage'
import { IBreadCrumb } from 'store'

interface ILoadingState {
  crumb: IBreadCrumb | {}
}

const LoadingState = ({ crumb }: ILoadingState) => {
  return (
    <BasePage crumbs={crumb}>
      <Grid.Container justify="center" className="hc-pt-xl" data-testid="spinner">
        <Spinner data-testid="load-indicator" />
      </Grid.Container>
    </BasePage>
  )
}

export default LoadingState
