import { createSelector } from 'reselect'
import { RootState } from 'store'

export const taxonomySelector = createSelector(
  (state: RootState) => state.taxonomy,
  state => state
)
export const taxonomiesSelector = createSelector(taxonomySelector, state => state.taxonomies)
export const swatchGenerationTaxonomyIdsSelector = createSelector(
  taxonomySelector,
  state => state.swatchGenerationTaxonomyIds
)
export const taxonomyLoadingSelector = createSelector(taxonomySelector, state => state.isLoading)
export const currentCategorySelector = createSelector(taxonomySelector, state => state.currentCategory)
export const subcategoryPageAssetsSelector = createSelector(taxonomySelector, state => state.subcategoryPageAssets)
export const subcategoryPageAssetsIsLoadingSelector = createSelector(
  taxonomySelector,
  state => state.subcategoryPageAssets.paginationIsLoading
)
export const carouselItemsSelector = createSelector(taxonomySelector, state => state.carouselItems)
