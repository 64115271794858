import { createSlice, createReducer } from '@reduxjs/toolkit'
import { ITaxonomyState, ICategoryAssets, ImageStatus, ISubcategoryPageAssets } from './types'

export const initialState: ITaxonomyState = {
  currentCategory: { id: '', assets: [], status: ImageStatus.IDLE },
  taxonomies: [],
  swatchGenerationTaxonomyIds: [],
  isLoading: true,
  subcategoryPageAssets: {
    page: 0,
    assets: [],
    total: 0,
    paginationIsLoading: false,
  },
  carouselItems: [],
}

// TODO: Add types to actions
const taxonomySlice = createSlice({
  name: 'taxonomy',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    getTaxonomies() {},
    setTaxonomies(state, action) {
      state.taxonomies = action.payload
    },
    setSwatchGenerationTaxonomyIds(state, action) {
      state.swatchGenerationTaxonomyIds = action.payload
    },
    getImages(state, action) {},
    setImages(state, action) {},
    getCategoryAssets(state, action) {},
    setCategoryAssetStatus(state, action) {
      currentCategoryReducer(state.currentCategory, action)
    },
    setCategoryId(state, action) {
      currentCategoryReducer(state.currentCategory, action)
    },
    setCategoryAssets(state, action) {
      currentCategoryReducer(state.currentCategory, action)
    },
    getSubcategoryPageAssets(state, action) {},
    setSubcategoryPageAssets(state, action) {
      subcategoryPageAssetsReducer(state.subcategoryPageAssets, action)
    },
    appendSubcategoryPageAssets(state, action) {
      subcategoryPageAssetsReducer(state.subcategoryPageAssets, action)
    },
    setSubcategoryPageAssetsPaginationIsLoading(state, action) {
      subcategoryPageAssetsReducer(state.subcategoryPageAssets, action)
    },
    getCarouselItemsReducer(state, action) {},
    setCarouselItems(state, action) {
      state.carouselItems = action.payload
    },
  },
})

export const {
  setLoading,
  getTaxonomies,
  setTaxonomies,
  setSwatchGenerationTaxonomyIds,
  getImages,
  getCategoryAssets,
  setImages,
  setCategoryAssets,
  setCategoryId,
  setCategoryAssetStatus,
  getSubcategoryPageAssets,
  setSubcategoryPageAssets,
  appendSubcategoryPageAssets,
  setSubcategoryPageAssetsPaginationIsLoading,
  getCarouselItemsReducer,
  setCarouselItems,
} = taxonomySlice.actions

const currentCategoryReducer = createReducer<ICategoryAssets>(
  { id: '', assets: [], status: ImageStatus.IDLE },
  builder => {
    builder
      .addCase(setCategoryAssetStatus, (state, action) => {
        state.status = action.payload
      })
      .addCase(setCategoryAssets, (state, action) => {
        state.assets = action.payload
      })
      .addCase(setCategoryId, (state, action) => {
        state.id = action.payload
      })
  }
)

const subcategoryPageAssetsReducer = createReducer<ISubcategoryPageAssets>(
  { page: 0, assets: [], total: 0, paginationIsLoading: false },
  builder => {
    builder
      .addCase(setSubcategoryPageAssets, (state, action) => {
        state.page = 0
        state.assets = action.payload.assets
        state.total = action.payload.totalCount
        state.paginationIsLoading = false
      })
      .addCase(appendSubcategoryPageAssets, (state, action) => {
        state.page = action.payload.page
        state.assets = state.assets.concat(action.payload.assets)
        state.total = action.payload.totalCount
        state.paginationIsLoading = false
      })
      .addCase(setSubcategoryPageAssetsPaginationIsLoading, (state, action) => {
        state.paginationIsLoading = action.payload
      })
  }
)

export default taxonomySlice.reducer
