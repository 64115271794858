import React from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'

const SubcategoryZeroState = () => {
  return (
    <Grid.Container className="hc-pt-4x hc-clr-grey03" justify="center" data-testid="subcategory-zero-state">
      <h2 className="no-assets-h2">No assets were found in this subcategory.</h2>
    </Grid.Container>
  )
}

export default SubcategoryZeroState
