import React from 'react'
import { useState } from 'react'

import { Card } from '@enterprise-ui/canvas-ui-react'
import './GalleryCard.scss'
import { FileView } from 'gallerydigitalassets-v1-client'
import GalleryCardImage from './GalleryCardImage'

type GalleryCardProps = {
  imageFiles: FileView[]
  id: string
  // chip is optional custom component
  chip?: unknown
  emptyText: unknown
  children: React.ReactNode
}

type ThumbnailGalleryProps = {
  imageFiles: FileView[]
}

enum ThumbLayout {
  oneImage = 1,
  twoImage,
  threeImage,
}

const ThumbnailGallery: React.FC<ThumbnailGalleryProps> = ({ imageFiles }) => {
  switch (imageFiles.length) {
    case ThumbLayout.oneImage:
      return (
        <>
          {imageFiles.map((imageFile, id) => (
            <GalleryCardImage key={id} file={imageFile} thumbnailLayoutNumber={ThumbLayout.oneImage} id={id} />
          ))}
        </>
      )

    case ThumbLayout.twoImage:
      return (
        <>
          {imageFiles.map((imageFile, id) => (
            <GalleryCardImage key={id} file={imageFile} thumbnailLayoutNumber={ThumbLayout.twoImage} id={id} />
          ))}
        </>
      )

    case ThumbLayout.threeImage:
      return (
        <>
          {imageFiles.map((imageFile, id) => (
            <GalleryCardImage key={id} file={imageFile} thumbnailLayoutNumber={ThumbLayout.threeImage} id={id} />
          ))}
        </>
      )

    default:
      return <></>
  }
}

const GalleryCard: React.FC<GalleryCardProps> = ({ children, chip, emptyText, id, imageFiles }) => {
  const [galleryHover, setGalleryHover] = useState<Boolean>(false)

  return (
    <Card data-test-id={id} className="galleryCard" elevation={0}>
      <div className="hc-pa-normal hc-pa-min">
        <div
          onMouseEnter={() => setGalleryHover(true)}
          onMouseLeave={() => setGalleryHover(false)}
          className="cardContainer"
        >
          {
            <>
              {chip}
              <div
                className={
                  galleryHover
                    ? `gallery-hover thumbnailGallery layout-${imageFiles.length} hc-mb-dense`
                    : `thumbnailGallery layout-${imageFiles.length} hc-mb-dense`
                }
              >
                <>
                  {imageFiles.length > 0 && <ThumbnailGallery imageFiles={imageFiles} />}
                  {!!emptyText && imageFiles.length === 0 && emptyText}
                </>
              </div>
              {/* render props.children as card description */}
              {children}
            </>
          }
        </div>
      </div>
    </Card>
  )
}

export default GalleryCard
