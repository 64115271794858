import { store, addToast, setUserAuth, setAdGroupMissing } from 'store'
import ReactGA from 'react-ga'
import { get } from 'lodash'

const processError = (
  e: Error,
  message: string = 'There was an error while processing the request',
  nonApiToast: boolean = false,
  caption: string = 'An error has occured'
) => {
  //Redirect when unauthorized error comes back.
  if (get(e, 'response.statusCode') === 401) {
    store.dispatch(
      addToast({
        color: 'error',
        message,
        caption,
        headingText: e.name || 'Authorization Error',
        autoHideDuration: 6000,
      })
    )
    store.dispatch(setUserAuth(true))
  } else if (get(e, 'response.statusCode') === 403) {
    store.dispatch(setAdGroupMissing(true))
  } else {
    // Stop here if we aren't sseeing this as a result of an API call.
    // This is not the result of an API call.

    if (nonApiToast) {
      store.dispatch(
        addToast({
          color: 'error',
          message,
          caption,
          headingText: e.name,
          autoHideDuration: 6000,
        })
      )
      return
    }

    console.error('processError', e)

    // Record event in Google Analytics
    ReactGA.event({
      category: 'Error',
      action: 'Client Error',
      label: e.toString(),
    })

    const requestId: string | undefined = get(e, 'response.headers[x-api-id]')
    const idCaption = requestId ? `ID: ${requestId}` : ''
    let apiErrorMessage
    if (get(e, 'response.data.message')) {
      apiErrorMessage = get(e, 'response.data.message')
    } else if (get(e, 'response.body.message')) {
      apiErrorMessage = get(e, 'response.body.message')
    } else {
      apiErrorMessage = message
    }

    // Dispatch toast event to store with given data.
    store.dispatch(
      addToast({
        color: 'error',
        message: apiErrorMessage,
        caption: idCaption,
        headingText: 'Error',
        autoHideDuration: 6000,
      })
    )
  }
}

export default processError
