import { createSlice } from '@reduxjs/toolkit'

const initialState: unknown = {}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getAccessToken() {},
  },
})

export const { getAccessToken } = authSlice.actions
export default authSlice.reducer
