import React from 'react'

import { Modal, GridContainer, GridItem, Button } from '@enterprise-ui/canvas-ui-react'
import './DeleteModal.scss'
import { FileView } from 'gallerydigitalassets-v1-client'
export interface IDeleteModalData {
  data: FileView | string
  headingText: string
  triggerDelete: () => void
  bodyText: string
  onRefuseText: string
  onApproveText: string
}

export interface IDeleteModalProps {
  data: IDeleteModalData
  closeModal: () => void
}

const DeleteModal: React.FC<IDeleteModalProps> = ({ data, closeModal }) => {
  const { headingText, triggerDelete, onRefuseText, onApproveText, bodyText } = data
  return (
    <Modal className="delete-file-modal" closeButton={false} isVisible>
      <div>
        <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">{headingText}</h1>
      </div>
      <div className="hc-pa-normal">
        <GridContainer className="hc-pv-md  hc-pa-normal">
          <GridItem xs>
            <p className="hc-pb-md">{bodyText}</p>
          </GridItem>
        </GridContainer>
        <GridContainer direction="row-reverse" spacing="dense">
          <GridItem>
            <Button
              type="primary"
              data-test-id="modal-delete-button"
              onClick={() => {
                triggerDelete()
                closeModal()
              }}
            >
              {onApproveText}
            </Button>
          </GridItem>
          <GridItem>
            <Button
              onClick={() => {
                closeModal()
              }}
            >
              {onRefuseText}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </Modal>
  )
}

export default DeleteModal
