import { createRoot } from 'react-dom/client'
import '@enterprise-ui/canvas-ui-css'
import App from 'components/App'
import * as serviceWorker from 'serviceWorker'
import { Provider } from 'react-redux'
import { store } from 'store'
import { AuthProvider } from '@praxis/component-auth'
import { EnvProvider, useEnv } from '@praxis/component-runtime-env'
import { Spinner } from '@enterprise-ui/canvas-ui-react'
import { STORAGE_KEYS } from 'config'
import packageJson from '../package.json'

const container = document.getElementById('app')
const root = createRoot(container)

const AppAuth = () => {
  const { clientId, polLoginUrl, polLogoutUrl, loginUrl, logoutUrl, apiKey, logoutRedirectUrl } = useEnv()
  const element = () => (
    <Spinner size={100} style={{ color: '#cc0000', top: '43%', left: '43%', position: 'absolute' }} />
  )
  localStorage.setItem(STORAGE_KEYS.USER_TOKEN, apiKey)
  const authProps = {
    // accessTokenKey: STORAGE_KEYS.ACCESS_TOKEN,
    clientId,
    loadingIndicator: element,
    logoutRedirect: logoutRedirectUrl,
  }

  const isPOLUser =
    window.location.hostname.includes('partnersonline') ||
    window.location.href.includes('partnersonline') ||
    localStorage.getItem(STORAGE_KEYS.USER_TYPE) === 'pol'

  // Checking for POL users and adjusting login/logout urls

  authProps.authorizationUrl = isPOLUser ? polLoginUrl : loginUrl
  authProps.logoutUrl = isPOLUser ? polLogoutUrl : logoutUrl

  return (
    <Provider store={store}>
      <AuthProvider {...authProps}>
        <App />
      </AuthProvider>
    </Provider>
  )
}
root.render(
  <EnvProvider
    loadingIndicator={false}
    commonConfig={{ apiBaseUrl: `${document.location.origin}/api` }}
    configPath={
      process.env.REACT_APP_ENV === 'dev' ? `/config.json?version=${packageJson.version}` : '/app-environment'
    }
  >
    <AppAuth />
  </EnvProvider>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
