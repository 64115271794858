import { CategoryLandingView, HttpError } from 'gallerydigitalassets-v1-client'
import { assetTaxonomyApi, galleryAxiosClient, IAsset } from 'store'
import { taxonomyApi } from '../client'
import axios from 'axios'
import { keysToCamel } from 'utils/object'

// TODO: MOVE THIS INTO CLIENT STORE AND MAKE ALTERABLE FROM ANY STORE FOR CANCELABLE CALLS
// This allows search to be cancelable if another search call is made
let call: any

export const getTaxonomiesApi = async (xTgtLanId: string) => {
  try {
    const { body } = await taxonomyApi.getTaxonomyTree(xTgtLanId)
    return body
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getCategoryAssetsApi = async (xTgtLanId: string, id: string) => {
  try {
    const { body } = await assetTaxonomyApi.getCategoryAssets(id, xTgtLanId)
    return body.data as CategoryLandingView[]
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const getSubcategoryAssetsApi = async (
  xTgtLanId: string,
  taxonomyId: string,
  page: number = 0,
  size: number = 25,
  sort: string[] = []
) => {
  try {
    const { body } = await assetTaxonomyApi.getTaxonomyAssets(taxonomyId, xTgtLanId, page, size, sort)
    const taxonomyAssets: IAsset[] = body?.data as unknown as IAsset[]
    const totalCount: number = body.totalItems
    return { taxonomyAssets, totalCount }
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const getCarouselItemsApi = async (xTgtLanId: string, taxonomyId: string, server: string, key: string) => {
  if (call) {
    call.cancel('Cancelling earlier search request')
  }
  call = axios.CancelToken.source()
  try {
    const axiosCall = await galleryAxiosClient.get(`/assets/taxonomies/${taxonomyId}/counts?key=${key}`, {
      cancelToken: call.token,
    })
    return keysToCamel(axiosCall.data.data)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}
