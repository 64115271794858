import { createSlice, createReducer } from '@reduxjs/toolkit'
import { ICollectionState, ICollectionAssets, ICollectionDetails, ICollectionResult, ICollectionUsers } from './types'
import { uniqBy } from 'lodash'
//TODO: Figure out why this errors when we use store instead of store/client.
import { PageStatus } from 'store/client'

export const initCollectionDetailsState: ICollectionDetails = {
  id: '',
  name: '',
  description: '',
  collectionTags: [],
  galleryUsers: [],
  createdAt: 0,
  updatedAt: 0,
  galleryEmpty: true,
  permissions: [],
  collectionOrganizations: [],
  creatorFirstName: '',
  creatorLanId: '',
  creatorLastName: '',
  orgs: [],
  thumbnailFileUrls: [],
  favoriteUsers: [],
}

export const initCollectionAssetsState: ICollectionAssets = {
  paginationCriteria: { p: 0, size: 25 },
  totalAssets: 0,
  assets: [],
  isLoading: false,
}

const initCollectionState: ICollectionState = {
  collectionAssets: initCollectionAssetsState,
  collectionDetails: initCollectionDetailsState,
  collectionDetailStatus: PageStatus.loading,
}

export const initCollectionsFeed: ICollectionResult = {
  criteria: { q: '', p: 0, size: 0, f: {}, sort: [] },
  feed: [],
  total: 0,
  isLoading: false,
}

const initCollectionUsers: ICollectionUsers = {
  collectionUsers: [],
}

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState: {
    loading: true,
    collection: initCollectionState,
    featuredCollection: initCollectionState,
    publicCollectionsFeed: initCollectionsFeed,
    myCollectionsFeed: initCollectionsFeed,
    favoriteCollectionsFeed: initCollectionsFeed,
    collectionUsersFeed: initCollectionUsers,
  },

  reducers: {
    getCollectionDetailsReducer(state, action) {},
    setCollectionDetailsState(state, action) {
      collectionReducer(state.collection, action)
    },
    getCollectionAssetsState(state, action) {},
    setCollectionAssetsState(state, action) {
      collectionReducer(state.collection, action)
    },
    appendCollectionAssetsState(state, action) {
      collectionReducer(state.collection, action)
    },
    setCollectionAssetsPaginationIsLoading(state, action) {
      collectionReducer(state.collection, action)
    },
    resetCollectionState(state) {
      state.collection = initCollectionState
    },
    getPublicCollectionsReducer(state, action) {},
    updatePublicCollectionsReducer(state, action) {
      state.publicCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: uniqBy(state.publicCollectionsFeed.feed?.concat(action.payload?.feed), 'id'),
        total: action.payload?.total,
        isLoading: false,
      }
    },
    refreshPublicCollectionsReducer(state, action) {
      state.publicCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: action.payload?.feed,
        total: action.payload?.total,
        isLoading: false,
      }
    },
    setPublicCollectionsIsLoading(state, action) {
      state.publicCollectionsFeed.isLoading = true
    },
    getMyCollectionsReducer(state, action) {},
    setMyCollectionsReducer(state, action) {
      state.myCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: action.payload.feed,
        total: action.payload?.total,
        isLoading: false,
      }
    },
    updateMyCollectionsReducer(state, action) {
      state.myCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: uniqBy(state.myCollectionsFeed.feed?.concat(action.payload?.feed), 'id'),
        total: action.payload?.total,
        isLoading: false,
      }
    },
    getFavoriteCollectionsFeed(state, action) {},
    setFavoriteCollectionsFeed(state, action) {
      state.favoriteCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: action.payload.feed,
        total: action.payload?.total,
        isLoading: false,
      }
    },
    updateFavoriteCollections(state, action) {
      state.favoriteCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: uniqBy(state.favoriteCollectionsFeed?.feed.concat(action.payload?.feed), 'id'),
        total: action.payload?.total,
        isLoading: false,
      }
    },
    refreshFavoriteCollections(state, action) {
      state.favoriteCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: action.payload?.feed,
        total: action.payload?.total,
        isLoading: false,
      }
    },
    refreshMyCollectionsReducer(state, action) {
      state.myCollectionsFeed = {
        criteria: action.payload?.criteria,
        feed: action.payload?.feed,
        total: action.payload?.total,
        isLoading: false,
      }
    },
    setMyCollectionsIsLoading(state, action) {
      state.myCollectionsFeed.isLoading = true
    },
    setFavoriteCollectionsIsLoading(state, action) {
      state.favoriteCollectionsFeed.isLoading = true
    },
    setLoadingReducer(state, action) {
      state.loading = action.payload!
    },
    resetOwnCollectionsReducer(state) {
      state.myCollectionsFeed = initCollectionsFeed
    },
    resetPublicCollectionsReducer(state) {
      state.publicCollectionsFeed = initCollectionsFeed
    },
    setCollectionDetailsStatus(state, action) {
      collectionReducer(state.collection, action)
    },
    getFeaturedCollection(state, action) {},
    setFeaturedCollectionAssets(state, action) {
      featuredCollectionReducer(state.featuredCollection, action)
    },
    setFeaturedCollectionsIsLoading(state, action) {
      state.featuredCollection.collectionAssets.isLoading = true
    },
    addAssetsToGalleryReducer(state, payload) {},
    deleteAssetsToGalleryReducer(state, payload) {},
    downloadGalleryReducer(state, payload) {},
    createGalleryReducer(state, payload) {},
    editGalleryReducer(state, payload) {},
    deleteCollectionReducer(state, payload) {},
    addGalleryUserReducer(state, action) {},
    deleteGalleryUserReducer(state, action) {},
    getGalleryUsersReducer(state, action) {},
    setGalleryUsersReducer(state, action) {
      state.collectionUsersFeed.collectionUsers = action.payload
    },
    favoriteGalleryReducer(state, payload) {},
    unFavoriteGalleryReducer(state, payload) {},
    resetFavoriteCollectionsReducer(state) {
      state.favoriteCollectionsFeed = initCollectionsFeed
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  getCollectionAssetsState,
  setCollectionAssetsState,
  appendCollectionAssetsState,
  setCollectionAssetsPaginationIsLoading,
  setCollectionDetailsState,
  resetCollectionState,
  updatePublicCollectionsReducer,
  refreshPublicCollectionsReducer,
  setPublicCollectionsIsLoading,
  updateMyCollectionsReducer,
  refreshMyCollectionsReducer,
  getFavoriteCollectionsFeed,
  setMyCollectionsReducer,
  setFavoriteCollectionsFeed,
  setFavoriteCollectionsIsLoading,
  setMyCollectionsIsLoading,
  getCollectionDetailsReducer,
  getPublicCollectionsReducer,
  getMyCollectionsReducer,
  setLoadingReducer,
  resetOwnCollectionsReducer,
  resetPublicCollectionsReducer,
  addAssetsToGalleryReducer,
  deleteAssetsToGalleryReducer,
  downloadGalleryReducer,
  createGalleryReducer,
  editGalleryReducer,
  deleteCollectionReducer,
  addGalleryUserReducer,
  deleteGalleryUserReducer,
  getGalleryUsersReducer,
  setGalleryUsersReducer,
  setCollectionDetailsStatus,
  setFeaturedCollectionAssets,
  setFeaturedCollectionsIsLoading,
  getFeaturedCollection,
  favoriteGalleryReducer,
  unFavoriteGalleryReducer,
  updateFavoriteCollections,
  refreshFavoriteCollections,
  resetFavoriteCollectionsReducer,
} = collectionsSlice.actions

const featuredCollectionReducer = createReducer<ICollectionState>(initCollectionState, builder => {
  builder.addCase(setFeaturedCollectionAssets, (state, action) => {
    state.collectionAssets.paginationCriteria.p = action.payload?.page
    state.collectionAssets.paginationCriteria.size = action.payload?.size
    state.collectionAssets.isLoading = false
    state.collectionAssets.totalAssets = action.payload?.totalAssets
    state.collectionAssets.assets = action.payload?.assets
  })
})
const collectionReducer = createReducer<ICollectionState>(initCollectionState, builder => {
  builder
    .addCase(setCollectionDetailsStatus, (state, action) => {
      state.collectionDetailStatus = action.payload
    })
    .addCase(setCollectionAssetsState, (state, action) => {
      state.collectionAssets.paginationCriteria.p = action.payload?.page
      state.collectionAssets.paginationCriteria.size = action.payload?.size
      state.collectionAssets.isLoading = false
      state.collectionAssets.totalAssets = action.payload?.totalAssets
      state.collectionAssets.assets = action.payload?.assets
    })
    .addCase(appendCollectionAssetsState, (state, action) => {
      state.collectionAssets.paginationCriteria.p = action.payload.page
      state.collectionAssets.isLoading = false
      state.collectionAssets.assets = state.collectionAssets.assets.concat(action.payload.assets)
    })
    .addCase(setCollectionAssetsPaginationIsLoading, (state, action) => {
      state.collectionAssets.isLoading = action.payload
    })
    .addCase(setCollectionDetailsState, (state, action) => {
      const {
        id,
        name,
        description,
        galleryUsers,
        createdAt,
        updatedAt,
        galleryEmpty,
        permissions,
        collectionOrganizations,
        collectionTags,
      } = action.payload
      state.collectionDetails.id = id
      state.collectionDetails.name = name
      state.collectionDetails.description = description
      state.collectionDetails.galleryUsers = galleryUsers
      state.collectionDetails.createdAt = createdAt
      state.collectionDetails.updatedAt = updatedAt
      state.collectionDetails.galleryEmpty = galleryEmpty
      state.collectionDetails.permissions = permissions
      state.collectionDetails.collectionOrganizations = collectionOrganizations
      state.collectionDetails.collectionTags = collectionTags
    })
})

export default collectionsSlice.reducer
