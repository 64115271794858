import React, { useEffect } from 'react'

import { Grid, Button, Modal, Form, Input } from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import './IdListSearch.scss'
import { useSearch } from 'store'
import { useEnv } from '@praxis/component-runtime-env'
interface IListSearchValues {
  searchTerms: string
  searchType: string
}

type IListSearchProps = {
  closeModal: () => void
}

type SearchType = {
  name: string
  value: string
}

const IdListSearchModal: React.FC<IListSearchProps> = ({ closeModal }) => {
  const { dispatchSearch, activateExactSearch, setSearchValue, clearStore } = useSearch()
  const { searchTypes } = useEnv()
  const initialValues: IListSearchValues = {
    searchTerms: '',
    searchType: 'gallery-id',
  }
  const formik = useFormik({
    initialValues,
    onSubmit: async (values: IListSearchValues) => {
      const { searchTerms, searchType } = values
      if (searchTerms && searchType) {
        activateExactSearch(false)
        // Remove any commas
        const parsedTerms = searchTerms
          .trim()
          .replace(/[\s+]+/, ' ')
          .replace(',', ' ')
          .split(' ')
          .map(term => term.trim())
          .join(',')
        setSearchValue(parsedTerms)
        clearStore()
        dispatchSearch({
          q: parsedTerms,
          p: 0,
          f: { item_identifiers: [searchType] },
          t: 'All',
          size: 25,
        })
      }
    },
    validate: (values: IListSearchValues) => {
      const errors: any = {}
      if (!values.searchTerms.trim()) {
        errors.name = 'Required'
      }
    },
    validateOnMount: true,
    isInitialValid: false,
  })

  const { searchTerms, searchType } = formik.values

  const searchTypeOptions = [
    {
      value: null,
      name: 'Select One',
    },
    ...searchTypes,
  ]

  useEffect(() => {
    formik.validateForm()
  }, [searchTerms, searchType])

  useEffect(() => {
    formik.setFieldValue('searchType', null)
  }, [])

  return (
    <Modal
      className="id-list-search-modal"
      headingText="Search by asset ID"
      isVisible
      onRefuse={() => closeModal()}
      onApproveModal={() => {}}
    >
      <div className="hc-pa-normal id-list-search-container">
        <Form onSubmit={formik.handleSubmit}>
          <Grid.Container direction="column">
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={3} className="id-list-search-type">
                  <Input.Select
                    data-testid="id-list-search-type-select"
                    value={formik.values.searchType}
                    id="search-term-type"
                    onUpdate={(_: string, term: string) => {
                      formik.setFieldValue('searchType', term)
                    }}
                    options={searchTypeOptions.map((term: SearchType) => ({
                      value: term.value,
                      label: term.name,
                    }))}
                  />
                </Grid.Item>
                <Grid.Item xs={12} className="id-list-search-terms">
                  <Form.Field
                    className="id-list-search-terms-input"
                    error={!!(formik.touched.searchTerms && formik.errors.searchTerms)}
                    errorText={formik.errors.searchTerms}
                    type="textarea"
                    name="searchTerms"
                    grow
                    placeholder="Type asset ID here. You can separate ID numbers by either comma (,) or line breaks."
                    onChange={formik.handleChange}
                    value={searchTerms}
                    onBlur={formik.handleBlur}
                    data-testid="id-list-search-search-terms-input"
                  />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    disabled={
                      !!(formik.touched.searchTerms && formik.errors.searchTerms) ||
                      !formik.values.searchTerms ||
                      !formik.values.searchType
                    }
                    type="primary"
                    className="submitButton "
                    onClick={() => formik.submitForm()}
                    data-testid="id-list-search-button"
                  >
                    Search
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button onClick={() => closeModal()} data-testid="id-list-search-cancel">
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Form>
      </div>
    </Modal>
  )
}

export default IdListSearchModal
