import { all, call, put, take, takeLatest, select } from 'redux-saga/effects'
import { dialogDataSelector } from './selectors'
import { onAccept, onReject, openDialog, setDialogData, IDialogData, IDialogActionData } from 'store'

function* dialogSaga(data: any) {
  const { payload }: { payload: IDialogActionData } = data

  const newDialogData: IDialogData = {
    headerText: payload.headerText,
    message: payload.message,
    isVisible: payload.isVisible,
    refuseButtonText: payload.refuseButtonText,
    approveButtonText: payload.approveButtonText,
  }

  yield put(setDialogData(newDialogData))

  const dialogData: IDialogData = yield select(dialogDataSelector)

  while (dialogData.isVisible) {
    yield take(onAccept.type)
    yield call(payload.onAccept!)

    yield take(onReject.type)
    yield call(payload.onReject!)
  }
}

export default function* watchDialogSagas() {
  yield all([takeLatest(openDialog.type, dialogSaga)])
}
