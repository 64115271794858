import React from 'react'
import { Modal, Heading, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import './HelpModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEnv } from '@praxis/component-runtime-env'

export interface IHelpModalProps {
  closeModal: () => void
}

const EditCollectionModal: React.FC<IHelpModalProps> = ({ closeModal }) => {
  const { slackUrl, feedbackFormUrl } = useEnv()
  return (
    <Modal
      className="modal-parent-container"
      headingText="Help"
      isVisible
      onRefuse={() => closeModal()}
      onApproveModal={() => {}}
    >
      <Grid.Container align="center" justify="center" direction="column" spacing="expanded" className="hc-pa-xl">
        <Heading size={4} className="hc-pv-normal">
          Please contact us with any concerns!
        </Heading>
        <Button
          size="expanded"
          as="a"
          href={slackUrl}
          target="_blank"
          className="help-button hc-ma-normal"
          data-testid="help-modal-slack-button"
        >
          <div className="slack-image hc-mr-normal"></div>
          <Heading size={6}>#gallery_support</Heading>
        </Button>
        <Button
          as="a"
          className="help-button hc-ma-normal"
          href={feedbackFormUrl}
          target="_blank"
          data-testid="help-modal-question-button"
        >
          <Button type="primary" iconOnly size="dense">
            <FontAwesomeIcon icon="question" />
          </Button>
          <Heading size={6} className="hc-ml-normal hc-pv-normal">
            Open Feedback Form
          </Heading>
        </Button>
      </Grid.Container>
    </Modal>
  )
}

export default EditCollectionModal
