import React from 'react'
import { useState } from 'react'
import { Modal } from '@enterprise-ui/canvas-ui-react'
import AddAssetsToNewCollectionModal from '../AddAssetsToNewCollectionModal/AddAssetsToNewCollectionModal'
import AddAssetsToExistingCollectionModal from '../AddAssetsToExistingCollectionModal/AddAssetsToExistingCollectionModal'
// TODO: Consolidate these styles. ATM it's kinda sloppy.
import existingCollectionStyles from './../AddAssetsToExistingCollectionModal/AddAssetsToExistingCollectionModal.module.scss'
import newCollectionStyles from './../AddAssetsToNewCollectionModal/AddAssetsToNewCollectionModal.module.scss'

type IAssetCollectionModalProps = {
  closeModal: () => void
  data?: any
}

const AssetCollectionModal: React.FC<IAssetCollectionModalProps> = ({ closeModal, data }) => {
  const [isCreateNewCollectionOpen, setIsCreateNewCollectionOpen] = useState<boolean>(false)

  return (
    <Modal
      className={`hc-pb-dense ${
        isCreateNewCollectionOpen
          ? newCollectionStyles['create-and-add-to-collection-modal']
          : existingCollectionStyles['add-to-collection-modal']
      }`}
      isVisible
      onRefuse={() => closeModal()}
      onApproveModal={() => {}}
    >
      <div>
        <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">
          {isCreateNewCollectionOpen ? 'Create a new collection' : 'Add to collection'}
        </h1>
      </div>
      <AddAssetsToNewCollectionModal
        data={data}
        container
        visible={isCreateNewCollectionOpen}
        closeModal={() => closeModal()}
        onBack={() => {
          setIsCreateNewCollectionOpen(false)
        }}
      />
      <AddAssetsToExistingCollectionModal
        data={data}
        closeModal={() => closeModal()}
        container
        visible={!isCreateNewCollectionOpen}
        onCreateNewCollection={() => {
          setIsCreateNewCollectionOpen(true)
        }}
      />
    </Modal>
  )
}

export default AssetCollectionModal
