import { loadSaved } from './slice'
import { Middleware } from 'redux'

// TODO: Pull features from DB by default and set them in sessionStorage. Then on subsequent page refreshes, prioritize pulling from sessionStorage.
export const featureFlagMiddleware: Middleware = store => next => action => {
  if (action.type === 'START_GALLERY') {
    const featuresInStorage = sessionStorage.getItem('features')
      ? JSON.parse(sessionStorage.getItem('features') as string)
      : []
    store.dispatch(loadSaved(featuresInStorage))
  }
  return next(action)
}
