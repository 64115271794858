export enum Phase {
  Dev = 'DEV',
  Stage = 'STAGE',
  Prod = 'PROD',
}

export interface IEnvironment {
  prod: Boolean
  stage: Boolean
  dev: Boolean
  [key: string]: Boolean
}

export const defaultFFEnv: IEnvironment = {
  prod: false,
  stage: false,
  dev: false,
}

export interface IFeatureFlag {
  id?: string
  name: string
  phase: Phase
  environment?: IEnvironment
}
