// import 'canvas-ui-css-marquette'
import { BrowserRouter as Router, Routes, useLocation, Route, Navigate } from 'react-router-dom'
import { useEnv } from '@praxis/component-runtime-env'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { useEffect, useState } from 'react'
import AssetDetailPage from 'components/pages/AssetDetailPage/AssetDetailPage'
import BaseLayout from 'containers/BaseLayout/BaseLayout'
import CollectionDetailPage from 'components/pages/CollectionDetailPage/CollectionDetailPage'
import CollectionsPage from 'components/pages/CollectionsPage/CollectionsPage'
import NotFoundPage from 'components/pages/NotFoundPage/NotFoundPage'
import SubcategoryLandingPage from 'components/pages/SubcategoryLandingPage/SubcategoryLandingPage'
import React from 'react'
import ReactGA from 'react-ga'
import SearchPage from 'components/pages/SearchPage/SearchPage'
import UserPage from 'components/pages/UserPage/UserPage'
import HomePage from 'components/pages/HomePage/HomePage'
import CategoryPage from 'components/pages/CategoryPage/CategoryPage'
import SystemErrorPage from 'components/pages/ErrorPage/SystemErrorPage'
import './GlobalStyles.scss'
import { includesCaseInsensitive } from 'utils/string'

// Initialize Font Awesome & load needed icons.
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEllipsisV,
  faDownload,
  faInfoCircle,
  faArrowCircleDown,
  faCube,
  faHeart,
  faCheckSquare,
  faClock,
  faPlus,
  faFilter,
  faFileImage,
  faFile,
  faSearch,
  faSpinner,
  faCheck,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faAngleLeft,
  faAngleRight,
  faUser,
  faNewspaper,
  faPuzzlePiece,
  faFolder,
  faFolderOpen,
  faUsersCog,
  faTags,
  faQuestion,
  faTrash,
  faSortUp,
  faSortDown,
  fas,
  faMinusCircle,
  faLink,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'
import { faCircle, faDotCircle, faHeart as faHeartSolid, far, faSquare } from '@fortawesome/free-regular-svg-icons'
import { useGallery } from 'hooks'
import { useDispatch } from 'react-redux'
import { setCollectionPageSize, setSearchSize, setEnvironment } from 'store'
import CacheBuster from './cacheBuster/CacheBuster'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import GalleryToaster from './shared/Toaster'
library.add(
  fas,
  far,
  faEllipsisV,
  faDownload,
  faInfoCircle,
  faArrowCircleDown,
  faHeart,
  faHeartSolid,
  faCheckSquare,
  faCircle,
  faDotCircle,
  faSquare,
  faPlus,
  faFilter,
  faSearch,
  faTimes,
  faCube,
  faFileImage,
  faFile,
  faSpinner,
  faCheck,
  faClock,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faAngleLeft,
  faAngleRight,
  faUser,
  faNewspaper,
  faPuzzlePiece,
  faFolder,
  faFolderOpen,
  faUsersCog,
  faTags,
  faQuestion,
  faTrash,
  faSortUp,
  faSortDown,
  faMinusCircle,
  faLink,
  faExchangeAlt
)

const AppRoutes: React.FC = () => {
  // Needs to be wrapped by ENV provider
  const dispatch = useDispatch()
  const { defaultCollectionPageSize, defaultSearchSize, environment } = useEnv()

  useEffect(() => {
    dispatch(setCollectionPageSize(defaultCollectionPageSize))
    dispatch(setSearchSize(defaultSearchSize))
    dispatch(setEnvironment(environment))
  }, [])

  return (
    <BaseLayout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/asset/:id/media/:fileId" element={<AssetDetailPage />} />
        <Route path={'/asset/:id'} element={<AssetDetailPage />} />
        <Route path="/collection/:id" element={<CollectionDetailPage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/collections/:set" element={<CollectionsPage />} />
        <Route path="/collections" element={<Navigate to="/collections/favorite" />} />
        <Route path="/subcategory/:name" element={<SubcategoryLandingPage />} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/error" element={<SystemErrorPage />} />
        <Route
          path="/callback"
          action={async ({ request }) => {
            console.log(`${request} - CALLBACK HIT`)
          }}
        />
        {/* <ProtectedRoute exact={false} path="/features" element={<FeaturesPage />} /> */}
        <Route element={<NotFoundPage />} />
      </Routes>
    </BaseLayout>
  )
}

const DEFAULT_PAGE_TITLE = document.title

const GoogleAnalytics: React.FC<{ children: any }> = ({ children }) => {
  const { googleAnalyticsId } = useEnv()
  const [pageTitle, setPageTitle] = useState<string>(DEFAULT_PAGE_TITLE)

  // Setup / do initial page track.
  useEffect(() => {
    const { pathname, search } = window.location
    ReactGA.initialize(googleAnalyticsId)
    ReactGA.pageview(pathname + search)
  }, [googleAnalyticsId])

  // Track page changes.
  const location = useLocation()
  useEffect(() => {
    const { pathname } = location
    ReactGA.send(['pageview', pathname])

    // Also, update the title.

    //TODO: Deterrmine a better way to do this with protected routes
    if (pathname === '/') {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Home`)
    } else if (includesCaseInsensitive(pathname, '/collections/my')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - My Collections`)
    } else if (includesCaseInsensitive(pathname, '/collections/public')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Public Collections`)
    } else if (includesCaseInsensitive(pathname, '/collections')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Collections`)
    } else if (includesCaseInsensitive(pathname, '/collection')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Collection`)
    } else if (includesCaseInsensitive(pathname, '/asset/')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Asset`)
    } else if (includesCaseInsensitive(pathname, '/subcategory/')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Subcategory`)
    } else if (includesCaseInsensitive(pathname, '/category/')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Category`)
    } else if (includesCaseInsensitive(pathname, '/search')) {
      setPageTitle(`${DEFAULT_PAGE_TITLE} - Search`)
    } else {
      setPageTitle(`${DEFAULT_PAGE_TITLE}`)
    }
  }, [location])

  useEffect(() => {
    document.title = pageTitle
  }, [pageTitle])

  return <>{children}</>
}

const App = () => {
  useGallery(true)
  const { environment } = useEnv()

  const Gallery = () => {
    return (
      <Router>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <GoogleAnalytics>
            <AppRoutes />
          </GoogleAnalytics>
        </QueryParamProvider>
      </Router>
    )
  }

  return (
    <CacheBuster env={environment}>
      <ToastProvider>
        <GalleryToaster />
        <Gallery />
      </ToastProvider>
    </CacheBuster>
  )
}

export default App
