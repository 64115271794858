import { createSlice, createAction } from '@reduxjs/toolkit'
import { IDialogActionData, IDialogData } from 'store'

const initialState: IDialogData = {
  headerText: '',
  message: '',
  refuseButtonText: '',
  approveButtonText: '',
  isVisible: false,
}

export const openDialog = createAction<IDialogActionData>('OPEN_DIALOG')

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialogData(state, action) {
      state.headerText = action.payload.headerText
      state.message = action.payload.message
      state.refuseButtonText = action.payload.refuseButtonText
      state.approveButtonText = action.payload.approveButtonText
      state.isVisible = action.payload.isVisible
    },
    closeDialog(state) {
      state.headerText = ''
      state.message = ''
      state.refuseButtonText = ''
      state.approveButtonText = ''
      state.isVisible = false
    },
    onAccept(state, action) {},
    onReject(state, action) {},
  },
})

export const { setDialogData, closeDialog, onAccept, onReject } = dialogSlice.actions

export default dialogSlice.reducer
