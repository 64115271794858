import { all, takeLatest, call, put } from 'redux-saga/effects'
import { addToast, getAssetReducer, processError, removeToast } from 'store'
import { updateFileAPI, deleteFileAPI, setAssetThumbnailApi, downloadMultipleFilesAPI, downloadFileAPI } from './api'
import {
  updateFileReducer,
  deleteFileReducer,
  updateAssetThumbnailReducer,
  downloadMultipleFilesReducer,
  downloadFileReducer,
} from './slice'
import { STORAGE_KEYS } from '../../config'
import { getAssetSaga } from 'store/asset/sagas'

function* updateFileSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield call(
      updateFileAPI,
      xTgtLanId,
      data.payload.assetId,
      data.payload.fileId,
      data.payload.fileName,
      data.payload.heroImage
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export function* deleteFileSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield call(deleteFileAPI, xTgtLanId, data.payload.fileIds, data.payload.assetId)
    yield call(getAssetSaga, { payload: data.payload.assetId })
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export function* downloadMultipleFilesSaga(data: any) {
  const downloadToast = {
    color: 'success',
    headingText: 'Files Downloading',
    message: 'Files Download Initiated, Please Wait',
    autoHideDuration: 90000,
  }
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(addToast(downloadToast))
    yield call(downloadMultipleFilesAPI, xTgtLanId, data.payload.assetId, data.payload.fileIds)
  } catch (error) {
    if (error.isAxiosError) {
      const errorResponse = JSON.parse(Buffer.from(error.response.data).toString('utf8'))
      const errorMessage = errorResponse.message
      yield call(processError, error, errorMessage)
    } else if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  } finally {
    yield put(removeToast(downloadToast))
  }
}

export function* downloadFileSaga(data: any) {
  const downloadToast = {
    color: 'success',
    headingText: 'File Downloading',
    message: 'File Download Initiated, Please Wait',
    autoHideDuration: 90000,
  }
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(addToast(downloadToast))
    yield call(downloadFileAPI, xTgtLanId, data.payload.fileUrl)
  } catch (error) {
    if (error.isAxiosError) {
      const errorResponse = JSON.parse(Buffer.from(error.response.data).toString('utf8'))
      const errorMessage = errorResponse.message
      yield call(processError, error, errorMessage)
    } else if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  } finally {
    yield put(removeToast(downloadToast))
  }
}

function* updateAssetThumbnailSaga(data: any) {
  const { fileId, assetId } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    yield call(setAssetThumbnailApi, xTgtLanId, fileId, assetId)
    yield put(
      addToast({
        color: 'success',
        headingText: 'Thumbnail Updated',
        message: `The ${fileId} file has been set as the Asset thumbnail.`,
      })
    )
    yield put(getAssetReducer(assetId))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export default function* watchFilesSagas() {
  yield all([
    takeLatest(updateFileReducer.type, updateFileSaga),
    takeLatest(deleteFileReducer.type, deleteFileSaga),
    takeLatest(updateAssetThumbnailReducer.type, updateAssetThumbnailSaga),
    takeLatest(downloadMultipleFilesReducer.type, downloadMultipleFilesSaga),
    takeLatest(downloadFileReducer.type, downloadFileSaga),
  ])
}
