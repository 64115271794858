import {
  CategoryLandingView,
  FileView,
  SingleResponseTaxonomyTree,
  TaxonomyAssetCountsResponse,
} from 'gallerydigitalassets-v1-client'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { IAsset, ImageStatus, processError } from 'store'
import { getCategoryAssetsApi, getTaxonomiesApi, getSubcategoryAssetsApi, getCarouselItemsApi } from './api'
import {
  getCategoryAssets,
  getTaxonomies,
  setCategoryAssets,
  setCategoryAssetStatus,
  setLoading,
  setTaxonomies,
  getSubcategoryPageAssets,
  setSubcategoryPageAssets,
  appendSubcategoryPageAssets,
  getCarouselItemsReducer,
  setCarouselItems,
  setSubcategoryPageAssetsPaginationIsLoading,
} from './slice'
import { STORAGE_KEYS } from '../../config'

const extractImages = ({ assetFileViewKt }: CategoryLandingView): FileView[] => {
  let imageFiles: FileView[] = []

  if (assetFileViewKt?.length! > 0) {
    assetFileViewKt?.forEach(item => {
      if (item.fileSources && item.fileSources?.length > 0) {
        imageFiles = [...imageFiles, item]
      }
    })
  }

  return imageFiles
}

// GET TAXONOMIES
function* getTaxonomiesSaga() {
  try {
    // Call API end point for taxonomies
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const { data }: SingleResponseTaxonomyTree = yield call(getTaxonomiesApi, xTgtLanId)
    // Insert taxonomies into state and set loading to false.
    yield put(setTaxonomies(data.taxonomies))
    yield put(setLoading(false))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* getCategoryAssetsSaga(data: any) {
  try {
    yield put(setCategoryAssetStatus(ImageStatus.LOADING))
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const categoryAssets: CategoryLandingView[] = yield call(getCategoryAssetsApi, xTgtLanId, data.payload)
    const categorizedAssets = categoryAssets.map(clv => {
      return { id: clv.categoryId, imageFiles: extractImages(clv) }
    })
    yield put(setCategoryAssets(categorizedAssets))
    yield put(setCategoryAssetStatus(ImageStatus.SUCCESS))
  } catch (error) {
    yield put(setCategoryAssetStatus(ImageStatus.ERROR))
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* getSubcategoryPageAssetsSaga(data: any) {
  yield put(setSubcategoryPageAssetsPaginationIsLoading(true))
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const response: { taxonomyAssets: IAsset[]; totalCount: number } = yield call(
      getSubcategoryAssetsApi,
      xTgtLanId,
      data.payload.taxonomyId,
      data.payload.page
    )
    if (data.payload.page > 0) {
      yield put(
        appendSubcategoryPageAssets({
          assets: response.taxonomyAssets,
          page: data.payload.page,
          totalCount: response.totalCount,
        })
      )
    } else {
      yield put(setSubcategoryPageAssets({ assets: response.taxonomyAssets, totalCount: response.totalCount }))
    }
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* getCarouselItemsSaga(data: any) {
  try {
    const { taxonomyId, server, key } = data.payload
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const response: TaxonomyAssetCountsResponse[] = yield call(getCarouselItemsApi, xTgtLanId, taxonomyId, server, key)
    yield put(setCarouselItems(response))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export default function* watchTaxonomySagas() {
  yield all([
    takeLatest(getTaxonomies.type, getTaxonomiesSaga),
    takeLatest(getCategoryAssets.type, getCategoryAssetsSaga),
    takeLatest(getSubcategoryPageAssets.type, getSubcategoryPageAssetsSaga),
    takeLatest(getCarouselItemsReducer.type, getCarouselItemsSaga),
  ])
}
