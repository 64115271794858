import { AssetFileView, FileSourceView, FileView } from 'gallerydigitalassets-v1-client'
import { uniq, isEmpty, take } from 'lodash'
function filterImages(assets: any) {
  const imageFiles: FileView[] = uniq(
    take(
      assets
        .filter((file: FileView) => {
          return !isEmpty(file)
        })
        .reduce((files: FileView[], asset: AssetFileView) => {
          return files.concat(asset?.files!!)
        }, []),
      3
    )
  )

  return { imageFiles, imageCount: imageFiles.length }
}

const useCollectionThumbs = () => {
  // Has to account for both ICollection and GallerySearchViewKT types - Need to refactor.

  const collectionThumbs = (collection: any) => {
    if (collection.thumbnailFileUrls) {
      let imageFiles: FileView[] = []
      collection.thumbnailFileUrls.forEach((thumbnailFileUrl: string) => {
        let fileSource: FileSourceView = {
          id: '',
          fileName: '',
          fileType: '',
          fileExtension: '',
          md5Hash: '',
          tossKey: '',
          tossBucketId: '',
          eTag: '',
          contentDisposition: '',
          tcinUrl: '',
          etag: '',
          accessUrl: thumbnailFileUrl,
          image: true,
        }

        let imageFile: FileView = {
          id: '',
          name: '',
          classification: FileView.ClassificationEnum.Thumbnail,
          heroImage: true,
          original: false,
          createdAt: collection.createdAt,
          updatedAt: collection.updatedAt,
          fileSources: [fileSource],
          renderPerspective: '',
          renderResolution: '',
          fileTags: [],
        }
        imageFiles.push(imageFile)
      })
      return {
        imageFiles,
        imageCount: imageFiles.length,
      }
    }
    if (collection?.assets) {
      const { assets } = collection
      return filterImages(assets)
    }
    if (collection?.galleryAssets) {
      const { galleryAssets } = collection
      return filterImages(galleryAssets)
    }
    if (collection?.files) {
      const imageFiles: FileView[] = collection.files
      return { imageFiles, imageCount: imageFiles.length }
    } else {
      return { imageFiles: [], imageCount: 0 }
    }
  }
  return { collectionThumbs }
}

export default useCollectionThumbs
