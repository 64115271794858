import { createSlice } from '@reduxjs/toolkit'
import { IEnv } from './types'

const initialState: IEnv = {
  collectionPageSize: 25,
  searchSize: 25,
  environment: 'dev',
}

const env = createSlice({
  name: 'env',
  initialState,
  reducers: {
    setCollectionPageSize: (state, action) => {
      state.collectionPageSize = action.payload
    },
    setSearchSize: (state, action) => {
      state.searchSize = action.payload
    },
    setEnvironment: (state, action) => {
      state.environment = action.payload
    },
  },
})

export const { setCollectionPageSize, setSearchSize, setEnvironment } = env.actions

export default env.reducer
