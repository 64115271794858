import { some } from 'lodash'
import { assetTagApi } from '../client'
import { HttpError, TagBaseView, TagRequest } from 'gallerydigitalassets-v1-client'

// TAGS ENDPOINTS
export const getAssetDetailTagsAPI = async (
  xTgtLanId: string,
  assetId: string,
  assetDetails: { [key: string]: string[] }
) => {
  const assetDetailTags: { [key: string]: TagBaseView[] } = {}
  try {
    const { body } = await assetTagApi.getAssetTags(assetId, false, xTgtLanId)
    if (body.data) {
      let tagBaseViews: TagBaseView[] = body.data.filter(tagBaseView => tagBaseView.value !== 'null')
      const tagsByKey: { [key: string]: TagBaseView[] } = {}
      tagBaseViews.forEach(tagBaseView => {
        tagsByKey[tagBaseView.key.toLowerCase()] = tagBaseViews.filter(
          tag => tagBaseView.key.toLowerCase() === tag.key.toLowerCase()
        )
      })
      Object.keys(assetDetails).forEach((assetDetailKey: string) => {
        const keys = assetDetails[assetDetailKey]
        let filteredTags = keys
          .map(key => tagsByKey[key.toLowerCase()])
          .filter(tagBaseView => tagBaseView !== undefined)
        const value: TagBaseView[] = []
        filteredTags.forEach(tags => {
          const newTag = new TagBaseView()
          newTag.id = tags[0].id
          newTag.key = tags[0].key.toLowerCase()
          if (tags.length === 1) {
            newTag.value = tags[0].value
          } else {
            newTag.value = tags.map(tag => tag.value).join(', ')
          }
          value.push(newTag)
        })
        if (some(value)) {
          assetDetailTags[assetDetailKey] = value
        }
      })
    }
    return assetDetailTags
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const getCustomAssetTagsAPI = async (xTgtLanId: string, assetId: string) => {
  try {
    const { body } = await assetTagApi.getAssetTags(assetId, true, xTgtLanId)
    return body.data
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const addCustomAssetTagAPI = async (xTgtLanId: string, assetId: string, tagValue: string) => {
  try {
    const tagRequest = new TagRequest()
    tagRequest.value = tagValue
    tagRequest.classification = TagRequest.ClassificationEnum.User
    tagRequest.tagType = TagRequest.TagTypeEnum.Custom
    const { body } = await assetTagApi.createAssetTags(assetId, xTgtLanId, [tagRequest], true)
    return body.data
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}

export const removeCustomAssetTagAPI = async (xTgtLanId: string, assetId: string, tag: TagBaseView) => {
  try {
    await assetTagApi.deleteAssetTag(assetId, tag.key, tag.value, xTgtLanId)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}
