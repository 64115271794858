import SearchBar from 'components/SearchBar/SearchBar'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import './HeroSection.scss'

const HeroSection = () => {
  return (
    <div className="hero-background" data-testid="background-pic">
      <Grid.Container direction="column" align="center" data-testid="searchbar-container">
        <Grid.Item data-testid="searchbar-item">
          <SearchBar className="hero-search" data-testid="search-overview" showSelect={true} />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default HeroSection
