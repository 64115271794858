import { RootState } from 'store'
import { createSelector } from 'reselect'

export const userSelector = createSelector(
  (state: RootState) => state.user,
  state => state
)

export const userInfoSelector = createSelector(userSelector, state => state.userDetails)
export const userIdentifiersSelector = createSelector(userSelector, state => state.userIdentifiers)
export const userAuthSelector = createSelector(userSelector, state => state.userUnauthorized)
export const adGroupMissingSelector = createSelector(userSelector, state => state.adGroupMissing)
