import * as React from 'react'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'
import ReactGA from 'react-ga'
import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import './DownloadAssetButton.scss'
import { useAsset } from 'store'

type DownloadAssetProps = {
  assetIds: string[]
  isButton: boolean
}

const DownloadAssetButton: React.FC<DownloadAssetProps> = ({ assetIds, isButton }) => {
  const { downloadAssetLocally, downloadAssetsAsync } = useAsset()
  return (
    <>
      {!!isButton && (
        <Tooltip content="Download" location="top">
          <Button
            type="primary"
            className="download-asset-button"
            data-testid="download-selected-assets-button"
            disabled={assetIds.length === 0}
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
              downloadAssetsAsync(e, assetIds)
            }}
          >
            <EnterpriseIcon icon={DownloadIcon} />
          </Button>
        </Tooltip>
      )}

      {!isButton && (
        <Tooltip content="Download" location="top">
          <span
            data-testid="download-button-card"
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
              ReactGA.event({
                category: 'Asset',
                action: 'Downloaded via Thumbnail',
              })
              e.preventDefault()
              downloadAssetLocally(e, assetIds[0])
            }}
          >
            <EnterpriseIcon icon={DownloadIcon} />
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default DownloadAssetButton
