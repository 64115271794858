import React from 'react'
import { Modal, Button, Chip } from '@enterprise-ui/canvas-ui-react'
import GalleryFileImage from 'components/images/GalleryFileImage'
import './CollageLightboxModal.scss'
import { FileView } from 'gallerydigitalassets-v1-client'

export interface ILightboxModalData {
  file: FileView
}

export interface ILightboxModalProps {
  data: ILightboxModalData
  closeModal: () => void
}

const CollageLightboxModal: React.FC<ILightboxModalProps> = ({ data, closeModal }) => {
  const { file } = data
  return (
    <Modal onRefuse={() => closeModal()} isVisible>
      <div className="lightbox-container">
        <GalleryFileImage
          file={file}
          height="100%"
          width="100%"
          type="contain"
          src={file.fileSources[0].accessUrl}
          alt={file.name}
        />
        {file.name !== 'tcin-image' && (
          <Button
            className="download-file-button"
            size="dense"
            onClick={() => (window.location.href = `${file.fileSources[0].accessUrl}`)}
          >
            Download
          </Button>
        )}
        <Chip size="dense" className="lightbox-chip">
          {file.fileSources[0].fileName}
        </Chip>
      </div>
    </Modal>
  )
}

export default CollageLightboxModal
