import { TagBaseView } from 'gallerydigitalassets-v1-client'
import { schema } from 'normalizr'

export interface ITagState {
  loading: boolean
  assetDetailTagsFeed: { [key: string]: TagBaseView[] }
  customAssetTagsFeed: TagBaseView[]
}

type ITagTag = {
  key: string
  value: string
}

export interface ITag {
  id: string
  tag: ITagTag
  createdAt: number
  updatedAt: number
}

export const tagSchema = new schema.Entity('tag')
