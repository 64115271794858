import { useAsset, useTaxonomy } from 'store'
import { uniq } from 'lodash'
import './LinkedAssetTable.scss'
import LinkedAssetDrawer from './LinkedAssetDrawer'
import { useEffect, useState } from 'react'
import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'

export enum globalDrawerState {
  ALL_CLOSED = 'CLOSED',
  INTERMEDIATE = 'INTERMEDIATE',
  ALL_OPEN = 'OPEN',
}

export default function LinkedAssetTable() {
  const { linkedAssets } = useAsset()
  const { getCategoryById } = useTaxonomy()
  const [taxonomies, setTaxonomies] = useState<TaxonomyNodeView[]>([])

  const loadTaxonomies = async (taxonomyIds: string[]) => {
    setTaxonomies(
      uniq((await Promise.all(taxonomyIds.map(async taxonomyId => getCategoryById(taxonomyId)))) as TaxonomyNodeView[])
    )
  }

  useEffect(() => {
    if (linkedAssets) {
      const taxonomies = linkedAssets?.map(linkedAsset => linkedAsset.taxonomyId) as string[]
      loadTaxonomies(taxonomies)
    }
  }, [linkedAssets])

  return (
    <>
      {linkedAssets &&
        taxonomies.map(taxonomy => {
          const filteredAssets = linkedAssets.filter(asset => {
            return asset.taxonomyId === taxonomy.id
          })
          return <LinkedAssetDrawer taxonomy={taxonomy.name} assets={filteredAssets} />
        })}
    </>
  )
}
