import { Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import BasePage from 'components/pages/BasePage/BasePage'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

interface IErrorState {
  name: string
  href?: string
  redirect?: Boolean
}

const ErrorState = ({ name, href = '/', redirect = true }: IErrorState) => {
  const navigate = useNavigate()
  useEffect(() => {
    const homePageRedirect = setTimeout(() => {
      if (redirect) {
        navigate(href)
      }
    }, 6000)

    return () => {
      clearTimeout(homePageRedirect)
    }
  }, [])

  const errorStateMessage = `The ${name.toLowerCase()} you were looking for is not here.`

  return (
    <BasePage crumbs={[]}>
      <Grid.Container direction="column" className="content-container hc-pa-lg" justify="center" align="center">
        <Grid.Item xs={12}>
          <Heading>{`${name} not found`}</Heading>
        </Grid.Item>
        <Grid.Item xs={12}>
          <p data-testid="error-state-message">{errorStateMessage}</p>
          <p>
            If you believe this occurred in error, please email{' '}
            <a className="hc-clr-interactive" href="mailto:gallery-team@target.com">
              gallery-team@target.com
            </a>{' '}
            and let them know about it.
          </p>
        </Grid.Item>
      </Grid.Container>
    </BasePage>
  )
}

export default ErrorState
