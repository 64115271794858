import { find } from 'lodash'

export const isArray = (a: any): boolean => Array.isArray(a)
export const arrayEquals = (a: Array<any>, b: Array<any>) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
}

export const findMismatches = (array1: Array<any>, array2: Array<any>, parameter: any) => {
  let newArray1ToReturn = [...array1]
  let newArray2ToReturn = [...array2]
  for (const array1Unit of array1) {
    if (
      find(array2, function (array2Unit) {
        return array2Unit[parameter] === array1Unit[parameter]
      })
    ) {
      const indexOfArray1 = newArray1ToReturn?.findIndex(
        array1Element => array1Unit[parameter] === array1Element[parameter]
      )
      const indexOfArray2 = newArray2ToReturn?.findIndex(
        array2Element => array1Unit[parameter] === array2Element[parameter]
      )
      newArray1ToReturn.splice(indexOfArray1, 1)
      newArray2ToReturn.splice(indexOfArray2, 1)
    }
  }
  return { newArray1: newArray1ToReturn, newArray2: newArray2ToReturn }
}

export const arraySort = (array: Array<any>, key?: any) => {
  if (key) {
    return array.sort((a, b) => (a[key] > b[key] ? 1 : -1))
  }
  return array.sort((a, b) => (a > b ? 1 : -1))
}

export const findStringInArray = (arr: String[], str: String, ignoreCase: Boolean) => {
  const index = arr.findIndex(element => {
    if (ignoreCase) {
      return element.toLocaleLowerCase() === str.toLocaleLowerCase()
    }
    return element === str
  })
  return index >= 0
}
