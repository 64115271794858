import { useDispatch } from 'react-redux'
import { useEnv } from '@praxis/component-runtime-env'
import { some } from 'lodash'
import { addToast } from 'store'
import { match } from 'utils/validation'

const useFileUpload = () => {
  const dispatch = useDispatch()
  const { denyListedFileTypes, dropZoneNameMatcher } = useEnv()

  const isFileDenyListed = async (fileNames: string[]) => {
    const denyListedFiles = fileNames.filter((fileName: string) =>
      denyListedFileTypes.includes(fileName.split('.').pop()?.toLowerCase())
    )
    if (some(denyListedFiles)) {
      const message = 'Denylisted file extension attempted to be uploaded.'
      dispatch(addToast({ color: 'error', headingText: 'Cannot upload', message }))
    }
    return some(denyListedFiles)
  }

  const validateFileNames = async (fileNames: string[]): Promise<string[]> => {
    const regex = new RegExp(dropZoneNameMatcher.expression)
    return new Promise((resolve, reject) => {
      const invalidNames = fileNames.filter(fileName => {
        return !match(fileName, regex)
      })

      if (some(invalidNames)) {
        dispatch(
          addToast({
            color: 'error',
            headingText: `Invalid file name(s) detected`,
            message: `${dropZoneNameMatcher.message}. \n You have ${invalidNames.length} invalid file name(s).`,
            autoHideDuration: 5000,
          })
        )
        reject(invalidNames)
      } else {
        resolve(invalidNames)
      }
    })
  }

  return { isFileDenyListed, validateFileNames }
}

export default useFileUpload
