import { useSelector } from 'react-redux'
import { collectionPageSizeSelector, searchSizeSelector, environmentSelector } from './selectors'

export const useAppEnv = () => {
  const collectionPageSize = useSelector(collectionPageSizeSelector)
  const searchSize = useSelector(searchSizeSelector)
  const environment = useSelector(environmentSelector)

  return { collectionPageSize, searchSize, environment }
}
