import React, { useEffect, useState } from 'react'
import BasePage from 'components/pages/BasePage/BasePage'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import { useLocation, useParams } from 'react-router-dom'
import CategoryFeed from 'components/feeds/CategoryFeed/CategoryFeed'
import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'
import { BreadCrumbPriority, processError, useTaxonomy } from 'store'

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const CategoryPage: React.FC = () => {
  const [taxonomy, setTaxonomy] = useState<TaxonomyNodeView | null>()
  const { category } = useParams()
  const location = useLocation()

  const title = capitalize(category!)
  const crumb = { name: title, priority: BreadCrumbPriority.High }

  const { getCategory, isLoading, resetCategoryAssets } = useTaxonomy()

  const pullCategory = async () => {
    try {
      const node = (await getCategory(title)) as TaxonomyNodeView
      setTaxonomy(node)
    } catch (error) {
      if (error instanceof Error) {
        processError(error, error.message, true)
      } else {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    setTaxonomy(null)
    resetCategoryAssets()
  }, [location.pathname])

  useEffect(() => {
    if (!taxonomy && !isLoading) {
      pullCategory()
    }
  }, [taxonomy, isLoading])

  return (
    <BasePage crumbs={crumb}>
      <Grid.Container direction="column" className="centered-feed-content">
        <Grid.Item xs={12}>
          <Grid.Container className="hc-pt-lg hc-pb-xl">
            <Grid.Item>
              <Heading data-testid="category-name" size={2}>
                {capitalize(category!)}
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="column" className="hc-pv-md">
            {taxonomy && (
              <Grid.Item data-testid="category-feed">
                <CategoryFeed taxonomy={taxonomy} />
              </Grid.Item>
            )}
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </BasePage>
  )
}

export default CategoryPage
