import React from 'react'

import { Grid } from '@enterprise-ui/canvas-ui-react'
import './BasePage.scss'
import BreadCrumbs from 'components/shared/BreadCrumbs'
import { useBreadCrumbs } from 'store'

const BasePage = (props: any) => {
  const { children, crumbs } = props

  useBreadCrumbs(crumbs ? crumbs : null)

  return (
    <Grid.Container direction="column">
      <Grid.Item xs={12} className="hc-bg-contrast-weak hc-pl-none">
        <BreadCrumbs />
        {/* <Breadcrumbs className="hc-ml-lg" crumbs={[...BASE_CRUMBS, ...crumbs]}></Breadcrumbs> */}
      </Grid.Item>
      {/* fixes issue with items not fitting their contents */}
      <div className="hc-pa-normal hc-pa-none">
        <Grid.Item xs={12} className="main-content hc-pt-normal hc-bg-contrast-weak">
          {children}
        </Grid.Item>
      </div>
    </Grid.Container>
  )
}

export default BasePage
