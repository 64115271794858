import { Heading, Grid, GridContainer, Dropdown, Button, Input } from '@enterprise-ui/canvas-ui-react'
import { numberWithCommas } from 'utils/number'
import { find } from 'lodash'
import React from 'react'
import './AssetFeed.module.scss'
import {
  IAsset,
  ZeroStateToShowEnum,
  useAuth,
  setModalType,
  setModalData,
  useAsset,
  useCollections,
  openDialog,
} from 'store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES } from 'config'
import { useParams } from 'react-router-dom'

type AssetFeedActionsProps = {
  assets: IAsset[]
  totalCount?: number
  zeroStateToShow: ZeroStateToShowEnum
  showAssetsLabel?: boolean
  checkedItems: string[]
  setCheckedItems: (checkedItems: string[]) => void
  showResultsLabel?: boolean
}

const AssetFeedActions: React.FC<AssetFeedActionsProps> = ({
  assets,
  showAssetsLabel,
  zeroStateToShow,
  totalCount,
  checkedItems,
  setCheckedItems,
  showResultsLabel,
}) => {
  const { canUserCreateContent } = useAuth()
  const { downloadAssetsAsync } = useAsset()
  const { deleteGalleryAsset } = useCollections()
  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch()

  const addToCollection = (selectedAssets: IAsset[]) => {
    dispatch(setModalType(MODAL_TYPES.ADD_COLLECTION))
    dispatch(setModalData(selectedAssets))
  }

  const deleteFromCollection = async (assetIds: string[], id: string) => {
    await deleteGalleryAsset(id!, assetIds)
    setCheckedItems([])
  }

  const getResultsLabel = () =>
    (totalCount || 0) === 1 ? `${totalCount} result found` : `${numberWithCommas(totalCount || 0)} results found`

  const isUserACreator = canUserCreateContent()

  const assetText = checkedItems.length === 1 ? 'asset' : 'assets'

  return (
    <GridContainer direction="row" justify="space-between" className="hc-ph-normal hc-pt-none hc-pb-md" align="center">
      <Grid.Item className="padding-fix-safari">
        <Grid.Container justify="flex-start" align="center" className="margin-fix-safari">
          {!!showResultsLabel && (
            <Heading size={5}>
              <span data-test-id="results-label">{getResultsLabel()}</span>
            </Heading>
          )}
          {!!showAssetsLabel && <Heading size={4}>Assets</Heading>}
        </Grid.Container>
      </Grid.Item>
      <Grid.Item>
        <Grid.Container className="margin-fix-safari" direction="row" justify="flex-end" align="center">
          <Grid.Item className="hc-pr-none hc-pl-none">
            <Input.Checkbox
              indeterminate={checkedItems.length > 0 && checkedItems.length < assets.length}
              data-testid={`select-all-assets-checkbox`}
              checked={checkedItems.length > 0}
              onChange={() => {
                if (checkedItems.length === assets.length) {
                  setCheckedItems([])
                  return
                }
                setCheckedItems(assets.map(asset => asset.id))
              }}
              className="hc-mt-dense"
            />
          </Grid.Item>
          <Grid.Item className="hc-pl-none">
            <span data-test-id="items-selected-header">
              {checkedItems.length === 0 ? `Select all` : `${checkedItems.length} ${assetText} selected`}
            </span>
          </Grid.Item>
          <Grid.Item className="hc-pl-none hc-pr-none">
            <Dropdown size="dense" location="bottom-right">
              <Button
                data-testid="asset-feed-actions-menu-btn"
                disabled={checkedItems.length <= 0}
                style={{ color: '#333', fontWeight: 'normal' }}
              >
                Actions
                <FontAwesomeIcon icon="chevron-down" className="fa-sm" style={{ marginLeft: 5 }} />
              </Button>
              <Dropdown.Menu className="asset-actions-dropdown">
                <Dropdown.MenuItem
                  data-testid={`download-assets-menu-item`}
                  onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                    downloadAssetsAsync(e, checkedItems)
                  }}
                >
                  Download assets
                </Dropdown.MenuItem>
                {isUserACreator && (
                  <Dropdown.MenuItem
                    disabled={checkedItems.length <= 0}
                    data-testid={`add-to-collection-menu-item`}
                    onClick={() => {
                      addToCollection(checkedItems.map((id: string) => find(assets, { id })!))
                    }}
                  >
                    Add {assetText} to collection
                  </Dropdown.MenuItem>
                )}
                {zeroStateToShow === ZeroStateToShowEnum.COLLECTIONDETAILPAGE && isUserACreator && (
                  <Dropdown.MenuItem
                    data-testid={`delete-assets-from-collection-menu-item`}
                    onClick={() => {
                      dispatch(
                        openDialog({
                          headerText: `Remove ${assetText} from collection?`,
                          message: `The selected ${assetText} will be removed from this collection. The ${assetText} will still remain in Gallery.`,
                          approveButtonText: `Remove ${assetText}`,
                          refuseButtonText: 'Cancel',
                          isVisible: true,
                          onAccept: () => deleteFromCollection(checkedItems, id!),
                        })
                      )
                    }}
                  >
                    Remove {assetText} from collection
                  </Dropdown.MenuItem>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </GridContainer>
  )
}

export default AssetFeedActions
