// used to choose the filter values by key, with which to search both tag keys and values during search
import { ICustomFilterValue } from './types'
export const findFunkyFiltersByKey = (
  customFilterKeys: string[],
  customSearchFilters: { [key: string]: ICustomFilterValue[] }
): { [key: string]: ICustomFilterValue[] } => {
  let customFilterValues: { [key: string]: ICustomFilterValue[] } = {}
  if (customSearchFilters) {
    Object.keys(customSearchFilters).forEach((key: string) => {
      if (customFilterKeys.find((filterKey: string) => filterKey === key)) {
        customFilterValues[key] = customSearchFilters[key]
      }
    })
  }
  return customFilterValues
}

/*
// used to choose the filter values by key, with which to search both tag keys and values during search
export const findFunkyFiltersByKey = (
  customFilterKeys: string[],
  customSearchFilters: { [key: string]: string[] }
): { [key: string]: string[] } => {
  let customFilterValues: { [key: string]: string[] } = {}
  if (customSearchFilters) {
    Object.keys(customSearchFilters).forEach((key: string) => {
      if (customFilterKeys.find((filterKey: string) => filterKey === key)) {
        customFilterValues[key] = customSearchFilters[key]
      }
    })
  }
  return customFilterValues
}
*/
