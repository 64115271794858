import { FileView, HttpError } from 'gallerydigitalassets-v1-client'
import { assetFileApi, galleryAxiosClient } from '../client'
import axios from 'axios'
import { processError } from '../utils'

// TODO: MOVE THIS INTO CLIENT STORE AND MAKE ALTERABLE FROM ANY STORE FOR CANCELABLE CALLS
// This allows search to be cancelable if another search call is made
let call: any

export const updateFileAPI = async (
  xTgtLanId: string,
  assetId: string,
  fileId: string,
  fileName?: string,
  heroImage?: boolean
) => {
  try {
    const { body } = await assetFileApi.updateAssetFile(assetId, fileId, xTgtLanId, fileName, heroImage)
    if (body.data) {
      return body.data as FileView
    }
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error)
    }
  }
}

export const downloadMultipleFilesAPI = async (xTgtLanId: string, assetId: string, fileIds: string) => {
  if (call) {
    call.cancel('Cancelling earlier download request')
  }
  call = axios.CancelToken.source()
  const response = await galleryAxiosClient.get(`/assets/${assetId}/files/downloads?file_ids=${fileIds}`, {
    cancelToken: call.token,
  })
  const preSignedUrl = response.data.data.pre_signed_url
  const link = document.createElement('a')
  link.href = preSignedUrl
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFileAPI = async (xTgtLanId: string, fileSignedUrl: string) => {
  const link = document.createElement('a')
  link.href = fileSignedUrl
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const deleteFileAPI = async (xTgtLanId: string, fileIds: string[], assetId: string) => {
  try {
    await assetFileApi.deleteAssetFile(assetId, fileIds, xTgtLanId)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error)
    }
  }
}

export const setAssetThumbnailApi = async (xTgtLanId: string, fileId: string, assetId: string) => {
  try {
    await assetFileApi.updateAssetFile(assetId, fileId, xTgtLanId, undefined, true, undefined)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error)
    }
  }
}
