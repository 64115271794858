import { createSlice } from '@reduxjs/toolkit'
import { find } from 'lodash'

export interface IToast {
  caption?: string
  color: 'alert' | 'error' | 'success' | undefined
  headingText: string
  message: string
  autoHideDuration?: number
}

const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    value: [],
  },
  reducers: {
    addToast: (state, action) => {
      return find(state.value, action.payload) ? { ...state } : { value: state.value.concat(action.payload) }
    },
    removeToast: (state, action) => {
      return {
        value: state.value.filter((toast: IToast) => {
          return (
            toast.color !== action.payload.color &&
            toast.headingText !== action.payload.headingText &&
            toast.message !== action.payload.message &&
            toast.caption !== action.payload.caption
          )
        }),
      }
    },
  },
})

export const { addToast, removeToast } = toastSlice.actions
export default toastSlice.reducer
