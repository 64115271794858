import { createSelector } from 'reselect'
import { RootState } from 'store'

export const filesSelector = createSelector(
  (state: RootState) => state.files,
  state => state
)
export const checkedFilesSelector = createSelector(filesSelector, state => state.fileSelect.checkedFiles)
export const selectAllSelector = createSelector(filesSelector, state => state.fileSelect.selectAll)
export const fileDrawersSelector = createSelector(filesSelector, state => state.fileSelect.fileDrawers)
