import SubCategoryResult, { ISubcategory } from 'components/feeds/CategoryFeed/SubCategoryResult'
import React, { useEffect, useState } from 'react'
import BasePage from '../BasePage/BasePage'
import Carousel from 'components/Carousel/Carousel'
import { TaxonomyNodeView } from 'gallerydigitalassets-v1-client'
import { processError, useTaxonomy } from 'store'

const carouselItems = [{ assetCount: 0, id: '00000000-0000-0000-0000-000000000002', name: 'Model' }]

const exampleSubCategory: ISubcategory = {
  taxonomyId: '00000001',
  name: 'exampleName',
  imageFiles: [],
}

const UserPage = () => {
  const [taxonomy, setTaxonomy] = useState<TaxonomyNodeView>()
  const { getCategory, isLoading } = useTaxonomy()

  const category = async () => {
    try {
      const result = (await getCategory('Material')) as TaxonomyNodeView
      setTaxonomy(result)
    } catch (error) {
      if (error instanceof Error) {
        processError(error, error.message, true)
      } else {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (!taxonomy && !isLoading) {
      category()
    }
  }, [taxonomy, isLoading])

  return (
    <BasePage>
      <div>
        <h1>UserPage</h1>
        <SubCategoryResult subCategory={exampleSubCategory} />
        <Carousel subCategories={carouselItems} />
      </div>
    </BasePage>
  )
}

export default UserPage
