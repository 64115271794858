import React, { useState, useEffect } from 'react'
import { extend } from 'lodash'
import { FileView } from 'gallerydigitalassets-v1-client'
import { useFiles } from 'store'

type GalleryCardImageProps = {
  file: FileView
  thumbnailLayoutNumber: number
  id: number
  width?: string
  height?: string
}

const GalleryCardImage: React.FC<GalleryCardImageProps> = ({ file, thumbnailLayoutNumber, id, width, height }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>()
  const { getFileThumbnailUrl } = useFiles()
  useEffect(() => {
    setThumbnailUrl(getFileThumbnailUrl(file))
  }, [file])

  let style = {
    border: '#e6e6e6 solid 1px',
    backgroundImage: `url(${thumbnailUrl})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }

  if (width !== undefined) {
    let styllingWidth = { width: `${width}` }
    style = extend(style, styllingWidth)
  }

  if (height !== undefined) {
    let styleHeight = { height: `${height}` }
    style = extend(style, styleHeight)
  }

  return (
    <div
      key={id}
      data-testid={`image-no-${id}`}
      className={`image-${thumbnailLayoutNumber} image-no-${id}`}
      style={style}
    />
  )
}

export default GalleryCardImage
