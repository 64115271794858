import * as React from 'react'
import EnterpriseIcon, { PlusIcon } from '@enterprise-ui/icons'
import ReactGA from 'react-ga'
import { MODAL_TYPES } from 'config/constants'
import { Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import './AddAssetToCollectionButton.scss'

import { useDispatch } from 'react-redux'
import { setModalData, setModalType } from 'store'

type AddAssetToCollectionBProps = {
  assets: any
  isButton: Boolean
}

const AddAssetToCollectionButton: React.FC<AddAssetToCollectionBProps> = ({ assets, isButton }) => {
  const dispatch = useDispatch()

  const addToCollection = () => {
    dispatch(setModalType(MODAL_TYPES.ADD_COLLECTION))
    dispatch(setModalData(assets))
  }
  return (
    <>
      {!!isButton && (
        <Tooltip content="Add to Collection" location="top">
          <Button
            type="primary"
            className="add-asset-to-collection-button"
            disabled={assets.length === 0}
            data-testid="add-assets-to-collection-button"
            onClick={() => addToCollection()}
          >
            <EnterpriseIcon icon={PlusIcon} />
          </Button>
        </Tooltip>
      )}
      {!isButton && (
        <Tooltip content="Add to Collection" location="top-right">
          <span
            data-test-id="add-to-collection-button"
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
              ReactGA.event({
                category: 'Asset',
                action: 'Add asset to collection',
              })
              e.preventDefault()
              addToCollection()
            }}
          >
            <EnterpriseIcon className="add-asset-to-collection-icon" icon={PlusIcon} />
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default AddAssetToCollectionButton
