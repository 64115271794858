import React, { useEffect, useState } from 'react'
import { Heading, Grid, Spinner, Button, Caption, Dropdown } from '@enterprise-ui/canvas-ui-react'
import { some, find } from 'lodash'
import BasePage from 'components/pages/BasePage/BasePage'
import dayjs from 'dayjs'
import AssetFeed from 'components/feeds/AssetFeed/AssetFeed'
import { cleanTitle } from 'utils/string'
import './CollectionDetailPage.scss'
import {
  BreadCrumbPriority,
  IBreadCrumb,
  ICollectionUser,
  processError,
  useCollections,
  setModalData,
  setModalType,
  useBreadCrumbs,
  removeCrumbById,
  PageStatus,
} from 'store'
import { MODAL_TYPES } from 'config/constants'
import { useDispatch } from 'react-redux'
import { useEnv } from '@praxis/component-runtime-env'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ZeroStateToShowEnum, useAuth } from 'store'
import { useLocation, useParams } from 'react-router-dom'
import ErrorState from 'components/shared/ErrorState'
import { Tooltip } from '@enterprise-ui/canvas-ui-react'
import { PermissionView } from 'gallerydigitalassets-v1-client'
import AccessEnum = PermissionView.AccessEnum

const CollectionDetailPage = () => {
  const { collectionPermissions } = useEnv()
  const {
    collectionDetailPageStatus: pageStatus,
    getCollectionDetails,
    getCollectionAssets,
    getPaginatedCollectionAssets,
    collectionPageSize,
    collectionDetails: collection,
    collectionAssets,
    resetCollection,
    downloadGallery,
    triggerDeleteCollectionModal,
    triggerDeleteCollection,
    launchEditCollectionmanagerModal,
    setCollectionDetailPageStatus,
  } = useCollections()
  const [canEditCollectionManagers, setCanEditCollectionManagers] = useState<Boolean>(false)
  const [canEditCollection, setCanEditCollection] = useState<Boolean>(false)
  const [canDeleteCollection, setCanDeleteCollection] = useState<Boolean>(false)
  const [canEditCollectionOrgs, setCanEditCollectionOrgs] = useState<Boolean>(false)
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false)
  const { id } = useParams()
  const [creator, setCreator] = useState<ICollectionUser>()
  const [name, setName] = useState<string>()
  const [isPrivate, setPrivacy] = useState(false)
  const location = useLocation()
  const pageId = 'Collection Details Page'
  const { canUserCreateContent, collectionAccess, isAdmin } = useAuth()

  const dispatch = useDispatch()

  const { addCrumbs, crumbsFromSlice } = useBreadCrumbs()

  useEffect(() => {
    if (id) {
      resetCollection()
      getCollectionDetails(id)
      getCollectionAssets(id, 0, collectionPageSize)
    }
    return () => {
      resetCollection()
      setCollectionDetailPageStatus(PageStatus.idle)
    }
  }, [id])

  useEffect(() => {
    return () => {
      removeCrumbById(pageId)
    }
  }, [])

  useEffect(() => {
    const collectionPrivacy = !some(collection.collectionOrganizations)
    setPrivacy(collectionPrivacy)
    if (collection?.id === id) {
      const collectionCreator: ICollectionUser = find(collection?.galleryUsers, {
        access: 'GALLERY_CREATOR',
      })!! as ICollectionUser
      const collectionName = collection?.name || ''
      setCreator(collectionCreator)
      setName(collectionName)
    }
  }, [collection, collection.name, pageStatus])

  useEffect(() => {
    const isUserCreator = canUserCreateContent()
    const isUserAdmin = isAdmin()
    if (collection?.id === id) {
      const accessList: AccessEnum[] = collectionAccess(collection!!.galleryUsers)
      if (isUserAdmin) {
        setCanEditCollection(isUserAdmin)
        setCanDeleteCollection(isUserAdmin)
        setCanEditCollectionOrgs(isUserAdmin)
        setCanEditCollectionManagers(isUserAdmin)
      } else {
        accessList.forEach((access: AccessEnum) => {
          if (collectionPermissions.editCollection.includes(access)) {
            setCanEditCollection(isUserCreator)
          }
          if (collectionPermissions.deleteCollection.includes(access)) {
            setCanDeleteCollection(isUserCreator)
          }
          if (collectionPermissions.editCollectionOrgs.includes(access)) {
            setCanEditCollectionOrgs(isUserCreator)
          }
          if (collectionPermissions.editCollectionManagers.includes(access)) {
            setCanEditCollectionManagers(isUserCreator)
          }
        })
      }
    }
  }, [collection, pageStatus])

  useEffect(() => {
    if (name !== '') {
      const crumbsToAdd = []
      const pageCrumb = {
        id: pageId,
        name: cleanTitle(name || ''),
        href: location.pathname,
        priority: BreadCrumbPriority.Low,
      }

      let crumbName: string
      let pathCrumb: any
      // Logic for detecting if a user came from favorites tab. TODO: Revisit this logic
      const lastCrumb = crumbsFromSlice[crumbsFromSlice.length - 1]
      if (lastCrumb.name === 'Favorite Collections') {
        crumbName = lastCrumb.name
        pathCrumb = {
          name: crumbName,
          href: lastCrumb.href,
          priority: BreadCrumbPriority.Medium,
        }
        crumbsToAdd.push(pathCrumb)
      } else if (crumbsFromSlice.length === 0) {
        crumbName = `${isPrivate ? 'My' : 'Public'} Collections`
        pathCrumb = {
          name: crumbName,
          href: `/collections/${isPrivate ? 'my' : 'public'}`,
          priority: BreadCrumbPriority.Medium,
        }
        crumbsToAdd.push(pathCrumb)
      }

      if (pageCrumb.name) {
        crumbsToAdd.push(pageCrumb)
        addCrumbs(crumbsToAdd)
      }
    }
  }, [name])

  let crumb: IBreadCrumb | {} = {}

  const LoadingState = () => {
    return (
      <BasePage crumbs={crumb}>
        <Grid.Container justify="center" className="hc-pt-xl">
          <Spinner data-test-id="load-indicator" />
        </Grid.Container>
      </BasePage>
    )
  }

  switch (pageStatus) {
    case PageStatus.idle:
    case PageStatus.loading:
      return <LoadingState />

    case PageStatus.error:
      return <ErrorState name={'Collection'} />
  }

  if (!collection || !collection.name) {
    return <ErrorState name={'Collection'} />
  }

  const launchEditCollectionModal = () => {
    dispatch(setModalType(MODAL_TYPES.EDIT_COLLECTION))
    const collectionModalValues = {
      id: collection.id,
      name: collection.name,
      description: collection.description,
      isPrivate,
      assets: collectionAssets.assets,
      canEditCollectionOrgs: canEditCollectionOrgs,
      organizations: collection.collectionOrganizations,
      tags: collection.collectionTags,
    }
    dispatch(setModalData(collectionModalValues))
  }

  return (
    <BasePage>
      <Grid.Container direction="column">
        <Grid.Item xs={12} className="hc-pb-none hc-mt-2x">
          <Grid.Container justify="space-between" className="collection-page-centered-content">
            <Grid.Item className="hc-pl-none" md={8} xl={6}>
              <Caption
                data-test-id="collection-caption"
                below={
                  <Grid.Container direction="column">
                    {collectionAssets.totalAssets > 0 && (
                      <Grid.Item
                        className="caption hc-pb-none hc-fs-sm hc-clr-grey03"
                        data-test-id="collection-total-asset-ct"
                      >
                        Total asset count: {`${collectionAssets.totalAssets}`}
                      </Grid.Item>
                    )}
                    {pageStatus === PageStatus.success && (
                      <Grid.Item
                        style={{ marginBottom: '6px', paddingTop: '4px' }}
                        className="caption hc-pb-none hc-fs-sm hc-clr-grey03"
                        data-test-id="collection-create-info"
                      >
                        Created by {`${creator?.user.firstName} ${creator?.user.lastName}`} on{' '}
                        {dayjs(collection?.createdAt).format('MM/DD/YY')}
                      </Grid.Item>
                    )}
                    {collection?.description && (
                      <Grid.Item
                        className="hc-pt-none hc-pb-none hc-clr-black hc-fs-sm"
                        data-test-id="collection-description"
                      >
                        {collection?.description}
                      </Grid.Item>
                    )}
                  </Grid.Container>
                }
              >
                <Heading data-test-id="collection-name" className="hc-mb-dense" size={2}>
                  {cleanTitle(name || '')}
                </Heading>
              </Caption>
            </Grid.Item>
            <Grid.Item className="hc-pr-none">
              <Grid.Container direction="row" className="hc-pa-none">
                {some(collectionAssets.assets) && (
                  <Grid.Item className="hc-pr-none">
                    <Button
                      key={`detail_btn_grp_${collection.id}`}
                      onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                        setDownloadDisabled(true)
                        downloadGallery(id!)
                        setTimeout(() => setDownloadDisabled(false), 20000)
                      }}
                      data-test-id="download-collection-button"
                      type="primary"
                      disabled={!some(collectionAssets.assets) || downloadDisabled}
                    >
                      Download all assets
                    </Button>
                  </Grid.Item>
                )}
                {pageStatus === PageStatus.success &&
                  (canDeleteCollection || canEditCollection || canEditCollectionManagers || canEditCollectionOrgs) && (
                    <Grid.Item className="hc-pr-none hc-pt-sm hc-pl-min">
                      <Dropdown location="bottom-right" size="normal" key={`collection-more-menu-${collection?.id}`}>
                        <Tooltip content="Edit Collection Options" location="top">
                          <Button
                            type="secondary"
                            className="collection-more-button"
                            data-testid="collection-more-button"
                          >
                            <FontAwesomeIcon icon="ellipsis-v" />
                          </Button>
                        </Tooltip>
                        <Dropdown.Menu
                          data-testid={`collection_detail_more_${collection?.id}`}
                          style={{ zIndex: 100, width: 192 }}
                        >
                          {canEditCollection && (
                            <Dropdown.MenuItem
                              className={'hc-fs-xs'}
                              data-testid="drop-down-item-edit-collection"
                              onClick={launchEditCollectionModal}
                            >
                              Edit collection
                            </Dropdown.MenuItem>
                          )}
                          {canEditCollectionManagers && (
                            <Dropdown.MenuItem
                              data-testid="drop-down-item-edit-collection-managers"
                              className={'hc-fs-xs'}
                              onClick={(e: Event) => {
                                e.preventDefault()
                                launchEditCollectionmanagerModal(collection.id, [], '')
                              }}
                            >
                              Edit collection managers
                            </Dropdown.MenuItem>
                          )}
                          {canDeleteCollection && (
                            <Dropdown.MenuItem
                              data-testid="drop-down-item-delete-collection"
                              className="hc-clr-error hc-fs-xs"
                              onClick={(e: Event) => {
                                e.preventDefault()
                                const triggerDelete = async () => {
                                  try {
                                    triggerDeleteCollection(collection.id, cleanTitle(name || ''))
                                  } catch (error) {
                                    if (error instanceof Error) {
                                      processError(error, error.message, true)
                                    } else {
                                      console.log(error)
                                    }
                                  }
                                }
                                triggerDeleteCollectionModal(collection.id, cleanTitle(name || ''), triggerDelete)
                              }}
                            >
                              Delete collection
                            </Dropdown.MenuItem>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid.Item>
                  )}
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pt-none">
          <AssetFeed
            pageName={cleanTitle(name || '')}
            isLoading={collectionAssets.isLoading}
            assets={collectionAssets.assets}
            totalCount={collectionAssets.totalAssets}
            zeroStateToShow={ZeroStateToShowEnum.COLLECTIONDETAILPAGE}
            infiniteScrollMethod={getPaginatedCollectionAssets}
          />
        </Grid.Item>
      </Grid.Container>
    </BasePage>
  )
}

export default CollectionDetailPage
