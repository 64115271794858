import * as React from 'react'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

type InfiniteScrollerProps = {
  nextPageCallback?: () => void
  // This is the distance from the bottom of the parent container at which infinite scroll should be triggered.
  infiniteScrollerOffset?: number
}

const InfiniteScroller: React.FC<InfiniteScrollerProps> = ({ nextPageCallback, infiniteScrollerOffset }) => {
  const scrollerOffset = infiniteScrollerOffset ? `${infiniteScrollerOffset}px` : '300px'
  const [ref, inView] = useInView({ threshold: 0 })
  // Makes sure this doesn't fire more than once (if a user scrolls up & back)
  const [hasFired, setHasFired] = useState<boolean>(false)

  useEffect(() => {
    if (inView && !hasFired) {
      setHasFired(true)
      if (nextPageCallback) {
        nextPageCallback()
        setHasFired(false)
      }
    }
  }, [inView])

  return (
    <div
      style={{
        height: scrollerOffset,
        position: 'absolute',
        bottom: '0px',
        zIndex: -1,
      }}
      ref={ref}
    ></div>
  )
}

export default InfiniteScroller
