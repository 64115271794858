import React from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
// import 'canvas-ui-css-marquette'
import Header from 'components/Header/Header'
import ModalSetup from 'containers/ModalSetup'

const BaseLayout: React.FC<{ children: any }> = ({ children }) => {
  return (
    <>
      <ModalSetup />
      <Layout darkMode={false} fullWidth theme="default">
        <Header />
        <Layout.Body includeRail={true}>{children}</Layout.Body>
      </Layout>
    </>
  )
}

export default BaseLayout
