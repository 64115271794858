import { Card, Grid, ExpandableSection, Image } from '@enterprise-ui/canvas-ui-react'
import { DEFAULT_IMAGE_URL } from 'config'
import { kebabCase } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IAsset } from 'store'
interface LinkedAssetProps {
  assets: IAsset[]
  taxonomy: string
}
const LinkedAssetDrawer: React.FC<LinkedAssetProps> = ({ assets, taxonomy }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const navigate = useNavigate()
  const paddingStyle = {
    padding: '0px',
  }
  return (
    <Card elevation={0} className="drawer" data-testid={`linked-asset-drawer-${kebabCase(taxonomy)}`}>
      <div style={paddingStyle} className="hc-pa-normal linked-asset-drawer-expandable-container">
        <ExpandableSection
          padding="dense"
          className="linked-asset-drawer"
          data-testid={`linked-asset-drawer-expandable-${kebabCase(taxonomy)}`}
          expanded={drawerOpen}
          onExpand={() => {
            setDrawerOpen(!drawerOpen)
          }}
        >
          <Grid.Container>
            <Grid.Item>
              <p className="drawer-label" data-testid={`linked-asset-taxonomy-${kebabCase(taxonomy)}`}>
                {taxonomy}{' '}
                <span>
                  {assets.length} {assets.length === 1 ? 'asset' : 'assets'}
                </span>
              </p>
            </Grid.Item>
          </Grid.Container>

          <ExpandableSection.Content>
            {assets.map(asset => {
              let heroImage = asset.files.find(file => {
                return file.heroImage
              })

              return (
                <Card
                  className="linked-asset-card"
                  data-testid={`linked-asset-card-${kebabCase(asset.taxonomyId)}`}
                  onClick={() => {
                    navigate(`/asset/${asset.id}`, { state: { linkedAsset: true } })
                  }}
                >
                  <div className="hc-pa-normal">
                    <Grid.Container align="center" className="linked-asset-display">
                      <Grid.Item>
                        <Image
                          width="50px"
                          height="50px"
                          src={heroImage?.fileSources[0].accessUrl || DEFAULT_IMAGE_URL}
                          alt={`Hero image for ${asset.name} asset.`}
                        />
                      </Grid.Item>
                      <Grid.Item className="asset-name-grid">
                        <p className="asset-name hc-clr-grey01">{asset.name}</p>
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                </Card>
              )
            })}
          </ExpandableSection.Content>
        </ExpandableSection>
      </div>
    </Card>
  )
}

export default LinkedAssetDrawer
