import { TaxonomyAssetCountsResponse, TaxonomyNodeView } from 'gallerydigitalassets-v1-client'
import { compact, orderBy } from 'lodash'
import { useState, useEffect } from 'react'
import { cleanTitle } from 'utils/string'
import { useDispatch } from 'react-redux'
import {
  addCrumb,
  BreadCrumbPriority,
  clearLPCrumb,
  getSubcategoryPageAssets,
  processError,
  setSubcategoryPageAssets,
  setSubcategoryPageAssetsPaginationIsLoading,
  useTaxonomy,
  IAsset,
} from 'store'

const useSubcategory = (name: string) => {
  const {
    isLoading: taxonomyIsLoading,
    getCategory,
    getCarouselItems,
    resetCarouselItems,
    subcategoryPageAssets,
    assetsAreLoading,
    carouselItems,
  } = useTaxonomy()

  const [assets, setAssets] = useState<IAsset[]>()
  const [groups, setGroups] = useState<TaxonomyAssetCountsResponse[] | undefined>(undefined)
  const [totalCount, setTotalCount] = useState<number>()
  const [id, setId] = useState<string>()
  const [nextPage, setNextPage] = useState<number>()
  const dispatch = useDispatch()

  const getTaxonomiesForBC = async () => {
    // Gets Taxonomy with parents for breadcrumbs.
    const taxonomies = (await getCategory(name, true)) as TaxonomyNodeView[]
    // sets Id for carousel and assets calls
    setId(taxonomies[0].id)
    const breadcrumbs: any = taxonomies
      .map((tax, id) => {
        return {
          id: tax.id,
          name: cleanTitle(tax.name),
          href: `/${id === taxonomies.length - 1 ? 'category' : 'subcategory'}/${tax.name.toLowerCase()}`,
          priority: id === taxonomies.length - 1 ? BreadCrumbPriority.High : BreadCrumbPriority.Low,
        }
      })
      .reverse()
    dispatch(clearLPCrumb({ priority: BreadCrumbPriority.High }))
    dispatch(addCrumb(breadcrumbs))
  }

  const infiniteScrollMethod = () => {
    if (id) {
      dispatch(setSubcategoryPageAssetsPaginationIsLoading(true))
      dispatch(getSubcategoryPageAssets({ taxonomyId: id, page: nextPage }))
    }
  }

  const getTaxonomyData = async (id: string) => {
    try {
      dispatch(getSubcategoryPageAssets({ taxonomyId: id, page: 0 }))
      getCarouselItems(id)
    } catch (error) {
      if (error instanceof Error) {
        processError(error, error.message, true)
      } else {
        console.log(error)
      }
    }
  }

  // assigns ID to taxonomy based on name from url params
  useEffect(() => {
    setGroups(undefined)
    setAssets(undefined)
    if (!taxonomyIsLoading) {
      getTaxonomiesForBC()
    }
  }, [name, taxonomyIsLoading])

  // refetches data for asset feed and carousel with new subcategory
  useEffect(() => {
    dispatch(setSubcategoryPageAssets({ assets: [], totalCount: 0 }))
    if (id) {
      getTaxonomyData(id)
    }
  }, [id])

  // sets local nextPage on paginated getTaxonomyAssets call for future infinite scroll method
  useEffect(() => {
    if (subcategoryPageAssets.page !== undefined) {
      setNextPage(subcategoryPageAssets.page + 1)
    }
  }, [subcategoryPageAssets.page])

  useEffect(() => {
    if (subcategoryPageAssets.total !== totalCount) {
      setTotalCount(subcategoryPageAssets.total)
    }
    if (subcategoryPageAssets.assets.length) {
      const hydratedAssets: IAsset[] = compact(subcategoryPageAssets.assets)
      setAssets(hydratedAssets)
    } else {
      setAssets([])
      setTotalCount(0)
    }
  }, [subcategoryPageAssets])

  useEffect(() => {
    if (carouselItems && carouselItems.length >= 1) {
      const orderedGroups = orderBy(carouselItems.slice(1), ['name'])
      setGroups(orderedGroups)
    }
  }, [carouselItems])

  // cleanup hook data
  useEffect(() => {
    return () => {
      dispatch(setSubcategoryPageAssets({ assets: [], totalCount: 0 }))
      resetCarouselItems()
    }
  }, [])

  return { totalCount, groups, assets, assetsAreLoading, infiniteScrollMethod, id }
}

export default useSubcategory
