import { useState } from 'react'

import { Grid, Input, Anchor } from '@enterprise-ui/canvas-ui-react'
import { IFileDrawer, FileViewUI, useFiles } from 'store'
import { some } from 'lodash'
import styles from './FileTable.module.scss'
import { find } from 'lodash'
import FileDrawer from './FileDrawer'

export enum globalDrawerState {
  ALL_CLOSED = 'CLOSED',
  INTERMEDIATE = 'INTERMEDIATE',
  ALL_OPEN = 'OPEN',
}

const FileTable = () => {
  const { selectAll, selectAllFiles, checkedFiles, fileDrawers } = useFiles()
  const [allDrawersState, setAllDrawersState] = useState<globalDrawerState>(globalDrawerState.ALL_CLOSED)

  //// TODO: FIGURE OUT WHAT THIS LOGIC IS REALLY NEEDED FOR
  const sortedFiles = checkedFiles
    .slice()
    .sort((fileA: FileViewUI, fileB: FileViewUI) => (fileA.updatedAt < fileB.updatedAt ? 1 : -1))
  const thumbnailFile: any =
    find(sortedFiles, { classification: 'USER_THUMBNAIL' }) ||
    find(sortedFiles, { classification: 'TCIN_THUMBNAIL' }) ||
    find(sortedFiles, { classification: 'THUMBNAIL' })

  const FileDrawers = () => {
    return (
      <>
        <div className="hc-pa-normal hc-mb-md hc-pv-none">
          <Grid.Container direction="column" spacing="dense">
            <Grid.Item xs={12} spacing="none" className={styles['asset-icons-container']}>
              <Grid.Container spacing="dense">
                <Grid.Item sm={6}>
                  <Input.Checkbox
                    id="chk_select_all"
                    data-testid="chk_select_all"
                    className={`hc-pa-none hc-ma-none ${styles['asset-table-text']}`}
                    indeterminate={selectAll.indeterminate}
                    checked={selectAll.status > 0}
                    label={selectAll.filesSelected > 0 ? `${selectAll.filesSelected} Files Selected` : 'Select All'}
                    onChange={() => {
                      selectAllFiles(selectAll.status === 0 ? true : false)
                    }}
                  />
                </Grid.Item>
                <Grid.Item className={styles['asset-table-icons']}>
                  <div>
                    <Anchor
                      data-testid="btn_open_all_drawers"
                      size="dense"
                      onClick={() => {
                        allDrawersState === globalDrawerState.ALL_OPEN
                          ? setAllDrawersState(globalDrawerState.ALL_CLOSED)
                          : setAllDrawersState(globalDrawerState.ALL_OPEN)
                      }}
                    >
                      {allDrawersState === globalDrawerState.ALL_OPEN ? 'Collapse All' : 'Expand All'}
                    </Anchor>
                  </div>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
        {fileDrawers.map((splitFileBucket: IFileDrawer) => (
          <FileDrawer
            key={`${splitFileBucket.type}`}
            title={splitFileBucket.type}
            files={splitFileBucket.files}
            allDrawersState={allDrawersState}
            setAllDrawersState={setAllDrawersState}
            thumbnailFile={thumbnailFile}
          />
        ))}
      </>
    )
  }

  const emptyFileDrawers = () => {
    return (
      <div className={`hc-pa-normal ${styles['asset-table-container']}`}>
        <span>No Files</span>
      </div>
    )
  }
  return !some(checkedFiles) ? emptyFileDrawers() : FileDrawers()
}

export default FileTable
