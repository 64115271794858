export const descriptionMatcher: { expression: RegExp; message: string } = {
  expression: /^[\w\-\s()&/"'.,]*$/,
  message: 'Description can only contain alphanumerics, whitespaces, or the following: .,&/\'"()-_.',
}

export const assetTagMatcher: { expression: RegExp; message: string } = {
  expression: /^[\w\-\s()&/"'.,;*]*$/,
  message: 'Value characters are limited to alphanumerics and characters from this list: ;,()*"\'-_&/.',
}

export const searchInputSanitizer: RegExp = /[^\w\-\s,;&"]/g
export const collectionSearchInputSanitizer: RegExp = /[^\w\-\s]/g

export const match = (name: string, regex: RegExp) => {
  return name.match(regex)
}

export const doubleQuoteCorrection = (text: string) => {
  const quoteChecker = /["]/g
  if (!quoteChecker.test(text)) return text
  const commaCheck: boolean = text.includes(',')
  const singledTerms: string[] = commaCheck ? text.split(',') : text.split(' ') // Conditionally split by commas or spaces.

  const remappedString: string[] = singledTerms.map(term => {
    if (quoteChecker.test(term)) {
      return `"${term.replace(quoteChecker, '').trim()}"`
    }
    return term.trim()
  })

  return remappedString.join(commaCheck ? ', ' : ' ')
}

export const sanitize = (name: string, regex: RegExp) => {
  name = name.replace(regex, '').replace(/[\u200B-\u200D\uFEFF]/g, '') // Removes zero-width characters added by copying and pasting.
  return name.trim()
}
