import React from 'react'
import { useEffect } from 'react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { Drawer, Timeline, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useAsset } from 'store'
import './AssetActivity.scss'

export interface IAssetActivityValues {
  assetId: string
}

type IAssetActivityModalProps = {
  closeModal: () => void
  data?: IAssetActivityValues
}

const AssetActivityModal: React.FC<IAssetActivityModalProps> = ({ closeModal, data }) => {
  const { getAssetActivity, assetActivity, assetActivityIsLoading } = useAsset()

  useEffect(() => {
    getAssetActivity(data?.assetId!!)
  }, [])

  return (
    <Drawer
      isVisible
      headingText={'Activity Log'}
      onRequestClose={() => closeModal()}
      lg={4}
      className="asset-activity-drawer"
    >
      <Timeline>
        {assetActivityIsLoading ? (
          <Spinner data-test-id="load-indicator" className="activity-action-spinner" />
        ) : assetActivity && assetActivity.length > 0 ? (
          assetActivity.map((activity, index) => (
            <Timeline.Item key={index}>
              <strong>
                <DateFormatter date={activity.actionAt} format="LLL" />
              </strong>
              <div className="hc-mr-lg">{activity.activityMessage}</div>
            </Timeline.Item>
          ))
        ) : (
          <div>No activity found</div>
        )}
      </Timeline>
    </Drawer>
  )
}

export default AssetActivityModal
