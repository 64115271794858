import { IAsset } from 'store'

export interface ISearchState {
  searchPageAssets: ISearchPageAssets
  currentSearchValue: string
  currentSearchTab: string
  exactSearch: boolean
  searchFilters: ISearchFilters
  searchCriteria: ISearchCriteria
  searchAPIError: boolean
}
export interface ISearchCriteria {
  q?: string
  p: number
  f?: { [key: string]: string[] }
  t?: string
  size?: number
  sort?: ISearchSort[]
}

export interface ISearchSort {
  field: String
  direction: String
  fieldValue?: String
}

export interface ICollectionSearchCriteria {
  query?: string | undefined
  permission: string
  sort?: ISearchSort[] | undefined
}

export interface ISearchPageAssets {
  page: number
  assets: IAsset[]
  total: number
  paginationIsLoading: boolean
  sort?: ISearchSort[]
}

export interface ISearchFilters {
  loading: boolean
  filterValues: IFilterItemUI[]
}

export interface IFilterItem {
  key: string
  value: IFilterValue[]
}
export interface IFilterValue {
  filterValue: ICustomFilterValue
}

export interface IFilterValueUI extends IFilterValue {
  selected: boolean
}

export interface IFilterItemUI {
  filterKeyUI: string
  filterValueUI: IFilterValueUI[]
}

export interface IFilterChip {
  // TODO: NAME TO MATCH OTHER FILTER INTERFACES
  [key: string]: string[]
}

export interface IESSearchFilter {
  filter_chip_key: string
  filter_chip_value: string[]
}

export interface ISearchRequest {
  query?: string | undefined
  filters?: IESSearchFilter[] | undefined
  taxonomies?: string[] | undefined
  sort: ISearchSort[] | undefined
  permission?: string | undefined
}

export enum IFilterAction {
  Add = 'ADD',
  Remove = 'REMOVE',
  RemoveAll = 'REMOVE_ALL',
}

export interface ICustomFilterValue {
  displayValue: string | null
  value: string
}
