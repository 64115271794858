import { useDispatch, useSelector } from 'react-redux'
import { userInfoSelector, userIdentifiersSelector, userAuthSelector, adGroupMissingSelector } from './selectors'
import { OrganizationBaseView, PermissionView } from 'gallerydigitalassets-v1-client'
import { useAuth as useTargetAuth } from '@praxis/component-auth'
import { STORAGE_KEYS, COOKIE_KEYS } from 'config'
import { ICollectionUser, startGallery, setAdGroupMissing } from 'store'
import { useEffect } from 'react'
import { processError } from '../utils'
import { useEnv } from '@praxis/component-runtime-env'

export const useAuth = () => {
  const { isAuthenticated, session, login, logout } = useTargetAuth()
  const userInfo = useSelector(userInfoSelector)
  const userIdentifiers = useSelector(userIdentifiersSelector)
  const userUnauthorized = useSelector(userAuthSelector)
  const { contextRoot } = useEnv()
  const adGroupMissing = useSelector(adGroupMissingSelector)
  // Handle any 401s from the api

  useEffect(() => {
    if (userUnauthorized) scrubAuthAndLogin()
  }, [userUnauthorized])

  const dispatch = useDispatch()
  const scrubAuthAndLogin = () => {
    localStorage.setItem(COOKIE_KEYS.INITIAL_LOCATION, window.location.href)
    // Remove any old access tokens
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
    localStorage.removeItem(STORAGE_KEYS.DISPLAY_COMPANY_NAME)
    localStorage.removeItem(STORAGE_KEYS.DISPLAY_USER_NAME)
    localStorage.removeItem(STORAGE_KEYS.USER_TYPE)
    localStorage.removeItem(STORAGE_KEYS.TGT_LAN_ID)
    login()
  }
  const logOut = () => {
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
    localStorage.removeItem(STORAGE_KEYS.DISPLAY_COMPANY_NAME)
    localStorage.removeItem(STORAGE_KEYS.DISPLAY_USER_NAME)
    localStorage.removeItem(STORAGE_KEYS.USER_TYPE)
    localStorage.removeItem(STORAGE_KEYS.TGT_LAN_ID)
    logout()
  }
  const checkUserAuth = () => {
    // set the context path
    dispatch(setAdGroupMissing(false))
    localStorage.setItem(STORAGE_KEYS.CONTEXT_ROOT, contextRoot)
    // Check if user is authenticated.
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)

    // Soft-Checking for an expired session (Will not cause noteable error.)
    try {
      if (session?.hasExpired) {
        scrubAuthAndLogin()
      }
    } catch (error) {
      processError(error as unknown as Error)
    }

    if (!isAuthenticated() && !accessToken) {
      scrubAuthAndLogin()
    } else {
      if (session?.userInfo?.displayname && session?.userInfo?.company) {
        localStorage.setItem(STORAGE_KEYS.DISPLAY_USER_NAME, session.userInfo.displayname)
      }
      if (session?.userInfo?.company) {
        localStorage.setItem(STORAGE_KEYS.DISPLAY_COMPANY_NAME, session.userInfo.company)
      }
      if (session?.userInfo?.lanId) {
        localStorage.setItem(STORAGE_KEYS.TGT_LAN_ID, session.userInfo.lanId)
      }
      dispatch(startGallery())
    }
  }

  const isUserFavorite = (favoriteUsers: String[]) => {
    const userLanId = userInfo?.lanId ?? 'UNKNOWN_LAN_ID'
    return favoriteUsers.includes(userLanId)
  }
  const isAdGroupMissing = () => {
    return adGroupMissing
  }

  const canUserCreateContent = () => {
    const nonViewAccess = userInfo?.organizations?.filter(
      (userOrg: OrganizationBaseView) =>
        userOrg.access === OrganizationBaseView.AccessEnum.GalleryCreator ||
        userOrg.access === OrganizationBaseView.AccessEnum.GalleryManager ||
        userOrg.access === OrganizationBaseView.AccessEnum.AssetCreator ||
        userOrg.access === OrganizationBaseView.AccessEnum.AssetManager ||
        userOrg.access === OrganizationBaseView.AccessEnum.Admin ||
        userOrg.access === OrganizationBaseView.AccessEnum.OrganizationAdmin ||
        userOrg.access === OrganizationBaseView.AccessEnum.OrganizationAssetCreator ||
        userOrg.access === OrganizationBaseView.AccessEnum.OrganizationAssetManager ||
        userOrg.access === OrganizationBaseView.AccessEnum.OrganizationGalleryCreator ||
        userOrg.access === OrganizationBaseView.AccessEnum.OrganizationGalleryManager
    ).length
    return nonViewAccess ? nonViewAccess > 0 : false
  }

  const isAdmin = () => {
    const userAdminAccess = userInfo?.organizations?.filter(
      (userOrg: OrganizationBaseView) => userOrg.access === OrganizationBaseView.AccessEnum.Admin
    ).length
    return userAdminAccess ? userAdminAccess > 0 : false
  }

  const collectionAccess = (users: ICollectionUser[]) => {
    let access: PermissionView.AccessEnum[] = []
    let userId = userInfo?.id
    if (userId) {
      access = users
        .filter((collectionUser: ICollectionUser) => collectionUser.user.id === userId)
        .map((collectionUser: ICollectionUser) => collectionUser.access)
    }
    return access
  }

  return {
    canUserCreateContent,
    isAdmin,
    collectionAccess,
    logOut,
    userInfo,
    userIdentifiers,
    checkUserAuth,
    isUserFavorite,
    isAdGroupMissing,
  }
}
