import { createSlice, createReducer } from '@reduxjs/toolkit'
import { IFeatureFlag, Phase } from './types'

const initialState: { value: IFeatureFlag[] } = { value: [] }

const featureSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFF(state, action) {
      state.value = [...state.value, action.payload]
    },
    setManyFF(state, action) {
      state.value = state.value.concat(action.payload)
    },
    loadSaved(state, action) {
      state.value = action.payload
    },
    toggleFF(state, action) {
      state.value = state.value.map(feature => {
        return feature.name === action.payload.name ? featureReducer(feature, action) : feature
      })
    },
  },
})

export const { setFF, setManyFF, loadSaved, toggleFF } = featureSlice.actions

const featureReducer = createReducer<IFeatureFlag>({ name: '', phase: Phase.Dev }, builder => {
  builder.addCase(toggleFF, (feature, action) => {
    feature.environment = action.payload.environment
  })
})

export default featureSlice.reducer
