import { Heading, Grid, Chip, Form, Button, Input } from '@enterprise-ui/canvas-ui-react'
import { openDialog, useAuth } from 'store'
import React, { useEffect, useState } from 'react'
import styles from './TagView.module.scss'
import { useDispatch } from 'react-redux'
import { useTags } from 'store'

type TagViewProps = {
  assetId: string
}

const TagView: React.FC<TagViewProps> = ({ assetId }) => {
  const dispatch = useDispatch()

  const { customAssetTags, addCustomAssetTag, deleteCustomAssetTag, getCustomAssetTags, getErrorMessage } = useTags()
  const { canUserCreateContent } = useAuth()
  const [addTagValue, setAddTagValue] = useState<string>('')
  const [errorMsg, setErrorMsg] = useState<string>('')

  useEffect(() => {
    getCustomAssetTags(assetId)
  }, [])

  return (
    <>
      <Grid.Container direction="column" className="hc-ml-md">
        <Grid.Item xs={12} className="hc-pl-none">
          <Heading size={5}>Tags</Heading>
        </Grid.Item>
        {canUserCreateContent() && (
          <Grid.Item data-testid="add-tag-form" xs={12} className="hc-pv-none hc-pl-none">
            <Form
              className={styles['add-tag-form']}
              onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
                e.preventDefault()
                if (errorMsg.length <= 0) {
                  addCustomAssetTag(assetId, addTagValue)
                  setAddTagValue('')
                }
              }}
            >
              <Input.Text
                placeholder="Enter custom tag"
                expanded={true}
                type="text"
                data-testid="add-tag-input"
                className={styles['add-tag-input']}
                value={addTagValue}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) => {
                  setAddTagValue(e.target.value)
                  setErrorMsg(getErrorMessage(e.target.value as string))
                }}
              />
              <Button
                className={`${styles['add-tag-button']} hc-pa-normal hc-ml-normal`}
                data-testid="add-tag-button"
                dense={true}
                type="primary"
                disabled={!!errorMsg || !addTagValue}
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                  e.preventDefault()
                  addCustomAssetTag(assetId, addTagValue)
                  setAddTagValue('')
                }}
              >
                Add
              </Button>
              {errorMsg && <Input.Info error={true}>{errorMsg}</Input.Info>}
            </Form>
          </Grid.Item>
        )}
        {customAssetTags?.length > 0 ? (
          <Grid.Item xs={12}>
            <Grid.Container direction="row" noWrap={false}>
              <Grid.Item xs={12}>
                {customAssetTags.map((tag, index: number) => (
                  <Chip
                    key={index}
                    onRequestDelete={
                      canUserCreateContent()
                        ? () => {
                            dispatch(
                              openDialog({
                                headerText: `Remove ${tag.value} tag from asset?`,
                                message: 'The selected tag will be removed from this asset.',
                                approveButtonText: 'Remove tag',
                                refuseButtonText: 'Cancel',
                                isVisible: true,
                                onAccept: () => deleteCustomAssetTag(assetId, tag),
                              })
                            )
                          }
                        : undefined
                    }
                    className="hc-mb-dense"
                    data-testid="tags-content"
                    size="dense"
                    role="false"
                  >
                    <span className={styles['bold']} data-testid="tags-value">
                      {tag.value}
                    </span>
                  </Chip>
                ))}
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        ) : (
          <Grid.Item xs={12}>
            <p className="hc-clr-grey03 hc-fs-xs">There are no custom tags for this asset.</p>
          </Grid.Item>
        )}
      </Grid.Container>
    </>
  )
}

export default TagView
