import { all, takeLatest, call, put } from 'redux-saga/effects'
import { STORAGE_KEYS } from 'config/constants'
import {
  processError,
  setUserIdentifiers,
  applyServiceRedirect,
  detectServiceRedirect,
  IUserIdentifiers,
  getUserByLanId,
  getAccessToken,
} from 'store'
import { getTaxonomies } from 'store/taxonomy'

// Checks for token in storage on app startup
function* checkTokenStatus(action: any) {
  // Grabs token from storage or after login, and redirects to login if token not present
  try {
    let accessTokenFromStorage: string | null = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || null
    if (accessTokenFromStorage) {
      applyServiceRedirect(accessTokenFromStorage)
    }

    let displayNameFromStorage: string | null = localStorage.getItem(STORAGE_KEYS.DISPLAY_USER_NAME) || null
    let companyNameFromStorage: string | null = localStorage.getItem(STORAGE_KEYS.DISPLAY_COMPANY_NAME) || null
    if (displayNameFromStorage && companyNameFromStorage) {
      const userIdentifiers: IUserIdentifiers = {
        displayName: displayNameFromStorage,
        companyName: companyNameFromStorage,
      }
      yield put(setUserIdentifiers(userIdentifiers))
    }
    yield put(getTaxonomies())
    yield put(getUserByLanId())
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export default function* authSagas() {
  detectServiceRedirect()
  yield all([takeLatest(getAccessToken.type, checkTokenStatus)])
}
