import React from 'react'
import BasePage from 'components/pages/BasePage/BasePage'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import { BreadCrumbPriority } from 'store'

const NotFoundPage = () => (
  <BasePage crumbs={{ name: 'Page Not Found', href: '', priority: BreadCrumbPriority.High }}>
    <Grid.Container direction="column">
      <Grid.Item xs={12}>
        <Heading>Page Not Found</Heading>
      </Grid.Item>
      <Grid.Item xs={12}>
        <p>The page you were looking for is not here.</p>
        <p>
          If you believe this occurred in error, please email{' '}
          <a href="mailto:gallery-team@target.com">gallery-team@target.com</a> and let them know about it.
        </p>
      </Grid.Item>
    </Grid.Container>
  </BasePage>
)

export default NotFoundPage
