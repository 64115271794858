import React, { useEffect } from 'react'

import { Grid, Button, Modal, Form, Spinner, Input } from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import './CreateCollectionModal.scss'
import { useCollections } from 'store'
import CollectionPublicPrivate from '../../../components/collectionPublicPrivate/CollectionPublicPrivate'
import { match, descriptionMatcher } from 'utils/validation'
import { useEnv } from '@praxis/component-runtime-env'
import AddManagersSelect from './AddManagersSelect'

interface ICreateCollectionValues {
  name: string
  description: string
  make_gallery_public: boolean
  managers?: string[]
  makeCollectionFav?: boolean
}

type ICreateCollectionModalProps = {
  closeModal: () => void
  data?: any
}

const CreateCollectionModal: React.FC<ICreateCollectionModalProps> = ({ closeModal }) => {
  const { createGallery } = useCollections()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isPublic, setIsPublic] = useState<boolean>(true)
  const [collectionFav, makeCollectionFav] = useState<boolean>(false)
  const navigate = useNavigate()
  const { nameMatcher } = useEnv()
  const [managers, setManagers] = useState<string[]>([])
  const initialValues: ICreateCollectionValues = {
    name: '',
    description: '',
    make_gallery_public: true,
  }
  const formik = useFormik({
    initialValues,
    onSubmit: async (values: ICreateCollectionValues) => {
      const { name, description } = values
      setIsSubmitting(true)
      // We are creating a collection.
      await createGallery(galleryCollectionDetailPage, name, description, isPublic, [], managers, collectionFav)
    },
    validate: (values: ICreateCollectionValues) => {
      const errors: any = {}
      if (!values.name.trim()) {
        errors.name = 'Required'
      }
      if (!match(values.name, nameMatcher.expression)) {
        errors.name = nameMatcher.message
      }
      if (!match(values.description, descriptionMatcher.expression)) {
        errors.description = descriptionMatcher.message
      }
      return errors
    },
    validateOnMount: true,
    isInitialValid: false,
  })

  const galleryCollectionDetailPage = (collectionId: string) => {
    navigate(`/collection/${collectionId}`, { replace: true })
  }
  const { name, description } = formik.values

  useEffect(() => {
    formik.validateForm()
  }, [name, description])

  return (
    <Modal className="create-collection-modal" isVisible onRefuse={() => closeModal()} onApproveModal={() => {}}>
      <div>
        <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">Create a new collection</h1>
      </div>
      <div className="hc-pa-normal create-collection-container">
        <Form onSubmit={formik.handleSubmit} className="hc-pa-normal">
          <Grid.Container direction="column">
            <Grid.Item xs={12}>
              <Grid.Container>
                <Grid.Item xs={12} className="collection-name hc-mb-none hc-mt-none hc-pb-none">
                  <Form.Field
                    data-testid="create-new-collection-input"
                    error={!!(formik.touched.name && formik.errors.name)}
                    errorText={formik.errors.name}
                    type="text"
                    label="Collection title"
                    name="name"
                    placeholder="Enter collection name"
                    onChange={formik.handleChange}
                    value={name}
                    disabled={isSubmitting}
                    required
                    onBlur={formik.handleBlur}
                  />
                </Grid.Item>

                <Grid.Item xs={12} className="collection-description hc-mb-none hc-mt-none hc-pb-sm">
                  <Form.Field
                    error={!!(formik.touched.description && formik.errors.description)}
                    errorText={formik.errors.description}
                    type="textarea"
                    label="Collection description"
                    name="description"
                    placeholder="Briefly describe your collection"
                    onChange={formik.handleChange}
                    value={description}
                    disabled={isSubmitting}
                    onBlur={formik.handleBlur}
                    data-testid="create-new-collection-description-input"
                  />
                </Grid.Item>
                <Grid.Item xs={12} className="hc-mt-none hc-pt-none">
                  <CollectionPublicPrivate makeCollectionPublic={setIsPublic} collectionPublic={isPublic} />
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-mt-none hc-pt-sm">
              <Input.Label htmlFor="addUser" data-testid="form-label">
                Add Collection Managers
              </Input.Label>
              <AddManagersSelect
                placeholderText="Add Collection Managers"
                setManagers={setManagers}
              ></AddManagersSelect>
            </Grid.Item>
            <Grid.Item className="hc-mt-none">
              <Input.Checkbox
                key="add-collection-as-fav"
                data-testid="dd-collection-as-fav"
                label="Add to Favorite Collections"
                checked={collectionFav}
                onChange={() => {
                  makeCollectionFav(!collectionFav)
                }}
              />
            </Grid.Item>
            <Grid.Item xs={12} className="hc-mt-none hc-pt-none">
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    type="primary"
                    className="submitButton "
                    onClick={() => formik.submitForm()}
                    disabled={isSubmitting || !formik.isValid}
                    data-testid="create-collection-button"
                  >
                    {isSubmitting && <Spinner size="dense" />}
                    {!isSubmitting && 'Create'}
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button onClick={() => closeModal()} data-testid="cancel-collection-creation-button">
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Form>
      </div>
    </Modal>
  )
}

export default CreateCollectionModal
