import ReactGA from 'react-ga'

const useLink = () => {
  const linkEventListner = (linkCategory: string, linkAction: string, linkLabel: string) => {
    ReactGA.event({
      category: linkCategory,
      action: linkAction,
      label: linkLabel,
    })
  }

  return { linkEventListner }
}

export default useLink
