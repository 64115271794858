import React from 'react'
import { Link, useLocation } from 'react-router-dom'

// HistoricalLink automatically provides details regarding the previous page.

interface IHLState {
  isPublic: Boolean
}
interface IHistoricalLinkProps {
  pathname?: string
  state?: IHLState
  children: any
}

const HistoricalLink: React.FC<IHistoricalLinkProps> = ({ children, pathname, state = {} }) => {
  const location = useLocation()
  const to = pathname ? { pathname, state: { location, state } } : {}

  return <Link to={to}>{children}</Link>
}

export default HistoricalLink
