import React from 'react'
import { Heading, Grid, Button, Divider, Spinner } from '@enterprise-ui/canvas-ui-react'
import './FilterContainer.scss'
import FilterItem from './FilterItem'
import { IFilterAction, IFilterItemUI, IFilterValueUI, ICustomFilterValue } from 'store'
import { useSearch } from 'store'
import { useEnv } from '@praxis/component-runtime-env'

const FilterContainer: React.FC = () => {
  const numberOfStartingExpandedSections = 2
  const { searchFilters, searchFiltersAreLoading, updateSearchCriteriaFilters, currentFilterChips } = useSearch()
  const { customSearchFilters } = useEnv()

  // creating the filter items for customSearchFilters
  const createCustomFilterUiItems = () => {
    let customFilterItems: IFilterItemUI[] = []
    if (customSearchFilters) {
      Object.keys(customSearchFilters).forEach((key: string) => {
        const filterKeyUI = key
        let filterValueUI: IFilterValueUI[] = []
        customSearchFilters[key].forEach((value: ICustomFilterValue) => {
          filterValueUI.push({
            filterValue: value,
            selected: false,
          })
        })
        customFilterItems.push({ filterKeyUI: filterKeyUI, filterValueUI: filterValueUI })
      })
    }
    return customFilterItems
  }

  const customFilterUiItems = createCustomFilterUiItems()

  return (
    <div className={'filter-container'}>
      <Grid.Container className="hc-pa-normal hc-bg-white" spacing="none" justify="space-between">
        <Grid.Item>
          <Heading size={4} data-test-id="filter-header" className="filter-header">
            Filter Search
          </Heading>
        </Grid.Item>
        <Grid.Item>
          <Button
            className="clear-filters-button"
            data-testid="clear-filters-button"
            onClick={() => updateSearchCriteriaFilters(IFilterAction.RemoveAll)}
            type="primary"
            disabled={Object.keys(currentFilterChips ? currentFilterChips : {}).length < 1}
          >
            CLEAR ALL
          </Button>
        </Grid.Item>
      </Grid.Container>
      <Divider />
      {searchFiltersAreLoading && (
        <Grid.Container data-testid="load-indicator-container" justify="center" className="hc-pt-xl">
          <Spinner data-test-id="load-indicator" />
        </Grid.Container>
      )}
      {searchFilters.length > 0 &&
        !searchFiltersAreLoading &&
        searchFilters.map((filterItemUI: IFilterItemUI, i: number) => (
          <FilterItem
            key={filterItemUI.filterKeyUI}
            filterItemUI={filterItemUI}
            startExpanded={i < numberOfStartingExpandedSections ? true : false}
            showInput={true}
          />
        ))}
      {!searchFiltersAreLoading &&
        customFilterUiItems &&
        customFilterUiItems.map((filterItemUI: IFilterItemUI) => (
          <FilterItem
            key={filterItemUI.filterKeyUI}
            filterItemUI={filterItemUI}
            startExpanded={false}
            showInput={false}
          />
        ))}
    </div>
  )
}

export default FilterContainer
