import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Breadcrumbs, BreadcrumbsProps, Heading } from '@enterprise-ui/canvas-ui-react'
import { BreadCrumbPriority, IBreadCrumb, useBreadCrumbs } from 'store'
import { BREAD_CRUMB_VARS } from 'config'

const BreadCrumbs = () => {
  const { NUM_CRUMBS } = BREAD_CRUMB_VARS
  const navigate = useNavigate()
  let { reroute, crumbsFromSlice } = useBreadCrumbs()

  let updatedCrumbs: BreadcrumbsProps[] = crumbsFromSlice
    // This reduce function gets rid of lower priority crumbs until it reaches the length of the NUM_CRUMB constant.
    .reduce((prevCrumbs, currentCrumbs, currentIndex, allCrumbs) => {
      let crumbHolder

      const newCrumbs = currentIndex === 0 ? allCrumbs : prevCrumbs

      // Holds current value of newCrumbs so we can return it if nothing changes.
      crumbHolder = Array.from(newCrumbs)

      // Checking the length of our array to see if it's longer than NUM_CRUMBS
      if (newCrumbs.length > NUM_CRUMBS) {
        const homeFiltered = newCrumbs.filter(crumb => crumb.name !== 'Home')

        // Returns to us the lowest priority crumb in our list
        const lowestPriority = homeFiltered.reduce((a: IBreadCrumb, b: IBreadCrumb) => {
          const grouped: IBreadCrumb[] = [a, b]
          const smallest: BreadCrumbPriority = Math.min(a.priority, b.priority)
          return grouped.find((bc: IBreadCrumb) => bc.priority === smallest) || a
        })

        // Filters out the lowest priority crumb and excludes "Home" from the list. Sets it to crumbHolder
        crumbHolder = newCrumbs.filter(bc => {
          if (!bc.name) return true
          if (bc.name === 'Home') return true

          return bc.name !== lowestPriority.name
        })
      }

      return crumbHolder
    }, crumbsFromSlice)
    .filter(crumbs => 'name' in crumbs)
    .map(
      (crumb): BreadcrumbsProps => ({
        name: crumb.name,
        onClick: () => {
          //Navigate
          const index = crumbsFromSlice.findIndex(currentCrumb => {
            return currentCrumb.href === crumb.href
          })
          const newCrumbs = crumbsFromSlice?.slice(0, index! + 1)
          reroute(newCrumbs)
          navigate({ pathname: crumb.href, search: crumb.search })
        },
      })
    )

  return (
    <Breadcrumbs crumbAs="button" className="hc-ml-lg" crumbs={updatedCrumbs}>
      {updatedCrumbs.map((crumb, i) => {
        return (
          <Breadcrumbs.Item {...crumb} className={'hc-pa-none'} key={`${crumb['data-testid']}-key`}>
            <Heading size={6}>{crumb.name.toUpperCase()}</Heading>
          </Breadcrumbs.Item>
        )
      })}
    </Breadcrumbs>
  )
}

export default BreadCrumbs
