import _ from 'lodash'
import { arrayEquals, isArray } from 'utils/array'
import { toCamel } from 'utils/string'

export const isObject = (o: any): boolean => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}

export const keysToCamel = function (o: any) {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: string) => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamel(i)
    })
  }

  return o
}

export const deepCompareObjects = (object1: any, object2: any): boolean => {
  const objectOneKeys = Object.keys(object1)
  const objectTwoKeys = Object.keys(object2)
  const keysAreEqual = arrayEquals(objectOneKeys, objectTwoKeys)
  if (keysAreEqual) {
    let objectsEqual = true
    objectOneKeys.forEach((key: any) => {
      const valueFromObjectOne = object1[key]
      const valueFromObjectTwo = object2[key]
      if (!_.isEqual(valueFromObjectOne, valueFromObjectTwo)) {
        objectsEqual = false
      }
    })
    return objectsEqual
  } else {
    return false
  }
}
