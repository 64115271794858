import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { useSelector } from 'react-redux'
import { IToast, toastsSelector } from 'store'

export interface IToastHook {
  toasts: IToast[]
}

export const useToasts = (): IToastHook => {
  useToaster()
  const toasts = useSelector(toastsSelector)

  return { toasts }
}
