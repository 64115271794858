import React from 'react'

import { Grid, Button, Modal, Form, Spinner, Table, Anchor, Input } from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styles from './AddAssetsToNewCollectionModal.module.scss'
import AddManagersSelect from '../CreateCollectionModal/AddManagersSelect'
import { useDispatch } from 'react-redux'
import { addToast, useCollections, IAsset } from 'store'
import { match, descriptionMatcher } from 'utils/validation'
import { useEnv } from '@praxis/component-runtime-env'
import EnterpriseIcon, { TrashIcon } from '@enterprise-ui/icons'

interface IAddAssetsToNewCollectionValues {
  name: string
  description: string
  assets_being_added: IAsset[]
  make_gallery_public: boolean
}

type IAddAssetsToNewCollectionProps = {
  closeModal: () => void
  data?: any
  container: boolean
  visible: boolean
  onBack: () => void
}

const AddAssetsToNewCollectionModal: React.FC<IAddAssetsToNewCollectionProps> = ({
  closeModal,
  data,
  container,
  visible,
  onBack,
}) => {
  const { createGallery } = useCollections()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [collectionFav, makeCollectionFav] = useState<boolean>(false)
  const [managers, setManagers] = useState<string[]>([])
  const navigate = useNavigate()
  const { nameMatcher } = useEnv()
  const initialValues: IAddAssetsToNewCollectionValues = {
    name: '',
    description: '',
    assets_being_added: data,
    make_gallery_public: true,
  }
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: IAddAssetsToNewCollectionValues) => {
      const { name, description, assets_being_added, make_gallery_public } = values
      setIsSubmitting(true)
      if (assets_being_added !== undefined) {
        // We are creating a collection.
        await createGallery(
          galleryCollectionDetailPage,
          name,
          description,
          make_gallery_public,
          assets_being_added.map(asset => asset.id),
          managers,
          collectionFav
        )
      } else {
        const message =
          'Something went wrong and collections and/or assets cannot be found.  You may want to report this issue.'
        dispatch(addToast({ color: 'error', headingText: 'Cannot submit', message }))
      }
    },
    validate: (values: IAddAssetsToNewCollectionValues) => {
      const errors: any = {}
      if (values.name === undefined || values.name.trim().length <= 0) {
        errors.name = 'New collection must have a name.'
      }
      if (!match(values.name, nameMatcher.expression)) {
        errors.name = nameMatcher.message
      }
      if (!match(values.description, descriptionMatcher.expression)) {
        errors.description = descriptionMatcher.message
      }
      return errors
    },
    validateOnMount: true,
    enableReinitialize: true,
  })

  const galleryCollectionDetailPage = (collectionId: string) => {
    navigate(`/collection/${collectionId}`, { replace: true })
  }

  const { name, description, assets_being_added, make_gallery_public } = formik.values

  useEffect(() => {
    formik.validateForm()
  }, [name, make_gallery_public, visible])

  let getContainer = () => {
    return (
      <div className="hc-pa-normal">
        <Form onSubmit={formik.handleSubmit} className="hc-pa-normal">
          <Grid.Container className={styles['add-to-collection-select']} data-test-id="add-to-collection-input">
            <Grid.Item xs={7}>
              <Grid.Container direction="column">
                <Grid.Item>
                  <Form.Field
                    data-testid="create-new-collection-input"
                    error={!!(formik.touched.name && formik.errors.name)}
                    errorText={formik.errors.name}
                    type="text"
                    label="Collection title"
                    name="name"
                    placeholder="Enter collection name"
                    onChange={formik.handleChange}
                    value={name}
                    disabled={isSubmitting}
                    required
                    onBlur={formik.handleBlur}
                  />
                </Grid.Item>
                <Grid.Item>
                  <Form.Field
                    error={!!(formik.touched.description && formik.errors.description)}
                    errorText={formik.errors.description}
                    type="textarea"
                    label="Collection description"
                    name="description"
                    placeholder="Briefly describe your collection"
                    onChange={formik.handleChange}
                    value={description}
                    disabled={isSubmitting}
                    onBlur={formik.handleBlur}
                    data-testid="create-new-collection-description"
                  />
                </Grid.Item>
                <Grid.Item>
                  <div className="hc-pa-none">
                    <div className={'hc-pa-none hc-fs-xs'}>Collection permissions</div>
                    <Form.Field
                      data-test-id="new-collection-is-public-toggle"
                      type="radio"
                      name="permissions"
                      options={[
                        { value: true, label: 'Public collection (all Gallery users can access & share)' },
                        {
                          value: false,
                          label: 'Private collection (only users who have permission can access)',
                        },
                      ]}
                      onUpdate={(id: any, value: boolean) => {
                        if (value !== undefined) {
                          formik.setFieldValue('make_gallery_public', value)
                        }
                      }}
                      value={make_gallery_public}
                      disabled={isSubmitting}
                    />
                  </div>
                  <Grid.Item className={styles['margin-padding-create-collection']}>
                    <Input.Label htmlFor="addUser" data-testid="form-label">
                      Add Collection Managers
                    </Input.Label>
                    <AddManagersSelect
                      placeholderText="Add Collection Managers"
                      setManagers={setManagers}
                    ></AddManagersSelect>
                  </Grid.Item>
                  <Grid.Item className={`${styles['margin-padding-create-collection']} hc-mt-2x`}>
                    <Input.Checkbox
                      key="add-collection-as-fav"
                      data-testid="dd-collection-as-fav"
                      label="Add to Favorite Collections"
                      checked={collectionFav}
                      onChange={() => {
                        makeCollectionFav(!collectionFav)
                      }}
                    />
                  </Grid.Item>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={5}>
              <Input.Label className="hc-fs-sm" data-test-id="create-collection-assets-header">
                <strong> Assets being added ({assets_being_added ? assets_being_added.length : 0}) </strong>
              </Input.Label>
              {assets_being_added && assets_being_added.length > 0 && (
                <div className={`${styles['asset-list']} hc-pr-dense hc-ov-auto`}>
                  <Table>
                    <Table.Body>
                      {(assets_being_added || []).map((asset, index) => (
                        <Table.Row key={asset.id} align="center">
                          <Table.Data
                            xs={11}
                            className="hc-pb-dense hc-fs-xs"
                            data-test-id="create-collection-asset-name"
                          >
                            {asset.name}
                          </Table.Data>
                          <Table.Data xs={1}>
                            <Button
                              data-test-id="create-collection-remove-asset-button"
                              iconOnly
                              disabled={isSubmitting}
                              className="hc-bg-black"
                              size="dense"
                              onClick={() => {
                                const newCollectionAssets = assets_being_added.filter(
                                  collectionAsset => collectionAsset.id !== asset.id
                                )
                                formik.setFieldValue('assets_being_added', newCollectionAssets)
                              }}
                            >
                              <EnterpriseIcon icon={TrashIcon} />
                            </Button>
                          </Table.Data>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Grid.Item>
          </Grid.Container>
        </Form>
        <Grid.Container align="center" justify="space-between" className="hc-ph-md hc-pt-normal">
          <Grid.Item className="hc-ph-none">
            <Anchor data-test-id="create-new-collection-back-anchor" onClick={onBack}>{`< Back`}</Anchor>
          </Grid.Item>
          <Grid.Item className="hc-ph-none">
            <Grid.Container align="center" className="hc-pa-none hc-ma-none">
              <Button
                className="hc-fs-xs hc-bg-grey07 hc-mr-dense"
                onClick={() => closeModal()}
                data-test-id="cancel-collection-creation-button"
              >
                Cancel
              </Button>
              <Button
                className={`hc-fs-xs hc-ml-dense ${styles['add-asset-button']} hc-pv-min`}
                onClick={() => formik.submitForm()}
                disabled={isSubmitting || !formik.isValid}
                data-testid="create-collection-button"
                type="primary"
              >
                {isSubmitting && <Spinner size="dense" />}
                {!isSubmitting && 'Create and add'}
              </Button>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  }

  if (!container) {
    return (
      <Modal
        className={`${styles['create-and-add-to-collection-modal']} hc-pb-dense`}
        headingText="Create a new collection"
        isVisible={visible}
        onRefuse={() => closeModal()}
        onApproveModal={() => {}}
      >
        {getContainer()}
      </Modal>
    )
  }
  if (!visible) return <></>
  return getContainer()
}

export default AddAssetsToNewCollectionModal
