import { useSelector, useDispatch } from 'react-redux'
import {
  getCollectionDetailsReducer,
  getPublicCollectionsReducer,
  getMyCollectionsReducer,
  resetPublicCollectionsReducer,
  resetOwnCollectionsReducer,
  resetCollectionState,
  addAssetsToGalleryReducer,
  deleteAssetsToGalleryReducer,
  downloadGalleryReducer,
  createGalleryReducer,
  editGalleryReducer,
  deleteCollectionReducer,
  addGalleryUserReducer,
  deleteGalleryUserReducer,
  getGalleryUsersReducer,
  setGalleryUsersReducer,
  setCollectionDetailsStatus,
  getCollectionAssetsState,
  getFavoriteCollectionsFeed,
  favoriteGalleryReducer,
  unFavoriteGalleryReducer,
  resetFavoriteCollectionsReducer,
  getFeaturedCollection,
} from './slice'
import {
  ownCollectionsSelector,
  publicCollectionsSelector,
  collectionDetailsSelector,
  collectionAssetsSelector,
  collectionIsLoadingSelector,
  collectionUsersSelector,
  collectionDetailPageStatusSelector,
  featuredCollectionSelector,
  favoriteCollectionsSelector,
} from './selector'

import { ISearchSort, PageStatus, setModalData, setModalType, CollectionSort, CollectionSet } from 'store'
import { useLocation, useNavigate } from 'react-router-dom'
import { get } from 'lodash'
import { IDeleteModalData } from 'components/modals/DeleteModal/DeleteModal'
import { cleanTitle } from 'utils/string'
import { MODAL_TYPES } from 'config/constants'
import { IEditCollectionManagerModalValues } from 'components/modals/EditCollectionManagerModal/EditCollectionManagerModal'
import { collectionPageSizeSelector } from 'store'
import { useEnv } from '@praxis/component-runtime-env'

export const useCollections = () => {
  const ownCollections = useSelector(ownCollectionsSelector)
  const publicCollections = useSelector(publicCollectionsSelector)
  const favoriteCollections = useSelector(favoriteCollectionsSelector)
  const collectionDetails = useSelector(collectionDetailsSelector)
  const collectionAssets = useSelector(collectionAssetsSelector)
  const isLoading = useSelector(collectionIsLoadingSelector)
  const collectionUsers = useSelector(collectionUsersSelector)
  const collectionDetailPageStatus = useSelector(collectionDetailPageStatusSelector)
  const collectionPageSize = useSelector(collectionPageSizeSelector)
  const featuredCollection = useSelector(featuredCollectionSelector)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { searchServer, featuredCollectionId } = useEnv()

  // captures path of previous page, if deep-linked to page, pathname === ''
  const pathname: string = get(location, 'state.location.pathname', '')

  const getCollectionSortOrder = (collectionSort: CollectionSort) => {
    switch (collectionSort) {
      case CollectionSort.LATEST_ASC:
        return [
          {
            direction: 'Asc',
            field: 'CREATED_AT',
          },
        ]
      case CollectionSort.LATEST_DESC:
        return [
          {
            direction: 'Desc',
            field: 'CREATED_AT',
          },
        ]
      case CollectionSort.NAME_ASC:
        return [
          {
            direction: 'Asc',
            field: 'NAME',
          },
        ]
      case CollectionSort.NAME_DESC:
        return [
          {
            direction: 'Desc',
            field: 'NAME',
          },
        ]
      default:
        console.log('Invalid sort order found.')
        return []
    }
  }

  const getOwnCollections = async (query: string, page: number, size?: number, sort?: ISearchSort[]) => {
    const payload = {
      searchServer,
      query,
      page,
      size,
      sort,
    }
    if (page === 0) {
      resetMyCollection()
    }
    dispatch(getMyCollectionsReducer(payload))
  }

  const getFavoriteCollections = async (query: string, page: number, size?: number, sort?: ISearchSort[]) => {
    const payload = {
      searchServer,
      query,
      page,
      size,
      sort,
    }
    if (page === 0) {
      resetFavoriteCollection()
    }
    dispatch(getFavoriteCollectionsFeed(payload))
  }

  const getPublicCollections = async (page: number, size?: number, sort?: ISearchSort[], query?: string) => {
    const payload = {
      searchServer,
      page: page,
      size: size,
      sort: sort,
      query: query,
    }
    if (page === 0) {
      resetPublicCollection()
    }
    dispatch(getPublicCollectionsReducer(payload))
  }

  const getCollectionDetails = async (galleryId: string) => {
    dispatch(getCollectionDetailsReducer(galleryId))
  }

  const getCollectionAssets = async (galleryId: string, page: number, size: number) => {
    const payload = { galleryId, page, size }
    dispatch(getCollectionAssetsState(payload))
  }

  const getPaginatedCollectionAssets = () => {
    const { p, size } = collectionAssets.paginationCriteria
    getCollectionAssets(collectionDetails.id, p + 1, size || collectionPageSize)
  }

  const resetPublicCollection = () => {
    dispatch(resetPublicCollectionsReducer())
  }

  const resetMyCollection = () => {
    dispatch(resetOwnCollectionsReducer())
  }

  const resetFavoriteCollection = () => {
    dispatch(resetFavoriteCollectionsReducer())
  }

  const resetCollection = () => {
    dispatch(resetCollectionState())
  }

  const setCollectionDetailPageStatus = (status: PageStatus) => {
    dispatch(setCollectionDetailsStatus(status))
  }

  const addAssetsToGallery = async (
    galleryId: string,
    assetIds: string[],
    onSuccessCallBack: (collectionId: string) => void
  ) => {
    const payload = {
      galleryId,
      assetIds,
      onSuccessCallBack,
    }
    dispatch(addAssetsToGalleryReducer(payload))
  }

  const deleteGalleryAsset = async (galleryId: string, assetIds: string[]) => {
    const payload = {
      galleryId,
      assetIds,
    }
    dispatch(deleteAssetsToGalleryReducer(payload))
  }

  const triggerDeleteCollectionModal = async (id: string, name: string, triggerDelete: () => void) => {
    const modalData: IDeleteModalData = {
      data: id,
      triggerDelete,
      bodyText: `"${cleanTitle(name || '')}" will be deleted. The assets within the collection will remain in Gallery.`,
      onRefuseText: 'Cancel',
      onApproveText: 'Delete collection',
      headingText: 'Delete this collection?',
    }
    dispatch(setModalData(modalData))
    dispatch(setModalType(MODAL_TYPES.DELETE_COLLECTION))
  }

  const triggerDeleteCollection = async (id: string, name: string) => {
    const payload = {
      id,
      name,
      pathname,
      navigate,
    }
    dispatch(deleteCollectionReducer(payload))
  }

  const downloadGallery = async (galleryId: string) => {
    const payload = {
      galleryId: galleryId,
    }
    dispatch(downloadGalleryReducer(payload))
  }

  const favoriteGallery = async (galleryId: string, collectionSet: CollectionSet) => {
    const payload = {
      galleryId: galleryId,
      collectionSet: collectionSet,
      searchServer: searchServer,
    }
    dispatch(favoriteGalleryReducer(payload))
  }

  const unFavoriteGallery = async (galleryId: string, collectionSet: CollectionSet) => {
    const payload = {
      galleryId: galleryId,
      collectionSet: collectionSet,
      searchServer: searchServer,
    }
    dispatch(unFavoriteGalleryReducer(payload))
  }

  const createGallery = async (
    onSuccessCallBack: (collectionId: string) => void,
    name: string,
    description: string = '',
    make_asset_public: boolean,
    asset_ids?: string[],
    managers?: string[],
    makeCollectionFav?: boolean
  ) => {
    const payload = {
      name,
      description,
      make_asset_public,
      asset_ids,
      managers,
      makeCollectionFav,
      onSuccessCallBack,
    }
    dispatch(createGalleryReducer(payload))
  }

  const editGallery = async (
    onSuccessCallBack: () => void,
    id: string,
    name: string,
    description: string = '',
    make_collection_public: boolean,
    assetsToRemove?: string[]
  ) => {
    const payload = {
      id,
      name,
      description,
      make_collection_public,
      assetsToRemove,
      onSuccessCallBack,
    }
    dispatch(editGalleryReducer(payload))
  }

  const getGalleryUsers = async (galleryId: string, permission: string = 'EDIT') => {
    const payload = {
      galleryId,
      permission,
    }
    dispatch(getGalleryUsersReducer(payload))
  }

  const deleteGalleryUser = async (galleryId: string, userId: string) => {
    const payload = {
      galleryId,
      userId,
    }
    dispatch(deleteGalleryUserReducer(payload))
  }

  const launchEditCollectionmanagerModal = async (
    collectionId: string,
    usersToBeAdded?: string[],
    userToBeRemoved?: string
  ) => {
    const modalData: IEditCollectionManagerModalValues = {
      collectionId: collectionId!!,
      usersToBeAdded: usersToBeAdded,
      userToBeRemoved: userToBeRemoved,
    }
    dispatch(setModalData(modalData))
    dispatch(setModalType(MODAL_TYPES.EDIT_COLLECTION_USER))
  }

  const addGalleryUser = async (
    galleryId: string,
    userId: string[],
    access:
      | 'NONE'
      | 'ADMIN'
      | 'ORGANIZATION_ADMIN'
      | 'WORKFLOW_ADMIN'
      | 'ORGANIZATION_ASSET_CREATOR'
      | 'ORGANIZATION_ASSET_MANAGER'
      | 'ORGANIZATION_ASSET_VIEWER'
      | 'ORGANIZATION_GALLERY_VIEWER'
      | 'ORGANIZATION_GALLERY_MANAGER'
      | 'ORGANIZATION_GALLERY_CREATOR'
      | 'GALLERY_CREATOR'
      | 'GALLERY_MANAGER'
      | 'GALLERY_VIEWER'
      | 'ASSET_CREATOR'
      | 'ASSET_MANAGER'
      | 'ASSET_VIEWER'
      | 'WORKFLOW_REVIEWER'
      | 'WORKFLOW_RENDER_CREATOR'
      | 'WORKFLOW_DEFAULT'
  ) => {
    const payload = {
      galleryId,
      userId,
      access,
    }
    dispatch(addGalleryUserReducer(payload))
  }

  const resetCollectionUsers = async () => {
    dispatch(setGalleryUsersReducer([]))
  }

  const getFeatCollection = async () => {
    const payload = {
      id: featuredCollectionId,
      page: 0,
      size: 25,
    }
    dispatch(getFeaturedCollection(payload))
  }

  return {
    getOwnCollections,
    getPublicCollections,
    getFavoriteCollections,
    getCollectionDetails,
    getCollectionAssets,
    getPaginatedCollectionAssets,
    collectionPageSize,
    resetPublicCollection,
    resetMyCollection,
    resetCollection,
    isLoading,
    ownCollections,
    collectionDetails,
    collectionAssets,
    publicCollections,
    favoriteCollections,
    addAssetsToGallery,
    deleteGalleryAsset,
    downloadGallery,
    createGallery,
    editGallery,
    triggerDeleteCollection,
    triggerDeleteCollectionModal,
    launchEditCollectionmanagerModal,
    addGalleryUser,
    deleteGalleryUser,
    getGalleryUsers,
    collectionUsers,
    collectionDetailPageStatus,
    resetCollectionUsers,
    setCollectionDetailPageStatus,
    featuredCollection,
    favoriteGallery,
    unFavoriteGallery,
    getCollectionSortOrder,
    resetFavoriteCollection,
    getFeatCollection,
  }
}
