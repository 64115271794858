import React from 'react'
import { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'

import {
  Modal,
  Form,
  Heading,
  Grid,
  Tooltip,
  Avatar,
  Caption,
  Button,
  Spinner,
  Anchor,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { ActionMeta, components, MultiValue } from 'react-select'
import './EditAssetManagerModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { some } from 'lodash'
import { convertToProperCase } from 'utils/string'
import { PermissionView } from 'gallerydigitalassets-v1-client'
import { processError, useAsset, IUser, IPotentialManagerOption } from 'store'
import { includesCaseInsensitive } from 'utils/string'

export interface IEditAssetManagerModalValues {
  assetId: string
  user_to_be_added?: string
  user_to_be_removed?: string
  users_to_be_added?: string[]
}

export interface IEditAssetManagerModalProps {
  closeModal: () => void
  data: IEditAssetManagerModalValues
}

const EditAssetManagerModal: React.FC<IEditAssetManagerModalProps> = ({ closeModal, data }) => {
  const [options, setOptions] = useState<IPotentialManagerOption[]>([])
  const [potentialAssetManagers, setPotentialAssetManagers] = useState<IUser[]>([])
  const [assetOwners, setAssetOwners] = useState<IUser[]>([])
  const [assetManagers, setAssetManagers] = useState<IUser[]>([])
  const [selectedOption, setSelectedOption] = useState<IPotentialManagerOption | {}>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false)
  const initialValues: IEditAssetManagerModalValues = {
    assetId: data.assetId!!,
    user_to_be_added: '',
    user_to_be_removed: '',
    users_to_be_added: [],
  }
  const { getAssetUsers, addAssetUser, deleteAssetUser, assetUsers, resetUsers } = useAsset()

  useEffect(() => {
    getAssetUsers(data.assetId, 'EDIT')
    // resets asset users so old asset users wont show on new page
    return () => {
      resetUsers()
    }
  }, [])

  useEffect(() => {
    if (some(assetUsers)) {
      let assetUserData = [...assetUsers]
      assetUserData = assetUserData.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.toLowerCase() < `${b.firstName} ${b.lastName}`.toLowerCase() ? 1 : -1
      )
      setPotentialAssetManagers(
        assetUserData.filter(
          (user: IUser) =>
            user.access!! !== PermissionView.AccessEnum.AssetCreator &&
            user.access!! !== PermissionView.AccessEnum.AssetManager
        )
      )
      setAssetOwners(assetUserData.filter((user: IUser) => user.access!! === PermissionView.AccessEnum.AssetCreator))
      setAssetManagers(assetUserData.filter((user: IUser) => user.access!! === PermissionView.AccessEnum.AssetManager))
    }
  }, [assetUsers])

  useEffect(() => {
    if (some(potentialAssetManagers)) {
      const options: IPotentialManagerOption[] = potentialAssetManagers.map((user: IUser): IPotentialManagerOption => {
        let option: IPotentialManagerOption = {
          value: user.id,
          label: `${convertToProperCase(user.firstName)} ${convertToProperCase(user.lastName)}`,
          subLabel: user.mail,
        }
        return option
      })
      setOptions(options.sort((a, b) => (a.label > b.label ? 1 : -1)))
    }
  }, [potentialAssetManagers])

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <Grid.Container direction="row" spacing="none">
          <Grid.Item className="hc-fs-xs hc-clr-black hc-pr-min">{props.data.label}</Grid.Item>
          <Grid.Item className="hc-fs-xs hc-clr-grey01">{props.data.subLabel}</Grid.Item>
        </Grid.Container>
      </components.Option>
    )
  }

  let loadOptions = (searchTerm: string, callback: (options: any) => void) => {
    setTimeout(() => {
      callback(filterOptions(searchTerm))
    }, 1000)
  }

  let filterOptions = (searchTerm: string) => {
    if (searchTerm === undefined || searchTerm === '') {
      return options
    }
    return options.filter((option: IPotentialManagerOption) => includesCaseInsensitive(option.label, searchTerm))
  }

  useEffect(() => {
    if (options.length !== 0) {
      setOptionsLoaded(!optionsLoaded)
    }
  }, [options])

  const handleInputChange = (newValue: string) => {
    const searchTerm = newValue.replace(/\W/g, '')
    return searchTerm
  }

  const handleChange = (value: MultiValue<IPotentialManagerOption>, action: ActionMeta<any>) => {
    if (value !== undefined && value.length > 0) {
      const usersToBeAdded = value.map((option: IPotentialManagerOption) => option.value)
      usersToBeAdded.forEach((userToBeAdded: String) => setSelectedOption(userToBeAdded))
      setSelectedOption(usersToBeAdded)
      formik.setFieldValue('users_to_be_added', usersToBeAdded)
      formik.setFieldValue('user_to_be_added', usersToBeAdded[0])
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: 40,
      borderStyle: 'solid',
      borderColor: 'black',
    }),
    placeholder: (base: any) => ({
      ...base,
      fontColor: '#666666',
      fontSize: '12px',
      textAlign: 'left',
    }),
    noOptionsMessage: (base: any) => ({
      ...base,
      fontColor: '#666666',
      textAlign: 'left',
      fontSize: '12px',
    }),
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: IEditAssetManagerModalValues) => {
      const { users_to_be_added } = values
      setIsSubmitting(true)
      let message: string
      if (users_to_be_added !== undefined && users_to_be_added.length > 0) {
        try {
          addAssetUser(assetId, users_to_be_added, 'ASSET_MANAGER')
          closeModal()
        } catch (error) {
          if (error instanceof Error) {
            processError(error, error.message, true)
          } else {
            console.log(error)
          }
          closeModal()
        }
      } else {
        message = 'Something went wrong and  asset users cannot be found.  You may want to report this issue.'

        const error = new Error(message)
        processError(error)
      }
    },
    validate: (values: IEditAssetManagerModalValues) => {
      const errors: any = {}
      if (values.users_to_be_added === undefined || values.users_to_be_added?.length < 1) {
        errors.users_being_added = 'Must have users being added, maybe you want to create a new asset instead'
      }
      return errors
    },
    validateOnMount: true,
    isInitialValid: false,
  })

  const { users_to_be_added, assetId } = formik.values

  useEffect(() => {
    formik.validateForm()
  }, [users_to_be_added, selectedOption])

  return (
    <>
      <Modal
        className="edit-asset-user-model hc-pb-dense"
        headingText="Edit Asset Manager"
        isVisible
        onRefuse={() => closeModal()}
        onApproveModal={() => {}}
      >
        <div className="hc-pa-normal edit-asset-user-container hc-pb-dense ">
          <Form onSubmit={formik.handleSubmit}>
            <Grid.Container direction="column">
              <Grid.Item xs={12} className="hc-pt-dense">
                <Input.Label> Add user</Input.Label>
                <AsyncSelect
                  isMulti={true}
                  key={JSON.stringify(optionsLoaded)}
                  noOptionsMessage={() => 'No users found'}
                  loadOptions={loadOptions}
                  defaultOptions={true}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  isClearable={true}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Option,
                  }}
                  placeholder="Enter name"
                  styles={customStyles}
                />
              </Grid.Item>
              {(some(assetOwners) || some(assetManagers)) && (
                <Grid.Item className="asset-user-list hc-pt-none">
                  <Grid.Container direction="column">
                    {some(assetOwners) && (
                      <Grid.Item className="hc-pb-none">
                        <Grid.Container direction="column" spacing="none">
                          <Grid.Container direction="row" spacing="none" align="center">
                            <Grid.Item>
                              <Heading size={5}>Owner</Heading>
                            </Grid.Item>
                            <Grid.Item className="hc-pl-min">
                              <Tooltip
                                data-testid="tooltip-collection-owner"
                                location="bottom-left"
                                wrap
                                content="Asset Owners can edit asset details, settings, add/remove files, delete the collection, & add/remove Asset Managers."
                              >
                                <FontAwesomeIcon icon="info-circle" className="fa-xs" />
                              </Tooltip>
                            </Grid.Item>
                          </Grid.Container>
                          {assetOwners.map((owner: IUser) => (
                            <Grid.Item key={owner.id} className="hc-pt-min">
                              <Grid.Container direction="row" spacing="none" align="center">
                                <Grid.Item>
                                  <Avatar
                                    aria-label={`Asset Owner: ${owner.firstName} avatar`}
                                    data-testid={`asset-owner-avatar-${owner.firstName.charAt(
                                      0
                                    )}${owner.lastName.charAt(0)}`}
                                    className="hc-bg-grey02"
                                  >{`${owner.firstName.charAt(0)}${owner.lastName.charAt(0)}`}</Avatar>
                                </Grid.Item>
                                <Grid.Item className="hc-pl-xs">
                                  <Caption
                                    data-testid={`asset-owner-email-${owner.mail}`}
                                    below={<p className="hc-clr-grey01 hc-fs-xs">{`${owner.mail}`}</p>}
                                  >
                                    <Heading
                                      data-testid={`asset-owner-name-${convertToProperCase(
                                        owner.firstName
                                      )} ${convertToProperCase(owner.lastName)}`}
                                      size={9}
                                      className="hc-pa-none hc-fs-sm hc-clr-black"
                                    >
                                      {`${convertToProperCase(owner.firstName)} ${convertToProperCase(owner.lastName)}`}
                                    </Heading>
                                  </Caption>
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </Grid.Container>
                      </Grid.Item>
                    )}
                    {some(assetManagers) && (
                      <Grid.Item className="hc-pt-dense">
                        <Grid.Container direction="column" spacing="none">
                          <Grid.Item>
                            <Grid.Container direction="row" spacing="none" align="center">
                              <Grid.Item>
                                <Heading size={5}>Managers</Heading>
                              </Grid.Item>
                              <Grid.Item className="hc-pl-min">
                                <Tooltip
                                  className="hc-pa-none"
                                  location="bottom-left"
                                  wrap
                                  content="Asset Managers can edit asset details, settings, &/or remove files."
                                >
                                  <FontAwesomeIcon icon="info-circle" className="fa-xs" />
                                </Tooltip>
                              </Grid.Item>
                            </Grid.Container>
                          </Grid.Item>
                          {assetManagers.map((manager: IUser) => (
                            <Grid.Item key={manager.id} className="hc-pt-min">
                              <Grid.Container direction="row" justify="space-between" align="center">
                                <Grid.Item>
                                  <Grid.Container direction="row" spacing="none" align="center">
                                    <Grid.Item>
                                      <Avatar
                                        aria-label={`Manager: ${manager.firstName} avatar`}
                                        data-testid={`asset-manager-avatar-${manager.firstName.charAt(
                                          0
                                        )}${manager.lastName.charAt(0)}`}
                                        className="hc-bg-grey02"
                                      >{`${manager.firstName.charAt(0)}${manager.lastName.charAt(0)}`}</Avatar>
                                    </Grid.Item>
                                    <Grid.Item className="hc-pl-xs">
                                      <Caption
                                        data-testid={`asset-manager-email-${manager.mail}`}
                                        below={<p className="hc-clr-grey01 hc-fs-xs">{`${manager.mail}`}</p>}
                                      >
                                        <Heading
                                          data-testid={`asset-manager-name-${convertToProperCase(
                                            manager.firstName
                                          )} ${convertToProperCase(manager.lastName)}`}
                                          size={9}
                                          className="hc-pa-none hc-fs-sm hc-clr-black"
                                        >
                                          {`${convertToProperCase(manager.firstName)} ${convertToProperCase(
                                            manager.lastName
                                          )}`}
                                        </Heading>
                                      </Caption>
                                    </Grid.Item>
                                  </Grid.Container>
                                </Grid.Item>
                                <Grid.Item>
                                  <Anchor
                                    data-testid={`remove-manager-button-${manager.id}`}
                                    onClick={() => deleteAssetUser(assetId, manager.id)}
                                  >
                                    Remove
                                  </Anchor>
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </Grid.Container>
                      </Grid.Item>
                    )}
                  </Grid.Container>
                </Grid.Item>
              )}
              <Grid.Item xs={12} className="bottom-buttons">
                <Grid.Container direction="row" justify="flex-end" align="center">
                  <Grid.Item className="hc-pr-md hc-mt-lg hc-mb-xs">
                    <Button
                      type="primary"
                      onClick={() => formik.submitForm()}
                      disabled={isSubmitting || !formik.isValid}
                      data-testid="edit-asset-user-button"
                    >
                      {isSubmitting && <Spinner size="dense" />}
                      {!isSubmitting && 'Done'}
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Form>
        </div>
      </Modal>
    </>
  )
}
export default EditAssetManagerModal
