import { useDispatch } from 'react-redux'
import { setModalData, setModalType } from 'store'

export const useModal = () => {
  const dispatch = useDispatch()

  const saveDataAndTypeToStore = (type: string, data: any) => {
    dispatch(setModalData(data))
    dispatch(setModalType(type))
  }

  return { saveDataAndTypeToStore }
}
