import React, { useEffect } from 'react'
import packageJson from './../../../package.json'
import axios from 'axios'
import semver from 'semver'
import { useEnv } from '@praxis/component-runtime-env'

const globalAny: any = global

globalAny.appVersion = packageJson.version as unknown as String

const CacheBuster = ({ children, env }: { children: React.ReactNode; env?: string }) => {
  const { useRefreshCache } = useEnv()
  const verifyMeta = async () => {
    if (useRefreshCache) {
      const meta = await axios.get(`/meta.json?version=${globalAny.appVersion}`)
      const shouldForceRefresh = semver.neq(meta.data.version, globalAny.appVersion)
      if (shouldForceRefresh) {
        refreshCacheReload()
      }
    }
  }

  // Perform clearing of cached items
  const refreshCacheReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name)
      })
    }
    // delete browser cache and hard reload
    forceReload()
  }

  // Forces a reload from server to get latest version of JS.
  const forceReload = () => {
    if (env) console.log('NOT YET IMPLEMENTED')
    window.location.href = window.location.href + '?nocache=' + new Date().getTime()
  }

  // Verify meta.js version on app reload.
  useEffect(() => {
    verifyMeta()
  }, [])
  return <>{children}</>
}

export default CacheBuster
