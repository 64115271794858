import { HttpError, UserView } from 'gallerydigitalassets-v1-client'
import { userApi } from '../client'

export const getByLanId = async (xTgtLanId: string, lanId: string): Promise<UserView | void> => {
  try {
    const { body } = await userApi.findUserById(xTgtLanId, undefined, lanId)
    return body.data
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      console.error(error)
    }
  }
}
