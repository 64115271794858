import { createSlice } from '@reduxjs/toolkit'
import { IFileState } from 'store'

const initialState: IFileState = {
  fileSelect: {
    checkedFiles: [],
    selectAll: { status: 0, indeterminate: false, filesSelected: 0 },
    fileDrawers: [],
  },
}

export const fileSlice = createSlice({
  name: 'files',
  initialState: initialState,
  reducers: {
    updateFileReducer(state, action) {},
    deleteFileReducer(state, action) {},
    updateAssetThumbnailReducer(state, action) {},
    downloadMultipleFilesReducer(state, action) {},
    downloadFileReducer(state, action) {},
    setCheckedFilesReducer(state, action) {
      state.fileSelect.checkedFiles = action.payload
    },
    updateSelectAllReducer(state, action) {
      state.fileSelect.selectAll.status = action.payload.status
      state.fileSelect.selectAll.indeterminate = action.payload.indeterminate
      state.fileSelect.selectAll.filesSelected = action.payload.filesSelected
    },
    setFileDrawers(state, action) {
      state.fileSelect.fileDrawers = action.payload
    },
    resetFileStoreReducer(state, action) {
      state.fileSelect = initialState.fileSelect
    },
  },
})

export const {
  updateFileReducer,
  deleteFileReducer,
  updateAssetThumbnailReducer,
  downloadMultipleFilesReducer,
  downloadFileReducer,
  setCheckedFilesReducer,
  updateSelectAllReducer,
  setFileDrawers,
  resetFileStoreReducer,
} = fileSlice.actions
export default fileSlice.reducer
