import { FileView, TaxonomyAssetCountsResponse, TaxonomyNodeView, TaxonomyTree } from 'gallerydigitalassets-v1-client'
import { IAsset } from 'store'

export interface ITaxonomyMethods {
  getImages: (id: string) => void
  resetCategoryAssets: () => void
  getCategory: (name: string, includeParents?: boolean) => Promise<TaxonomyNodeView | TaxonomyNodeView[] | undefined>
  getCategoryById: (id: string, includeParents?: boolean) => Promise<TaxonomyNodeView | TaxonomyNodeView[] | undefined>
  getCarouselItems: (taxonomyId: string) => void
  resetCarouselItems: () => void
  taxonomies: TaxonomyNodeView[]
  isLoading: boolean
  subcategoryPageAssets: ISubcategoryPageAssets
  assetsAreLoading: boolean
  carouselItems: TaxonomyAssetCountsResponse[]
  tabs: string[]
  getTaxonomyNodeById: (taxonomies: TaxonomyNodeView[], taxonomyId: String) => TaxonomyNodeView
}

export interface ICategoryAssetArgs {
  type: string
  action: {
    //Taxonomy ID
    payload: string
  }
}

export interface ITaxonomy {
  id: string
  name: string
  isLoading: boolean
  children?: TaxonomyNodeView[]
  imageFiles?: FileView[]
}

export enum ImageStatus {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}

export interface ITaxonomyState extends TaxonomyTree {
  swatchGenerationTaxonomyIds: string[]
  isLoading: boolean
  currentCategory: ICategoryAssets
  subcategoryPageAssets: ISubcategoryPageAssets
  carouselItems: TaxonomyAssetCountsResponse[]
}

export interface ICategoryAssets {
  assets: ICategorizedAssets[]
  status: ImageStatus
  id: string
}

export interface ICategorizedAssets {
  id?: string
  imageFiles: FileView[]
}

export interface ISubcategoryPageAssets {
  page: number
  assets: IAsset[]
  total: number
  paginationIsLoading: boolean
}
