import { Grid, Spinner, Card, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { some, xor } from 'lodash'
import AssetResult from 'components/feeds/AssetFeed/AssetResult'
import InfiniteScroller from 'components/shared/InfiniteScroller'
import React, { useState, useEffect } from 'react'
import styles from './AssetFeed.module.scss'
import SearchPageZeroState from 'components/feeds/AssetFeed/SearchPageZeroState'
import CollectionDetailZeroState from 'components/feeds/AssetFeed/CollectionDetailZeroState'
import SubcategoryZeroState from 'components/feeds/AssetFeed/SubcategoryZeroState'
import { useSearch, IAsset, ZeroStateToShowEnum } from 'store'
import AssetFeedActions from './AssetFeedActions'

type AssetFeedProps = {
  isLoading: boolean
  assets?: IAsset[]
  totalCount?: number
  zeroStateToShow: ZeroStateToShowEnum
  infiniteScrollMethod?: () => void
  showAssetsLabel?: boolean
  pageName: string
  showResultsLabel?: boolean
  disableInfinite?: boolean
}

const AssetFeed: React.FC<AssetFeedProps> = ({
  isLoading,
  assets,
  totalCount,
  infiniteScrollMethod,
  showAssetsLabel,
  zeroStateToShow,
  pageName,
  showResultsLabel,
  disableInfinite = false,
}) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const { isHomePage, searchCriteria } = useSearch()
  const [currentName, setName] = useState<string>(pageName)

  useEffect(() => {
    if (isLoading) {
      if (currentName !== pageName) {
        setCheckedItems([])
        setName(pageName)
      }
    }
  }, [isLoading])

  useEffect(() => {
    return () => {
      // Clear checked items on unmount.
      setCheckedItems([])
    }
  }, [searchCriteria.q])

  const zeroStateView = () => {
    let zeroState
    switch (zeroStateToShow) {
      case ZeroStateToShowEnum.COLLECTIONDETAILPAGE:
        zeroState = <CollectionDetailZeroState />
        break
      case ZeroStateToShowEnum.SEARCHPAGE:
        zeroState = <SearchPageZeroState />
        break
      case ZeroStateToShowEnum.SUBCATEGORYPAGE:
        zeroState = <SubcategoryZeroState />
        break
    }
    return zeroState
  }

  if (!assets) {
    return (
      <Grid.Container justify="center" className="hc-pt-xl">
        <Spinner data-test-id="load-indicator" />
      </Grid.Container>
    )
  }

  return (
    <Grid.Container
      className={`${styles['centered-content']} ${styles['without-filters']} ${styles['heading']} hc-mh-expanded`}
      direction="column"
      justify="space-around"
      noWrap={false}
    >
      {/* HEADER SECTION FOR RESULTS HEADER AND FEED ACTIONS */}
      {!!totalCount && assets.length > 0 && (
        <AssetFeedActions
          assets={assets}
          totalCount={totalCount}
          zeroStateToShow={zeroStateToShow}
          showAssetsLabel={showAssetsLabel}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          showResultsLabel={showResultsLabel}
        />
      )}

      {/* If assets object is empty, show spinner or zero state depending on loading state */}
      {!some(assets) && !isLoading && zeroStateView()}
      {!some(assets) && !!isLoading && (
        <Grid.Item xs={12}>
          <Grid.Container justify="center">
            <span data-test-id="assets-loading">
              <Spinner data-test-id="load-indicator" />
            </span>
          </Grid.Container>
        </Grid.Item>
      )}
      {/* if assets object is not empty, render assets, and infinite scroll card if isLoading */}
      {some(assets) && (
        <Grid.Container>
          {assets.map((asset: IAsset, index: number) => (
            <AssetResult
              key={`${index}-asset-result`}
              asset={asset}
              checkedItems={checkedItems}
              isCheckable={true}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault()
                e.stopPropagation()
                setCheckedItems(xor(checkedItems, [asset.id]))
              }}
            />
          ))}
          {/* Infinite Scroller representation on loading state */}
          {!!totalCount && totalCount > assets.length && !!isLoading && (
            <>
              {Array.from(Array(5).keys()).map((d, j) => {
                return (
                  <Grid.Item
                    key={`${j}-LOADER`}
                    className={`${styles.asset} hc-pv-md hc-ph-normal`}
                    data-test-id="asset-result"
                  >
                    <Card elevation={1} className={styles.result}>
                      <Grid.Container align="center" justify="center">
                        <Grid.Item className={styles['placeholder-item']}>
                          <Placeholder className={styles.placeholder} data-test-id="load-indicator">
                            <Placeholder.Rect height="200px" />
                            <Placeholder.Text height="20px" />
                          </Placeholder>
                        </Grid.Item>
                      </Grid.Container>
                    </Card>
                  </Grid.Item>
                )
              })}
            </>
          )}
        </Grid.Container>
      )}
      {!!totalCount && totalCount > assets.length && !isLoading && !!infiniteScrollMethod && !disableInfinite && (
        <InfiniteScroller nextPageCallback={infiniteScrollMethod} infiniteScrollerOffset={600} />
      )}
      {isHomePage && !isLoading && (
        <Grid.Container className={`${styles['bottom-logo']} hc-mt-2x hc-mb-4x`} padding="none" />
      )}
    </Grid.Container>
  )
}
export default AssetFeed
