import React from 'react'
import { Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { FileView } from 'gallerydigitalassets-v1-client'
import GalleryCard from 'components/cards/GalleryCard'
import { Link } from 'react-router-dom'
import useLink from 'hooks/useLink'
import './SubCategoryResult.scss'

// example data model for subCategory data
export interface ISubcategory {
  taxonomyId: string
  name: string
  imageFiles: FileView[]
}

type SubCategoryResultProps = {
  subCategory: ISubcategory
}

const SubCategoryDetails: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div className="subCategoryDetails hc-pt-dense hc-ph-min">
      <Grid.Container justify="space-between" className="hc-ph-dense">
        <Heading
          data-testid="subcategory-name"
          className="collectionName hc-to-ellipsis hc-ws-no-wrap hc-ov-hidden"
          size={5}
        >
          {name}
        </Heading>
        <span style={{ textDecoration: 'none', color: 'rgb(51,102,187)' }} className="empty-collection-links">
          View all
        </span>
      </Grid.Container>
    </div>
  )
}

const SubCategoryResult: React.FC<SubCategoryResultProps> = ({ subCategory }) => {
  const { taxonomyId, name, imageFiles } = subCategory
  const { linkEventListner } = useLink()
  return (
    <Grid.Item className="hc-mb-none subCategory-result">
      <Link
        to={{ pathname: `/subcategory/${name.toLowerCase()}` }}
        onClick={() =>
          linkEventListner('CardLink', `View All clicked on ${name} SubCategory Card`, 'Category Feed Card Link')
        }
        data-test-id="public-collections-view"
      >
        <GalleryCard
          imageFiles={imageFiles}
          id={taxonomyId}
          emptyText={
            <p className="emptyCollection hc-fs-lg hc-clr-grey02 hc-ta-center">
              CATEGORY <br /> MISSING THUMBNAILS
            </p>
          }
        >
          <SubCategoryDetails name={name} />
        </GalleryCard>
      </Link>
    </Grid.Item>
  )
}

export default SubCategoryResult
