import { all, takeLatest, call, put } from 'redux-saga/effects'
import { STORAGE_KEYS } from 'config/constants'
import jwt from 'jsonwebtoken'
import { getUserByLanId } from './slice'
import { getByLanId, processError, setUserDetails } from 'store'
import { UserView } from 'gallerydigitalassets-v1-client'

function* getByLanIdSaga(action: any) {
  const accessToken: string = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || ''
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    const decodedAccessToken = jwt.decode(accessToken) as any
    const userInfo: UserView = yield call(getByLanId, xTgtLanId, decodedAccessToken?.username as string)
    yield put(setUserDetails(userInfo))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export default function* watchUserSagas() {
  yield all([takeLatest(getUserByLanId.type, getByLanIdSaga)])
}
