import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { arrayEquals } from 'utils/array'
import { useSelector, useDispatch } from 'react-redux'
import { addCrumb, BreadCrumbPriority, cleanCrumb, IBreadCrumb, replaceCrumbById, rerouteCrumb } from 'store'
import { removeCrumbById } from './crumbsSlice'
import { isEmpty } from 'lodash'
import { BREAD_CRUMB_VARS } from 'config'
import { crumbsSelector } from './selectors'

export interface IBreadCrumbHook {
  purgeLPCrumbs: (priority: BreadCrumbPriority) => void
  cleanBreadCrumbs: () => void
  addCrumbs: (crumbs: IBreadCrumb[]) => void
  removeCrumb: (name: string) => void
  removeById: (id: string) => void
  replaceById: (crumb: IBreadCrumb) => void
  reroute: (crumbs: IBreadCrumb[]) => void
  collectionCrumbs: (crumb: IBreadCrumb) => void
  crumbsFromSlice: IBreadCrumb[]
}

export const useBreadCrumbs = (crumbs?: IBreadCrumb): IBreadCrumbHook => {
  const { GROUPED_COLLECTIONS, COLLECTIONS, COLLECTION_ROUTE } = BREAD_CRUMB_VARS
  const location = useLocation()

  const crumbsFromSlice = useSelector(crumbsSelector)
  const dispatch = useDispatch()

  // Gets rid of any crumbs that are lower priority than the one being added.
  const purgeLPCrumbs = (priority: BreadCrumbPriority) => {
    const filteredCrumbs = crumbsFromSlice?.filter((crumb: IBreadCrumb) => {
      return crumb.priority < priority
    })

    dispatch(rerouteCrumb(filteredCrumbs))
  }

  // Clean crumbs
  const cleanBreadCrumbs = () => {
    dispatch(cleanCrumb())
  }

  const addCrumbs = (crumbs: IBreadCrumb[]) => {
    // Check if a crumb exists in state already
    let finalCrumbs: IBreadCrumb[] = crumbs.filter(crumb => {
      const duplicate = crumbsFromSlice.find(sliceCrumb => sliceCrumb.name === crumb.name)
      return duplicate ? false : true
    })

    finalCrumbs.forEach(crumb => {
      //Remove any like Ids
      if (crumb.id) {
        removeById(crumb.id!)
      }
    })

    dispatch(addCrumb(finalCrumbs))
  }

  const removeCrumb = (name: string) => {
    dispatch(removeCrumb(name))
  }

  const removeById = (id: string) => {
    dispatch(removeCrumbById(id))
  }

  const replaceById = (crumb: IBreadCrumb) => {
    dispatch(replaceCrumbById(crumb))
  }

  const reroute = (newCrumbs: IBreadCrumb[]) => {
    dispatch(rerouteCrumb(newCrumbs))
  }

  const collectionCrumbs = (pageCrumb: IBreadCrumb) => {
    if (!crumbsFromSlice.find(crumb => crumb.name === COLLECTIONS)) {
      const collectionsCrumb = { name: COLLECTIONS, href: COLLECTION_ROUTE, priority: BreadCrumbPriority.High }
      purgeLPCrumbs(collectionsCrumb.priority)
      addCrumbs([collectionsCrumb, pageCrumb])
    } else if (pageCrumb.id === GROUPED_COLLECTIONS) {
      purgeLPCrumbs(pageCrumb.priority)
      removeById(GROUPED_COLLECTIONS)
      addCrumbs([pageCrumb])
    } else {
      addCrumbs([pageCrumb])
    }
  }

  const addNewCrumb = (newCrumb: IBreadCrumb) => {
    switch (newCrumb.priority) {
      case BreadCrumbPriority.High:
      case BreadCrumbPriority.Medium: {
        // There should only be one medium and one high priority breadcrumb at a time.
        purgeLPCrumbs(newCrumb.priority)
      }
    }

    // Finally, add the new crumb (if it has a name)
    addCrumbs([newCrumb])
  }

  const reduceCrumbs = () => {
    // Find the crumb in state. Get it's index.
    const crumbIndex = crumbsFromSlice?.findIndex(crumb => crumbs!.name === crumb.name)

    // Keep everything from first crumb to the one we found.
    const newCrumbs = crumbsFromSlice?.slice(0, crumbIndex! + 1)

    // Ensure we're actually looking at a new page instead of just seeing a component rerender.
    const samePage = arrayEquals(newCrumbs as Array<any>, crumbsFromSlice as Array<any>)

    // Reroot the crumbs if we see a difference.
    if (!samePage) {
      dispatch(rerouteCrumb(newCrumbs))
    }
  }

  useEffect(() => {
    // This logic is only used for when crumbs are passed in.
    if (!crumbs) return

    const existingCrumb: IBreadCrumb | undefined = crumbsFromSlice?.find(
      (crumb: IBreadCrumb) => crumbs!.name === crumb.name
    )

    if (!existingCrumb) {
      const newCrumb = { ...crumbs!, ...{ href: location.pathname } }

      // Finally, add the new crumb (if it has a name)
      if (newCrumb.name && !isEmpty(newCrumb)) addNewCrumb(newCrumb)
    } else {
      // Remove any crumbs up until the one we need and do any adjustments necessary for prioritizing crumbs.
      reduceCrumbs()
    }

    if (location.pathname === '/' && crumbsFromSlice.length > 1) dispatch(cleanCrumb())
  }, [crumbs])

  return {
    addCrumbs,
    cleanBreadCrumbs,
    collectionCrumbs,
    crumbsFromSlice,
    purgeLPCrumbs,
    removeCrumb,
    removeById,
    replaceById,
    reroute,
  }
}
