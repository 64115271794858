import { Grid } from '@enterprise-ui/canvas-ui-react'
import AsyncSelect from 'react-select/async'
import { ActionMeta, components, MultiValue } from 'react-select'
import { useEffect, useState } from 'react'
import { IUser, IPotentialManagerOption, useCollections } from 'store'
import { some } from 'lodash'
import { PermissionView } from 'gallerydigitalassets-v1-client'
import { convertToProperCase, includesCaseInsensitive } from 'utils/string'
import { useEnv } from '@praxis/component-runtime-env'
import './AddManagersSelect.scss'

type AddManagersContentProps = {
  placeholderText: string
  setManagers: React.Dispatch<React.SetStateAction<string[]>>
}

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <Grid.Container direction="row" spacing="none">
        <Grid.Item className="hc-fs-xs hc-clr-black hc-pr-min">{props.data.label}</Grid.Item>
        <Grid.Item className="hc-fs-xs hc-clr-grey01">{props.data.subLabel}</Grid.Item>
      </Grid.Container>
    </components.Option>
  )
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 40,
    borderStyle: 'solid',
    borderColor: 'black',
  }),
  placeholder: (base: any) => ({
    ...base,
    fontColor: '#666666',
    fontSize: '12px',
    textAlign: 'left',
  }),
  noOptionsMessage: (base: any) => ({
    ...base,
    fontColor: '#666666',
    textAlign: 'center',
    fontSize: '12px',
  }),
}

const AddManagersSelect: React.FC<AddManagersContentProps> = ({ placeholderText, setManagers }) => {
  const [options, setOptions] = useState<IPotentialManagerOption[]>([])
  const [potentialCollectionManagers, setPotentialCollectionManagers] = useState<IUser[]>([])
  const { getGalleryUsers, collectionUsers, resetCollectionUsers } = useCollections()
  const { featuredCollectionId } = useEnv()

  useEffect(() => {
    // get possible managers for featured public collection
    getGalleryUsers(featuredCollectionId, 'EDIT')
    // resets collection users so old collection users wont show on new page
    return () => {
      resetCollectionUsers()
    }
  }, [])

  useEffect(() => {
    if (some(collectionUsers)) {
      let collectionUserData = [...collectionUsers]
      collectionUserData = collectionUserData.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.toLowerCase() < `${b.firstName} ${b.lastName}`.toLowerCase() ? 1 : -1
      )
      setPotentialCollectionManagers(
        collectionUserData.filter(
          (user: IUser) =>
            user.access!! !== PermissionView.AccessEnum.GalleryCreator &&
            user.access!! !== PermissionView.AccessEnum.GalleryManager
        )
      )
    }
  }, [collectionUsers])

  useEffect(() => {
    if (some(potentialCollectionManagers)) {
      const options: IPotentialManagerOption[] = potentialCollectionManagers.map(
        (user: IUser): IPotentialManagerOption => {
          return {
            value: user.id,
            label: `${convertToProperCase(user.firstName)} ${convertToProperCase(user.lastName)}`,
            subLabel: user.mail,
          }
        }
      )
      setOptions(options.sort((a, b) => (a.label > b.label ? 1 : -1)))
    }
  }, [potentialCollectionManagers])

  const handleInputChange = (newValue: string) => {
    const searchTerm = newValue.replace(/\W/g, '')
    return searchTerm
  }

  const handleChange = (value: MultiValue<IPotentialManagerOption>, action: ActionMeta<any>) => {
    if (value !== undefined && value.length > 0) {
      const usersToBeAdded = value.map((option: IPotentialManagerOption) => option.value)
      setManagers(usersToBeAdded)
    }
  }

  let loadOptions = (searchTerm: string, callback: (options: any) => void) => {
    setTimeout(() => {
      callback(filterOptions(searchTerm))
    }, 1000)
  }

  let filterOptions = (searchTerm: string) => {
    if (searchTerm === undefined || searchTerm === '') {
      return options
    }
    return options.filter((option: IPotentialManagerOption) => includesCaseInsensitive(option.label, searchTerm))
  }

  return (
    <div data-testid="collection-managers-select-component">
      <AsyncSelect
        className="add-managers-select"
        isMulti={true}
        key="AddManagersToCollection"
        noOptionsMessage={() => 'No users found'}
        loadOptions={loadOptions}
        defaultOptions={true}
        onChange={handleChange}
        onInputChange={handleInputChange}
        isClearable={true}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Option,
        }}
        placeholder={placeholderText}
        styles={customStyles}
        name="addUser"
        inputId="addUser"
      />
    </div>
  )
}

export default AddManagersSelect
