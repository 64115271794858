import React, { useState } from 'react'
import { Chip, Grid, Heading, Spinner } from '@enterprise-ui/canvas-ui-react'
import { CollectionSet, IFeedCollection, useAuth, useCollections } from 'store'
import dayjs from 'dayjs'
import styles from './CollectionResult.module.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import HistoricalLink from 'components/shared/HistoricalLink'
import { useCollectionThumbs } from 'hooks'
import GalleryCard from 'components/cards/GalleryCard'
import EnterpriseIcon, { DownloadIcon, HeartFilledIcon, HeartIcon } from '@enterprise-ui/icons'
import ReactGA from 'react-ga'

type CollectionResultProps = {
  collection: IFeedCollection
  collectionSet: CollectionSet
}

type ChipProps = {
  collectionId: string
  isFavorite: boolean
  collectionSet: CollectionSet
}

export const CollectionChip: React.FC<ChipProps> = ({ collectionId, isFavorite, collectionSet }) => {
  const [downloadIcon, setDownloadIcon] = useState<IconProp>('download')
  const [favoriteIcon, setFavoriteIcon] = useState<IconProp>('heart')
  const { downloadGallery, favoriteGallery, unFavoriteGallery } = useCollections()

  return (
    <Grid.Container spacing={'none'} className={'chip-container'}>
      <Grid.Item className={'download-chip hc-pr-min'}>
        <Chip
          size="expanded"
          role="button"
          className={'hc-pa-none hc-bg-grey05'}
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
            e.preventDefault()
            ReactGA.event({
              category: 'Collection',
              action: 'Download via Thumbnail',
              label: collectionId,
            })
            setDownloadIcon('spinner')
            downloadGallery(collectionId)
            setTimeout(() => setDownloadIcon('download'), 5000)
          }}
        >
          {downloadIcon === 'spinner' && (
            <Spinner
              size="inline"
              className={'download-spinner hc-pa-xs hc-ml-xs'}
              data-testid={'download-spinner-icon'}
            />
          )}
          {downloadIcon === 'download' && (
            <EnterpriseIcon
              icon={DownloadIcon}
              size={'md'}
              className={'icon download-icon hc-mt-min hc-mr-min'}
              data-testid="download-icon"
            />
          )}
        </Chip>
      </Grid.Item>
      <Grid.Item className={'heart-chip hc-pl-min'}>
        <Chip
          size="expanded"
          role="button"
          className={'hc-pa-none hc-bg-grey05'}
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
            e.preventDefault()
            ReactGA.event({
              category: 'Collection',
              action: 'Add to Collections favorites',
              label: collectionId,
            })
            setFavoriteIcon('spinner')
            isFavorite ? unFavoriteGallery(collectionId, collectionSet) : favoriteGallery(collectionId, collectionSet)
            setTimeout(() => {
              setFavoriteIcon('heart')
            }, 3000)
          }}
        >
          {favoriteIcon === 'spinner' && (
            <Spinner
              size="inline"
              className={'download-spinner hc-pa-xs hc-ml-xs'}
              data-testid={'heart-spinner-icon'}
            />
          )}
          {favoriteIcon === 'heart' && (
            <EnterpriseIcon
              icon={isFavorite ? HeartFilledIcon : HeartIcon}
              size={'md'}
              className={'icon heart-icon hc-mt-min hc-mr-min'}
              aria-hidden={isFavorite}
              data-testid={`${isFavorite ? 'heart-filled' : 'heart'}-icon`}
            />
          )}
        </Chip>
      </Grid.Item>
    </Grid.Container>
  )
}

const CollectionDetails: React.FC<{
  name: string
  byLine: string
  createdAt: number
  description?: string
}> = ({ name, byLine, createdAt, description }) => {
  return (
    <div className={`${styles.collectionDetails} hc-pt-none hc-ph-min`}>
      <Heading className="collectionName hc-to-ellipsis hc-ws-no-wrap hc-ov-hidden" size={5}>
        {name}
      </Heading>
      <p data-testid="test-byLine" className="created hc-clr-grey03 hc-fs-xs hc-mb-none">
        <span>
          Created by {byLine} on {dayjs(createdAt).format('MM/DD/YY')}
        </span>
      </p>

      {description && (
        <p className="hc-fs-xs hc-to-ellipsis hc-ws-no-wrap hc-ov-hidden">
          <span>{description}</span>
        </p>
      )}
    </div>
  )
}

const CollectionResult: React.FC<CollectionResultProps> = ({ collection, collectionSet }) => {
  const { collectionThumbs } = useCollectionThumbs()
  const { isUserFavorite } = useAuth()
  const { id, createdAt, name, description, favoriteUsers } = collection
  const isFavorite = isUserFavorite(favoriteUsers)
  const { imageFiles } = collectionThumbs(collection)
  const byLine = `${collection.creatorFirstName} ${collection.creatorLastName}`
  //galleryUsers.map((user: ICollectionUser) => `${user.user.firstName} ${user.user.lastName}`).join(', ')

  return (
    <Grid.Item className="hc-mb-none collectionResult">
      <HistoricalLink pathname={`/collection/${id}`}>
        <GalleryCard
          imageFiles={imageFiles}
          chip={<CollectionChip collectionId={id} isFavorite={isFavorite} collectionSet={collectionSet} />}
          id={id}
          emptyText={
            collection.galleryEmpty ? (
              <p className="emptyCollection hc-fs-lg hc-clr-grey02 hc-ta-center">
                EMPTY <br /> COLLECTION
              </p>
            ) : (
              <p className="emptyCollection hc-fs-lg hc-clr-grey02 hc-ta-center">
                COLLECTION HAS <br /> NO THUMBNAILS
              </p>
            )
          }
        >
          <CollectionDetails name={name} byLine={byLine} description={description} createdAt={createdAt} />
        </GalleryCard>
      </HistoricalLink>
    </Grid.Item>
  )
}

export default CollectionResult
