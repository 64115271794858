import { Grid, Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import { CollectionSort } from 'store'
import React, { useState } from 'react'
import './CollectionSearchBar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EnterpriseIcon, { SearchIcon } from '@enterprise-ui/icons'

type SearchBarContentProps = {
  placeholderText: string
  setCollectionSort: React.Dispatch<React.SetStateAction<CollectionSort>>
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  className?: string
}

const CollectionSearchBar: React.FC<SearchBarContentProps> = ({
  placeholderText,
  setCollectionSort,
  setSearchQuery,
  className = 'search',
}) => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('')

  const [sortNewest, setSortNewest] = useState<boolean>(true)
  const [sortOldest, setSortOldest] = useState<boolean>(false)
  const [sortAlphaAZ, setSortAlphaAZ] = useState<boolean>(false)
  const [sortAlphaZA, setSortAlphaZA] = useState<boolean>(false)
  const submitSearchResults = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    setSearchQuery(currentSearchTerm)
  }

  const handleChange = (event: any) => {
    setCurrentSearchTerm(event.target.value)
  }

  return (
    <Grid.Container size={6}>
      <Grid.Item xs={12} className={'hc-pr-none'}>
        <Grid.Container justify="flex-start" spacing={'none'}>
          <Grid.Item className={'hc-pr-none'}>
            <Dropdown location="bottom-right" size="normal" key="collectionsearch-dropdown">
              <Button
                iconOnly
                key="collectionsearch-icon"
                style={{ border: 'none' }}
                data-testid="collectionsearch-sort-icon-testid"
              >
                <FontAwesomeIcon icon="exchange-alt" size="1x" color="grey" className="fa-rotate-90" />
              </Button>
              <Dropdown.Menu data-testid="collectionsearch-dropdown-menu" style={{ zIndex: 100 }}>
                <Dropdown.MenuItem
                  onClick={(e: Event) => {
                    e.preventDefault()
                    setSortNewest(true)
                    setSortOldest(false)
                    setSortAlphaAZ(false)
                    setSortAlphaZA(false)
                    setSearchQuery(currentSearchTerm)
                    setCollectionSort(CollectionSort.LATEST_DESC)
                  }}
                  data-testid="collectionsearch-menu-newest"
                >
                  {sortNewest && <FontAwesomeIcon icon="check" style={{ marginRight: 10 }} color="grey" />}
                  Newest (Created Date)
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onClick={(e: Event) => {
                    e.preventDefault()
                    setSortNewest(false)
                    setSortOldest(true)
                    setSortAlphaAZ(false)
                    setSortAlphaZA(false)
                    setSearchQuery(currentSearchTerm)
                    setCollectionSort(CollectionSort.LATEST_ASC)
                  }}
                  data-testid="collectionsearch-menu-oldest"
                >
                  {sortOldest && <FontAwesomeIcon icon="check" style={{ marginRight: 10 }} color="grey" />}
                  Oldest (Created Date)
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onClick={(e: Event) => {
                    e.preventDefault()
                    setSortNewest(false)
                    setSortOldest(false)
                    setSortAlphaAZ(true)
                    setSortAlphaZA(false)
                    setSearchQuery(currentSearchTerm)
                    setCollectionSort(CollectionSort.NAME_ASC)
                  }}
                  data-testid="collectionsearch-menu-alpha-asc"
                >
                  {sortAlphaAZ && <FontAwesomeIcon icon="check" style={{ marginRight: 10 }} color="grey" />}
                  Alphabetical Order (A to Z)
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onClick={(e: Event) => {
                    e.preventDefault()
                    setSortNewest(false)
                    setSortOldest(false)
                    setSortAlphaAZ(false)
                    setSortAlphaZA(true)
                    setSearchQuery(currentSearchTerm)
                    setCollectionSort(CollectionSort.NAME_DESC)
                  }}
                  data-testid="collectionsearch-menu-alpha-desc"
                >
                  {sortAlphaZA && <FontAwesomeIcon icon="check" style={{ marginRight: 10 }} color="grey" />}
                  Alphabetical Order (Z to A)
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
          <Grid.Item className={'hc-pl-min'} xs={11}>
            <span className="searchInput">
              <form onSubmit={e => submitSearchResults(e)}>
                <div
                  className={currentSearchTerm ? 'search-icon' : 'search-icon  disable-search'}
                  data-testid="search-icon"
                  onClick={e => submitSearchResults(e)}
                >
                  <EnterpriseIcon icon={SearchIcon} />
                </div>
                <input
                  type="text"
                  data-testid="collections-search-input"
                  placeholder={placeholderText}
                  value={currentSearchTerm}
                  className={className}
                  autoFocus
                  onChange={e => handleChange(e)}
                />
              </form>
            </span>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
    </Grid.Container>
  )
}

export default CollectionSearchBar
