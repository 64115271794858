import React from 'react'

import { Modal, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { IDialog, useDialog } from 'store'
import './ConfirmDialog.scss'

const ConfirmDialog = ({ dialogData: data, setDialogData }: IDialog): any => {
  const { rejectDialog, acceptDialog } = useDialog()
  const { headerText, message, refuseButtonText, approveButtonText } = data

  const closeModal = () =>
    setDialogData({ headerText: '', message: '', refuseButtonText: '', approveButtonText: '', isVisible: false })

  const accept = () => {
    acceptDialog()
    closeModal()
  }
  const reject = () => {
    rejectDialog()
    closeModal()
  }

  return (
    <Modal
      className="confirm-dialog"
      isVisible={data.isVisible}
      approveButtonText={`${approveButtonText ? approveButtonText : 'Yes'}`}
      onApproveModal={() => {
        accept()
      }}
      onRefuse={() => {
        reject()
      }}
      refuseButtonText={`${refuseButtonText ? refuseButtonText : 'Cancel'}`}
    >
      <div>
        <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">{headerText}</h1>
      </div>
      <div className="hc-pa-normal">
        <Grid.Container className="hc-pa-normal">
          <Grid.Item xs>
            <p>{message}</p>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container direction="row-reverse" spacing="dense">
          <Grid.Item>
            <Button type="primary" onClick={() => accept()}>
              {approveButtonText}
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button type="secondary" onClick={() => reject()}>
              {refuseButtonText}
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default ConfirmDialog
