import React, { useState, useRef } from 'react'
import { Button, Grid, Image } from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styles from './Carousel.module.scss'
import useElementSize from 'hooks/useElementSize'
import { TaxonomyAssetCountsResponse } from 'gallerydigitalassets-v1-client'
import { useCarousel, CarouselItemProps } from 'hooks'
import { numberWithCommas } from 'utils/number'

type CarouselProps = {
  subCategories: TaxonomyAssetCountsResponse[]
}

const CarouselItem: React.FC<CarouselItemProps> = ({ imageUrl, name, assetCount }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <Grid.Item
      data-testid={`subCategory-${name}`}
      className={`${styles['carousel-item']} hc-pa-dense`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {assetCount > 0 ? (
        <Link to={`/subcategory/${name?.toLowerCase()}`}>
          <Image
            alt={`Carousel image ${name}`}
            data-testid={`carousel-image-${name}`}
            className={`hc-ma-none ${styles['carousel-image']}`}
            src={imageUrl}
            height="80px"
            width="160px"
            type="cover"
          >
            <div
              data-testid="active-link"
              className={`${styles['carousel-image-description']} hc-ta-center C-Mode--dark`}
            >
              <h4 data-testid="group-name" className={'hc-ma-none hc-clr-contrast'}>
                {name}
              </h4>
              <p className={'hc-clr-contrast hc-fs-xs'}>{`${numberWithCommas(assetCount)} assets`}</p>
            </div>
            <div className={isHovered ? `${styles['layer']} ${styles['layer-hovered']}` : `${styles['layer']}`}></div>
          </Image>
        </Link>
      ) : (
        <Image
          alt={`Carousel image ${name}`}
          data-testid={`carousel-image-${name}`}
          className={`hc-ma-none ${styles['carousel-image']}`}
          src={imageUrl}
          height="80px"
          width="160px"
          type="cover"
        >
          <div
            data-testid="inactive-link"
            className={`${styles['carousel-image-description']} hc-ta-center`}
            style={{ cursor: 'default' }}
          >
            <h4 data-testid="group-name" className={'hc-ma-none hc-clr-white'}>
              {name}
            </h4>
            <p className={'hc-clr-white hc-fs-xs'}>{`0 assets`}</p>
          </div>
          <div className={isHovered ? `${styles['layer']} ${styles['layer-hovered']}` : `${styles['layer']}`}></div>
        </Image>
      )}
    </Grid.Item>
  )
}

const Carousel: React.FC<CarouselProps> = ({ subCategories }) => {
  const carouselRef = useRef(null)
  const { width: carouselWidth } = useElementSize(carouselRef)
  const { translation, minimumTranslation, pageLeft, pageRight, carouselItems } = useCarousel(
    subCategories,
    carouselWidth
  )

  return (
    <Grid.Container
      ref={carouselRef}
      data-testid="carousel-wrapper"
      className={`${styles['carousel-wrapper']} hc-pa-dense`}
    >
      {/* Only show left button if not at start of list */}
      {translation < 0 && (
        <div id="carousel-left">
          <Button
            className={`${styles['carousel-button']} ${styles['carousel-left']}`}
            data-testid="carousel-left-button"
            iconOnly
            size="dense"
            onClick={pageLeft}
          >
            <FontAwesomeIcon icon="angle-left" size="1x" />
          </Button>
        </div>
      )}
      <Grid.Container
        align="center"
        className={`${styles['carousel']}`}
        noWrap={true}
        style={{
          WebkitTransform: `translate3d(${translation}px, 0, 0)`,
          transform: `translate3d(${translation}px, 0, 0)`,
          transition: 'transform .5s',
          padding: `0px 0px`,
        }}
      >
        {carouselItems.map((item, id) => (
          <CarouselItem
            key={id}
            imageUrl={item!!.imageUrl}
            id={item?.id}
            name={item?.name!!}
            assetCount={item!!.assetCount}
          />
        ))}
      </Grid.Container>
      {/* Dont show button if container is wider than item list or if currently at end of list */}
      {minimumTranslation <= 0 && minimumTranslation !== translation && (
        <div id="carousel-right">
          <Button
            className={`${styles['carousel-button']} ${styles['carousel-right']}`}
            data-testid="carousel-right-button"
            iconOnly
            size="dense"
            onClick={pageRight}
          >
            <FontAwesomeIcon icon="angle-right" size="1x" />
          </Button>
        </div>
      )}
    </Grid.Container>
  )
}

export default Carousel
