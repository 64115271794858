import React, { useState, useEffect } from 'react'
import { Image, ImageProps } from '@enterprise-ui/canvas-ui-react'
import { FileView } from 'gallerydigitalassets-v1-client'
import { useFiles } from 'store'

type GalleryFileImageProps = {
  file: FileView
}

const GalleryFileImage: React.FC<GalleryFileImageProps & ImageProps> = ({ file, ...canvasImageProps }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('')
  const { getFileThumbnailUrl } = useFiles()
  useEffect(() => {
    // Adjust padding for image
    const assetImageUrl = getFileThumbnailUrl(file)
    const assetUrlObject = new URL(assetImageUrl)

    // This trims solid white borders that shouldn't be there.
    assetUrlObject.searchParams.set('trim-color', '255,255,255,0.5')

    // Adds padding to theimage itself through fastly.
    assetUrlObject.searchParams.set('pad', '0.15')
    setThumbnailUrl(assetUrlObject.toString())
  }, [file])

  return <Image {...canvasImageProps} src={thumbnailUrl} data-testid={file?.id} />
}

export default GalleryFileImage
