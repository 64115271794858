import { createSlice } from '@reduxjs/toolkit'

export enum BreadCrumbPriority {
  Home,
  High,
  Medium,
  Low,
}

export interface IBreadCrumb {
  id?: string
  name: string
  href: string
  search?: string
  priority: BreadCrumbPriority
}

export interface ILocationState {
  pathname?: string
  state?: {
    isPublic: Boolean
    isLinkedAsset: Boolean
  }
}

const initState: IBreadCrumb = {
  name: 'Home',
  href: '/',
  priority: BreadCrumbPriority.Home,
}

export const crumbsSlice = createSlice({
  name: 'crumbs',
  initialState: {
    value: [initState],
  },

  reducers: {
    addCrumb: (state, action) => {
      return { ...state, ...{ value: state.value.concat(action.payload!) } }
    },
    removeCrumbById(state, action) {
      state.value = state.value.filter(crumb => {
        return crumb.id && crumb.id === action.payload ? false : true
      })
    },

    replaceCrumbById(state, action) {
      return {
        ...state,
        ...{
          value: state.value.map((crumb: IBreadCrumb) => {
            return action.payload.id === crumb.id ? action.payload : crumb
          }),
        },
      }
    },
    removeCrumb: (state, action) => {
      return {
        ...state,
        ...{
          value: state.value.filter(crumb => {
            return crumb.name !== action.payload
          }),
        },
      }
    },
    cleanCrumb: state => {
      return { value: [initState] }
    },
    replaceCrumb: (state, action) => {
      return {
        ...state,
        ...{
          value: state.value.map((crumb: IBreadCrumb) => {
            return action.payload.href === crumb.href ? action.payload : crumb
          }),
        },
      }
    },
    rerouteCrumb: (state, action) => {
      return { ...state, ...{ value: action.payload! } }
    },
    clearLPCrumb: (state, action) => {
      return {
        ...state,
        ...{
          value: state.value.filter((crumb: IBreadCrumb) => {
            return action.payload!.priority > crumb.priority
          }),
        },
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addCrumb,
  removeCrumb,
  cleanCrumb,
  replaceCrumb,
  rerouteCrumb,
  clearLPCrumb,
  removeCrumbById,
  replaceCrumbById,
} = crumbsSlice.actions

export default crumbsSlice.reducer
