import { useDispatch, useSelector } from 'react-redux'
import { dialogDataSelector } from './selectors'
import { closeDialog, IDialogData, setDialogData, onAccept, onReject } from 'store'
export const useDialog = () => {
  const dispatch = useDispatch()
  const dialogData = useSelector(dialogDataSelector)

  const openDialog = (data: IDialogData) => {
    dispatch(setDialogData(data))
  }

  const exitDialog = () => {
    dispatch(closeDialog())
  }

  const acceptDialog = (acceptFun?: any) => {
    dispatch(onAccept(acceptFun))
  }

  const rejectDialog = (rejectFun?: any) => {
    dispatch(onReject(rejectFun))
  }

  return { openDialog, exitDialog, acceptDialog, rejectDialog, dialogData }
}
