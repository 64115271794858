import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Form, Card, Input, ExpandableSection, Divider, Grid, Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import { some } from 'lodash'
import { useEnv } from '@praxis/component-runtime-env'
import { MODAL_TYPES } from 'config/constants'
import { IDeleteModalData } from 'components/modals/DeleteModal/DeleteModal'
import './FileDrawer.scss'

import { FileViewUI, IIndeterminateStatus, setModalData, setModalType, useAsset, useFiles } from 'store'
import { useDispatch } from 'react-redux'
import { FileView } from 'gallerydigitalassets-v1-client'
import { globalDrawerState } from './FileTable'

type IFileDrawerProps = {
  files: FileViewUI[]
  title: string
  thumbnailFile: any
  allDrawersState: globalDrawerState
  setAllDrawersState: React.Dispatch<React.SetStateAction<globalDrawerState>>
}

const FileDrawer = (props: IFileDrawerProps) => {
  const { allDrawersState, setAllDrawersState, title, files, thumbnailFile } = props
  const { validThumbnailClassifications, readOnlyFileClassifications } = useEnv()
  const { deleteFile, updateAssetThumbnail, downloadFile, selectFile, bulkSelectFiles } = useFiles()
  const { assetDetailPageAsset: asset, userAssetPermissions } = useAsset()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerStatus, setDrawerStatus] = useState<IIndeterminateStatus>({
    status: 0,
    indeterminate: false,
    filesSelected: 0,
  })

  const dispatch = useDispatch()

  const paddingStyle = {
    padding: '6px',
  }

  const deleteAlertModal = (file: FileView) => {
    //// TODO: MAKE SURE THAT ASSET IS UPDATED AFTER FILE DELETE
    const triggerDelete = () => {
      deleteFile(asset!!.id, [file.id])
    }
    const modalData: IDeleteModalData = {
      data: file,
      triggerDelete,
      bodyText: `"${file.name}" will be completely removed from Gallery.`,
      onRefuseText: 'Cancel',
      onApproveText: 'Delete File',
      headingText: 'Delete this file?',
    }
    dispatch(setModalType(MODAL_TYPES.DELETE_FILE))
    dispatch(setModalData(modalData))
  }

  useEffect(() => {
    switch (allDrawersState) {
      case globalDrawerState.ALL_CLOSED:
        setDrawerOpen(false)
        break
      case globalDrawerState.ALL_OPEN:
        setDrawerOpen(true)
        break
    }
  }, [allDrawersState])

  useEffect(() => {
    if (
      (drawerOpen && allDrawersState === globalDrawerState.ALL_CLOSED) ||
      (!drawerOpen && allDrawersState === globalDrawerState.ALL_OPEN)
    ) {
      setAllDrawersState(globalDrawerState.INTERMEDIATE)
    }
  }, [drawerOpen])

  useEffect(() => {
    const filesSelected = files.filter(file => file.selected)
    const percentSelected = filesSelected.length / files.length
    const status = {
      status: percentSelected,
      indeterminate: percentSelected < 1 && percentSelected > 0,
      filesSelected: filesSelected.length,
    }
    setDrawerStatus(status)
  }, [files])

  return (
    <Card elevation={0} className="drawer">
      <div style={paddingStyle} className="hc-pa-normal filedrawer-expandable-container">
        <Grid.Container direction="column" spacing="dense">
          <ExpandableSection
            padding="dense"
            className="file-drawer"
            expanded={drawerOpen}
            onExpand={() => {
              setDrawerOpen(!drawerOpen)
            }}
          >
            <Grid.Item xs={12}>
              <Grid.Container spacing="dense">
                <Grid.Item>
                  <Input.Checkbox
                    key={`drawer-${title}`}
                    id={`drawer-${title}`}
                    data-testid={`drawer_checkbox_${title}`}
                    label={title}
                    className="drawer-checkbox"
                    checked={drawerStatus.status > 0}
                    indeterminate={drawerStatus.indeterminate}
                    onChange={() =>
                      bulkSelectFiles(
                        files.map((file: FileViewUI) => file.id),
                        drawerStatus.status === 1 ? false : true
                      )
                    }
                  />
                </Grid.Item>
                <Grid.Item className="drawer-avatar">
                  <span data-testid={`drawer_filecount_${title}`}>{files.length} files</span>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <ExpandableSection.Content>
              <Form>
                <Grid.Item xs={12}>
                  {some(files) && (
                    <Grid.Item xs={12}>
                      {files!!.map((file: any) => (
                        <div key={`drawer-record-fragment-${file.id}`} className="filedrawer-files-table">
                          <div className="drawer-expandable-section" key={`drawer-record-${file.id}`}>
                            <Grid.Container align="center" justify="space-between" noWrap={true}>
                              <Grid.Item lg={10} md={10} sm={10}>
                                <Input.Checkbox
                                  key={`drawer-file-${file.id}`}
                                  id={`${file.id}`}
                                  data-testid={`drawer_file_checkbox_${file.id}`}
                                  label={`${file.fileSources[0].fileName + '.' + file.fileSources[0].fileExtension}`}
                                  className="drawer-file-checkbox"
                                  checked={file.selected}
                                  onChange={() => {
                                    selectFile(file.id)
                                  }}
                                />
                              </Grid.Item>
                              <Grid.Item className="hc-mr-normal" lg={1}>
                                <Dropdown location="bottom-right" size="dense" key={`drawer-record-dp-${file.id}`}>
                                  <Button iconOnly key={`${file.id}`} style={{ border: 'none' }}>
                                    <FontAwesomeIcon icon="ellipsis-v" color="grey" />
                                  </Button>
                                  <Dropdown.Menu data-testid={`files_kabob_menu_${file.id}`} style={{ zIndex: 100 }}>
                                    <Dropdown.MenuItem
                                      onClick={(e: Event) => {
                                        e.preventDefault()
                                        downloadFile(file.fileSources[0].accessUrl)
                                      }}
                                      data-testid={`files_kabob_menu_download_${file.id}`}
                                    >
                                      Download
                                    </Dropdown.MenuItem>
                                    {validThumbnailClassifications.includes(file.classification) &&
                                      thumbnailFile?.id !== file.id &&
                                      userAssetPermissions.userCanEditAndDeleteAsset && (
                                        <Dropdown.MenuItem
                                          data-testid={`files_kabob_set_as_default_image_${file.id}`}
                                          onClick={(e: Event) => {
                                            e.preventDefault()
                                            updateAssetThumbnail(file.id, asset!!.id)
                                          }}
                                        >
                                          Set as default image
                                        </Dropdown.MenuItem>
                                      )}
                                    {userAssetPermissions.userCanEditAndDeleteAsset &&
                                      !readOnlyFileClassifications.includes(file.classification) &&
                                      thumbnailFile?.id !== file.id && (
                                        <Dropdown.MenuItem
                                          data-testid={`files_kabob_delete_${file.id}`}
                                          onClick={(e: Event) => {
                                            e.preventDefault()
                                            deleteAlertModal(file)
                                          }}
                                        >
                                          Delete
                                        </Dropdown.MenuItem>
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Grid.Item>
                            </Grid.Container>
                          </div>
                          <Divider />
                        </div>
                      ))}
                    </Grid.Item>
                  )}
                </Grid.Item>
              </Form>
            </ExpandableSection.Content>
          </ExpandableSection>
        </Grid.Container>
      </div>
    </Card>
  )
}

export default FileDrawer
