import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import {
  addToast,
  collectionPageSizeSelector,
  CollectionSet,
  favoriteCollectionsSelector,
  favoriteGalleryApi,
  getCollectionByIdAPI,
  getFavoriteCollectionsAPI,
  IAsset,
  IBreadCrumb,
  ICollectionUser,
  ownCollectionsSelector,
  PageStatus,
  processError,
  publicCollectionsSelector,
  unFavoriteGalleryApi,
} from 'store'
import {
  addAssetsToCollectionAPI,
  addGalleryUserApi,
  createGalleryAPI,
  deleteCollectionAPI,
  deleteGalleryAssetAPI,
  deleteGalleryUserApi,
  downloadGalleryAPI,
  editGalleryAPI,
  getAssetsByCollectionIdApi,
  getCollectionDetailsAPI,
  getGalleryUsersApi,
  getOwnCollectionsAPI,
  getPublicCollectionsAPI,
} from './api'
import {
  addAssetsToGalleryReducer,
  addGalleryUserReducer,
  appendCollectionAssetsState,
  createGalleryReducer,
  deleteAssetsToGalleryReducer,
  deleteCollectionReducer,
  deleteGalleryUserReducer,
  downloadGalleryReducer,
  editGalleryReducer,
  favoriteGalleryReducer,
  getCollectionAssetsState,
  getCollectionDetailsReducer,
  getFavoriteCollectionsFeed,
  getFeaturedCollection,
  getGalleryUsersReducer,
  getMyCollectionsReducer,
  getPublicCollectionsReducer,
  refreshFavoriteCollections,
  refreshMyCollectionsReducer,
  refreshPublicCollectionsReducer,
  setCollectionAssetsPaginationIsLoading,
  setCollectionAssetsState,
  setCollectionDetailsState,
  setCollectionDetailsStatus,
  setFeaturedCollectionAssets,
  setFeaturedCollectionsIsLoading,
  setGalleryUsersReducer,
  setLoadingReducer,
  setMyCollectionsIsLoading,
  setMyCollectionsReducer,
  setPublicCollectionsIsLoading,
  unFavoriteGalleryReducer,
  updateMyCollectionsReducer,
  updatePublicCollectionsReducer,
  setFavoriteCollectionsFeed,
  updateFavoriteCollections,
  setFavoriteCollectionsIsLoading,
} from './slice'
import { ICollection, ICollectionResult } from './types'
import { IUser } from 'store/user'
import { STORAGE_KEYS } from '../../config'
import { CollectionView } from 'gallerydigitalassets-v1-client'
import { ListResponseUUID } from '@galleryjs/api-digital-assets'
import { crumbsSelector } from 'store/crumbs/selectors'

// GET COLLECTION
function* getCollectionDetailsSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    // Call API end point for Collection Feed
    yield put(setLoadingReducer(true))
    yield put(setCollectionDetailsStatus(PageStatus.loading))
    const collectionView: CollectionView = yield call(getCollectionDetailsAPI, xTgtLanId, data.payload)
    const collection: ICollection = {
      createdAt: collectionView.createdAt as unknown as number,
      description: collectionView.description,
      galleryAssets: undefined,
      galleryUsers: collectionView.galleryUsers as unknown as ICollectionUser[],
      id: collectionView.id,
      name: collectionView.name,
      permissions: [],
      collectionOrganizations: collectionView.galleryOrganizations,
      updatedAt: collectionView.updatedAt as unknown as number,
      //new entries from search call
      creatorFirstName: '',
      creatorLanId: '',
      creatorLastName: '',
      orgs: [],
      thumbnailFileUrls: [],
      favoriteUsers: [],
    }
    yield put(setCollectionDetailsState(collection))
    yield put(setLoadingReducer(false))
    yield put(setCollectionDetailsStatus(PageStatus.success))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
    yield put(setLoadingReducer(false))
    yield put(setCollectionDetailsStatus(PageStatus.error))
  }
}

function* getFeaturedCollectionSaga(data: any) {
  try {
    const { id, page, size } = data.payload

    const xTgtLanId: string | null = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID) || null
    if (xTgtLanId) {
      yield put(setFeaturedCollectionsIsLoading(true))
      const collectionAssets: { page: number; size: number; totalAssets: number; assets: IAsset[] } = yield call(
        getAssetsByCollectionIdApi,
        xTgtLanId,
        id,
        page,
        size
      )
      yield put(setFeaturedCollectionAssets(collectionAssets))
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(setFeaturedCollectionsIsLoading(false))
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* getCollectionAssetsSaga(data: any) {
  try {
    const { galleryId, page, size } = data.payload
    const xTgtLanId: string | null = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID) || null
    if (xTgtLanId) {
      yield put(setCollectionAssetsPaginationIsLoading(true))
      const collectionAssets: { page: number; size: number; totalAssets: number; assets: IAsset[] } = yield call(
        getAssetsByCollectionIdApi,
        xTgtLanId,
        galleryId,
        page,
        size
      )
      if (!page) {
        yield put(setCollectionAssetsState(collectionAssets))
      } else {
        yield put(appendCollectionAssetsState(collectionAssets))
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* refreshCollectionSaga(data: any) {
  try {
    const { id } = data.payload
    const collectionPageSize: number = yield select(collectionPageSizeSelector)
    yield call(getCollectionDetailsSaga, { payload: id })
    yield call(getCollectionAssetsSaga, { payload: { galleryId: id, page: 0, size: collectionPageSize } })
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// GET FAVORITE COLLECTIONS
function* getFavoriteCollectionSaga(data: any) {
  const { searchServer, query, page, size, sort } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    yield put(setFavoriteCollectionsIsLoading(true))
    // Call API end point for Collection Feed
    const collections: ICollectionResult = yield call(
      getFavoriteCollectionsAPI,
      xTgtLanId,
      searchServer,
      page,
      size,
      sort,
      query
    )
    if (page === 0) {
      yield put(setFavoriteCollectionsFeed(collections))
    } else {
      yield put(updateFavoriteCollections(collections))
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(setFavoriteCollectionsIsLoading(false))
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// GET OWN COLLECTIONS
function* getOwnCollectionSaga(data: any) {
  const { searchServer, query, page, size, sort } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    // Call API end point for Collection Feed
    yield put(setMyCollectionsIsLoading(true))
    const collections: ICollectionResult = yield call(
      getOwnCollectionsAPI,
      xTgtLanId,
      searchServer,
      query,
      page,
      size,
      sort
    )
    if (page === 0) {
      yield put(setMyCollectionsReducer(collections))
    } else {
      yield put(updateMyCollectionsReducer(collections))
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(setPublicCollectionsIsLoading(false))
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// GET PUBLIC COLLECTIONS
function* getPublicCollectionSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const { searchServer, page, size, sort, query } = data.payload
    yield put(setPublicCollectionsIsLoading(true))
    const collections: ICollectionResult = yield call(
      getPublicCollectionsAPI,
      xTgtLanId,
      searchServer,
      page,
      size,
      sort,
      query
    )
    yield put(updatePublicCollectionsReducer(collections))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
      yield put(setPublicCollectionsIsLoading(false))
    } else {
      console.log(error)
    }
  }
}

// Add assets to Gallery
function* addAssetsToGallerySaga(data: any) {
  try {
    const { galleryId, assetIds, onSuccessCallBack } = data.payload
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(setLoadingReducer(true))
    yield call(addAssetsToCollectionAPI, xTgtLanId, galleryId, assetIds)
    if (onSuccessCallBack !== undefined) {
      yield call(onSuccessCallBack, galleryId)
    }
    yield put(addToast({ color: 'success', headingText: 'Success', message: 'Collection updated' }))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// Delete assets to Gallery
function* deleteAssetsToGallerySaga(data: any) {
  try {
    yield put(setLoadingReducer(true))
    const { galleryId, assetIds } = data.payload
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield call(deleteGalleryAssetAPI, xTgtLanId, assetIds, galleryId)
    yield call(refreshCollectionSaga, { payload: { id: galleryId } })
    yield put(
      addToast({
        color: 'success',
        headingText: 'Assets Deleted',
        message: `${data.payload.assetIds.length} asset(s) successfully removed from collection.`,
      })
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// Delete Gallery or Collection
function* deleteCollectionSaga(data: any) {
  try {
    const { id, name, pathname, navigate } = data.payload
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const breadCrumbs: IBreadCrumb[] = yield select(crumbsSelector)
    yield call(deleteCollectionAPI, xTgtLanId, id)
    yield put(
      addToast({
        color: 'success',
        headingText: 'Collection Deleted',
        message: `The ${name} collection has been successfully deleted.`,
      })
    )
    // Redirect to homepage if deeplinked to page, else go back to previous page
    if (!pathname) {
      yield call(navigate, breadCrumbs[breadCrumbs.length - 2].href)
    } else {
      yield call(navigate, -1)
    }
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// Delete assets to Gallery
function* downloadGallerySaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(setLoadingReducer(true))
    yield call(downloadGalleryAPI, xTgtLanId, data.payload.galleryId)
    yield put(setLoadingReducer(false))
    yield put(
      addToast({
        color: 'success',
        headingText: 'Download Collection',
        message: 'A download link will be emailed to you shortly.',
      })
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// Create Gallery
function* createGallerySaga(data: any) {
  try {
    yield put(setLoadingReducer(true))
    const { name, description, make_asset_public, asset_ids, managers, makeCollectionFav, onSuccessCallBack } =
      data.payload
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const collectionId: string = yield call(
      createGalleryAPI,
      xTgtLanId,
      name,
      description,
      make_asset_public,
      asset_ids,
      managers,
      makeCollectionFav
    )
    if (onSuccessCallBack !== undefined) {
      yield call(onSuccessCallBack, collectionId)
    }
    yield put(
      addToast({
        color: 'success',
        headingText: 'Success',
        message: 'Collection created.',
      })
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// Edit Gallery
function* editGallerySaga(data: any) {
  try {
    const { id, name, description, make_collection_public, assetsToRemove, onSuccessCallBack } = data.payload
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(setLoadingReducer(true))
    yield call(editGalleryAPI, xTgtLanId, id, name, description, make_collection_public, assetsToRemove)
    yield call(refreshCollectionSaga, { payload: { id } })
    yield call(onSuccessCallBack)
    yield put(
      addToast({
        color: 'success',
        headingText: 'Success',
        message: 'Collection edited.',
      })
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* getGalleryUsersSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    const galleryUsers: IUser[] = yield call(
      getGalleryUsersApi,
      xTgtLanId,
      data.payload.galleryId,
      data.payload.permission
    )
    yield put(setGalleryUsersReducer(galleryUsers as any))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* addGalleryUserSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield call(addGalleryUserApi, xTgtLanId, data.payload.galleryId, data.payload.userId, data.payload.access)
    yield put(addToast({ color: 'success', headingText: 'Success', message: 'Asset manager updated' }))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* favoriteGallerySaga(data: any) {
  const { galleryId, collectionSet } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    const favoriteCollections: ListResponseUUID = yield call(favoriteGalleryApi, xTgtLanId, galleryId)
    const updatedCollectionId = favoriteCollections.data.find(favoriteCollection => {
      return favoriteCollection === galleryId
    })!!
    switch (collectionSet) {
      case CollectionSet.PUB: {
        const publicCollections: ICollectionResult = yield select(publicCollectionsSelector)
        const updatedFeed = updateCollectionFeedWithFavoriteUser(
          { ...publicCollections }.feed,
          updatedCollectionId,
          xTgtLanId
        )
        yield put(
          refreshPublicCollectionsReducer({
            ...publicCollections,
            ...{ feed: updatedFeed },
          })
        )
        break
      }
      case CollectionSet.FAV: {
        const favoriteCollections: ICollectionResult = yield select(favoriteCollectionsSelector)
        const updatedFeed = updateCollectionFeedWithFavoriteUser(
          { ...favoriteCollections }.feed,
          updatedCollectionId,
          xTgtLanId
        )
        yield put(
          refreshFavoriteCollections({
            ...favoriteCollections,
            ...{ feed: updatedFeed },
          })
        )
        break
      }
      case CollectionSet.MY: {
        const myCollectionResult: ICollectionResult = yield select(ownCollectionsSelector)
        const updatedFeed = updateCollectionFeedWithFavoriteUser(
          { ...myCollectionResult }.feed,
          updatedCollectionId,
          xTgtLanId
        )
        yield put(
          refreshMyCollectionsReducer({
            ...myCollectionResult,
            ...{ feed: updatedFeed },
          })
        )
        break
      }
    }
    yield put(
      addToast({
        color: 'success',
        headingText: 'Success',
        message: 'Collection added to favorites',
      })
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* unFavoriteGallerySaga(data: any) {
  const { galleryId, collectionSet, searchServer } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    yield call(unFavoriteGalleryApi, xTgtLanId, galleryId)
    yield delay(1000)
    const updatedCollection: ICollection = yield call(getCollectionByIdAPI, searchServer, xTgtLanId, galleryId)
    switch (collectionSet) {
      case CollectionSet.PUB: {
        const collectionResult: ICollectionResult = yield select(publicCollectionsSelector)
        const collectionResultClone = { ...collectionResult }
        const updatedFeed = mergeCollectionFeed(collectionResultClone.feed, updatedCollection)
        yield put(
          refreshPublicCollectionsReducer({
            ...collectionResult,
            ...{ feed: updatedFeed },
          })
        )
        break
      }
      case CollectionSet.FAV: {
        const collectionResult: ICollectionResult = yield select(favoriteCollectionsSelector)
        const collectionResultClone = { ...collectionResult }
        yield put(
          refreshFavoriteCollections({
            ...collectionResult,
            ...{
              feed: collectionResultClone.feed.filter(f => {
                return f.id !== updatedCollection.id
              }),
            },
          })
        )
        break
      }
      case CollectionSet.MY: {
        const collectionResult: ICollectionResult = yield select(ownCollectionsSelector)
        const collectionResultClone = { ...collectionResult }
        yield put(
          refreshMyCollectionsReducer({
            ...collectionResult,
            ...{ feed: mergeCollectionFeed(collectionResultClone.feed, updatedCollection) },
          })
        )
        break
      }
    }
    yield put(
      addToast({
        color: 'success',
        headingText: 'Success',
        message: 'Collection removed from favorites',
      })
    )
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function* deleteGalleryUserSaga(data: any) {
  const { galleryId, userId } = data.payload
  const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
  try {
    yield call(deleteGalleryUserApi, xTgtLanId, galleryId, userId)
    yield put(
      addToast({
        color: 'success',
        headingText: 'Success',
        message: 'Asset manager updated',
      })
    )
    yield call(getGalleryUsersSaga, { payload: { galleryId: galleryId, permission: 'EDIT' } })
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

function updateCollectionFeedWithFavoriteUser(
  collectionFeed: ICollection[],
  updatedCollectionId: string,
  favoriteUser: string
): ICollection[] {
  return collectionFeed.map(collection => {
    if (collection.id === updatedCollectionId) {
      const favoriteUsers = [favoriteUser, ...collection.favoriteUsers]
      return {
        ...collection,
        ...{ favoriteUsers: favoriteUsers },
      }
    }
    return collection
  })
}

function mergeCollectionFeed(collectionFeed: ICollection[], updatedCollection: ICollection): ICollection[] {
  return collectionFeed.map(collection => {
    if (collection.id === updatedCollection.id) {
      return updatedCollection
    }
    return collection
  })
}

export default function* watchCollectionsSagas() {
  yield all([
    takeLatest(getCollectionDetailsReducer.type, getCollectionDetailsSaga),
    takeLatest(getCollectionAssetsState.type, getCollectionAssetsSaga),
    takeLatest(getMyCollectionsReducer.type, getOwnCollectionSaga),
    takeLatest(getPublicCollectionsReducer.type, getPublicCollectionSaga),
    takeLatest(addAssetsToGalleryReducer.type, addAssetsToGallerySaga),
    takeLatest(deleteAssetsToGalleryReducer.type, deleteAssetsToGallerySaga),
    takeLatest(downloadGalleryReducer.type, downloadGallerySaga),
    takeLatest(createGalleryReducer.type, createGallerySaga),
    takeLatest(editGalleryReducer.type, editGallerySaga),
    takeLatest(deleteCollectionReducer.type, deleteCollectionSaga),
    takeLatest(getGalleryUsersReducer.type, getGalleryUsersSaga),
    takeLatest(addGalleryUserReducer.type, addGalleryUserSaga),
    takeLatest(deleteGalleryUserReducer.type, deleteGalleryUserSaga),
    takeLatest(getFeaturedCollection.type, getFeaturedCollectionSaga),
    takeLatest(getFavoriteCollectionsFeed.type, getFavoriteCollectionSaga),
    takeLatest(favoriteGalleryReducer.type, favoriteGallerySaga),
    takeLatest(unFavoriteGalleryReducer.type, unFavoriteGallerySaga),
  ])
}
