import { all, takeLatest, call, put } from 'redux-saga/effects'
import { processError } from 'store'
import { TagBaseView, AssetTagView, TagView } from 'gallerydigitalassets-v1-client'
import { getAssetDetailTagsAPI, getCustomAssetTagsAPI, addCustomAssetTagAPI, removeCustomAssetTagAPI } from './api'
import {
  tagsReducer,
  updateTagsReducer,
  customAssetTagsReducer,
  updateCustomAssetTagsReducer,
  updateIsLoadingTagsReducer,
  deleteCustomAssetTagsReducer,
  addCustomAssetTagsReducer,
  resetAssetDetailTagsReducer,
  resetCustomAssetTagsReducer,
} from './slice'
import { STORAGE_KEYS } from '../../config'

// GET Asset Tags
function* getAssetTagsSaga(data: any) {
  try {
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    // Call API end point for Asset Tag Feed
    yield put(resetAssetDetailTagsReducer())
    yield put(updateIsLoadingTagsReducer(true))
    const tags: TagBaseView[] = yield call(
      getAssetDetailTagsAPI,
      xTgtLanId,
      data.payload.assetId,
      data.payload.assetDetails
    )
    yield put(updateTagsReducer(tags))
    yield put(updateIsLoadingTagsReducer(false))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// GET Asset custom tags
function* getAssetCustomTagsSaga(data: any) {
  try {
    // Call API end point for Asset Tag Feed
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(updateIsLoadingTagsReducer(true))
    yield put(resetCustomAssetTagsReducer())
    const tags: TagBaseView[] = yield call(getCustomAssetTagsAPI, xTgtLanId, data.payload.assetId)
    yield put(updateCustomAssetTagsReducer(tags))
    yield put(updateIsLoadingTagsReducer(false))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// Add custom asset tag
function* addCustomAssetTagSaga(data: any) {
  try {
    // Call API end point for Collection Feed
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(updateIsLoadingTagsReducer(true))
    const assetTags: AssetTagView[] = yield call(
      addCustomAssetTagAPI,
      xTgtLanId,
      data.payload.assetId,
      data.payload.tagName
    )
    const customTags: TagBaseView[] = []
    assetTags
      .filter(assetTag => assetTag.tag.key === 'custom_tag' && assetTag.tag.tagType === TagView.TagTypeEnum.Custom)
      .forEach(assetTag => {
        let tagBasView = new TagBaseView()
        tagBasView.id = assetTag.tag.id
        tagBasView.key = assetTag.tag.key
        tagBasView.value = assetTag.tag.value
        customTags.push(tagBasView)
      })
    yield put(updateCustomAssetTagsReducer(customTags))
    yield put(updateIsLoadingTagsReducer(false))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

// Delete custom asset tag
function* deleteCustomAssetTagSaga(data: any) {
  try {
    // Call API end point for Collection Feed
    const xTgtLanId: string = localStorage.getItem(STORAGE_KEYS.TGT_LAN_ID)!!
    yield put(updateIsLoadingTagsReducer(true))
    yield call(removeCustomAssetTagAPI, xTgtLanId, data.payload.assetId, data.payload.tag)
    const tags: TagBaseView[] = yield call(getCustomAssetTagsAPI, xTgtLanId, data.payload.assetId)
    yield put(updateCustomAssetTagsReducer(tags))
    yield put(updateIsLoadingTagsReducer(false))
  } catch (error) {
    if (error instanceof Error) {
      yield call(processError, error, error.message)
    } else {
      console.log(error)
    }
  }
}

export default function* watchCollectionsSagas() {
  yield all([
    takeLatest(tagsReducer.type, getAssetTagsSaga),
    takeLatest(customAssetTagsReducer.type, getAssetCustomTagsSaga),
    takeLatest(addCustomAssetTagsReducer.type, addCustomAssetTagSaga),
    takeLatest(deleteCustomAssetTagsReducer.type, deleteCustomAssetTagSaga),
  ])
}
