import React from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Link } from 'react-router-dom'
import useLink from 'hooks/useLink'

const CollectionZeroState = () => {
  const { linkEventListner } = useLink()
  return (
    <Grid.Container className="hc-pt-4x" data-testid="collection-detail-zero-state">
      {/* RENDERS FOR EMPTY COLLECTION DETAIL PAGE */}
      <>
        <Grid.Container direction="column" className="hc-pa-none">
          <Grid.Item className="no-assets-image"></Grid.Item>
          <Grid.Item>
            <Grid.Container direction="row" justify="center">
              <Grid.Item className="hc-pt-none hc-pb-none hc-ta-center hc-clr-grey03">
                <h2 className="no-assets-h2">There are currently no assets in this collection</h2>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container direction="row" justify="center">
              <Grid.Item>
                <ul className="hc-clr-grey03">
                  <li>
                    <Link
                      to={{ pathname: '/search' }}
                      onClick={() => linkEventListner('Navigation', 'Search Clicked On Empty Collection', 'Search')}
                      data-test-id="search-view"
                    >
                      <span className="empty-collection-links">Search</span>
                    </Link>
                    <span> </span>
                    <span>for assets to add to this collection</span>
                  </li>
                  <li>
                    <span>Get ideas from</span>
                    <span> </span>
                    <Link
                      to={{ pathname: '/collections/public' }}
                      onClick={() =>
                        linkEventListner(
                          'Navigation',
                          'Public Collection Clicked On Empty Collection',
                          'Public Collections'
                        )
                      }
                      data-test-id="public-collections-view"
                    >
                      <span className="empty-collection-links">Public Collection</span>
                    </Link>
                  </li>
                  <li>
                    <span>Return</span>
                    <span> </span>
                    <Link
                      to={{ pathname: '/' }}
                      onClick={() => linkEventListner('Navigation', 'Home Clicked On Empty Collection', 'Home')}
                      data-test-id="home-view"
                    >
                      <span className="empty-collection-links">Home</span>
                    </Link>
                  </li>
                </ul>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </>
      {/* RENDERS ELSEWHERE */}
    </Grid.Container>
  )
}

export default CollectionZeroState
