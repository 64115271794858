import { useSelector, useDispatch } from 'react-redux'
import { tagsReducer, customAssetTagsReducer, deleteCustomAssetTagsReducer, addCustomAssetTagsReducer } from './slice'
import { customAssetTagsSelector, assetTagsSelector, assetTagsIsLoadingSelector } from './selector'
import { match, assetTagMatcher } from 'utils/validation'
import { useEnv } from '@praxis/component-runtime-env'
import { TagBaseView } from 'gallerydigitalassets-v1-client'

export const useTags = () => {
  const customAssetTags = useSelector(customAssetTagsSelector)
  const assetTags = useSelector(assetTagsSelector)
  const isLoading = useSelector(assetTagsIsLoadingSelector)
  const { assetDetails } = useEnv()

  const dispatch = useDispatch()

  const getAssetDetailTags = async (assetId: string) => {
    const payload = {
      assetId: assetId,
      assetDetails: assetDetails,
    }
    dispatch(tagsReducer(payload))
  }

  const getCustomAssetTags = async (assetId: string) => {
    const payload = {
      assetId: assetId,
    }
    dispatch(customAssetTagsReducer(payload))
  }

  const addCustomAssetTag = async (assetId: string, tagName: string) => {
    if (tagName.length > 0) {
      const payload = {
        assetId: assetId,
        tagName: tagName,
      }
      dispatch(addCustomAssetTagsReducer(payload))
    }
  }

  const deleteCustomAssetTag = async (assetId: string, tag: TagBaseView) => {
    if (tag) {
      const payload = {
        assetId: assetId,
        tag: tag,
      }
      dispatch(deleteCustomAssetTagsReducer(payload))
      dispatch(customAssetTagsReducer({ assetId: assetId }))
    }
  }

  const getErrorMessage = (tagName: string) => {
    const tagPresent = customAssetTags.filter(
      customAssetTag => customAssetTag.value.toLowerCase() === tagName.toLowerCase()
    )[0]
    let message = ''
    if (!match(tagName, assetTagMatcher.expression)) {
      message = assetTagMatcher.message
    } else if (tagPresent) {
      message = 'Custom Tag already exists'
    }
    return message
  }

  return {
    customAssetTags,
    assetTags,
    isLoading,
    addCustomAssetTag,
    deleteCustomAssetTag,
    getAssetDetailTags,
    getCustomAssetTags,
    getErrorMessage,
  }
}
