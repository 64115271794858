import { createSlice } from '@reduxjs/toolkit'
import { ITagState } from './types'

const initialState: ITagState = {
  loading: true,
  assetDetailTagsFeed: {},
  customAssetTagsFeed: [],
}

export const tagSlice = createSlice({
  name: 'tags',
  initialState,

  reducers: {
    tagsReducer(state, action) {},
    updateTagsReducer(state, action) {
      state.assetDetailTagsFeed = action.payload
    },
    customAssetTagsReducer(state, action) {},
    deleteCustomAssetTagsReducer(state, action) {},
    addCustomAssetTagsReducer(state, action) {},
    updateCustomAssetTagsReducer(state, action) {
      state.customAssetTagsFeed = action.payload
    },
    updateIsLoadingTagsReducer(state, action) {
      state.loading = action.payload
    },
    resetAssetDetailTagsReducer(state) {
      state.assetDetailTagsFeed = {}
    },
    resetCustomAssetTagsReducer(state) {
      state.customAssetTagsFeed = []
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  tagsReducer,
  updateTagsReducer,
  customAssetTagsReducer,
  updateCustomAssetTagsReducer,
  updateIsLoadingTagsReducer,
  deleteCustomAssetTagsReducer,
  addCustomAssetTagsReducer,
  resetAssetDetailTagsReducer,
  resetCustomAssetTagsReducer,
} = tagSlice.actions

export default tagSlice.reducer
