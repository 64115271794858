import React from 'react'
import { Input } from '@enterprise-ui/canvas-ui-react'

interface IMakeCollectionPrivatePublic {
  makeCollectionPublic: any
  collectionPublic: boolean
}
const CollectionPublicPrivate = (props: IMakeCollectionPrivatePublic) => {
  const { makeCollectionPublic, collectionPublic } = props

  const PublicPrivateCollectionOptions = [
    {
      label: 'Public collection (all Gallery users can access & share)',
      value: 'Public',
    },
    {
      label: 'Private collection (only users with permission can access)',
      value: 'Private',
    },
  ]

  return (
    <>
      <Input.Label>Collection permissions</Input.Label>
      <Input.Radio
        id="collection-permissions"
        data-testid="new-collection-permissions"
        value={collectionPublic ? 'Public' : 'Private'}
        onUpdate={(id: any, value: any) => {
          makeCollectionPublic(value === 'Public')
        }}
        options={PublicPrivateCollectionOptions}
      />
    </>
  )
}

export default CollectionPublicPrivate
