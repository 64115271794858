import { useEffect } from 'react'
import { useAuth } from 'store'

const useGallery = (launch?: Boolean) => {
  // Start Gallery Here.
  const { checkUserAuth } = useAuth()

  useEffect(() => {
    // Check if user is authenticated.
    if (launch) {
      checkUserAuth()
    }
  }, [])
}

export default useGallery
