import BasePage from 'components/pages/BasePage/BasePage'
import HeroSection from './HeroSection'
import { useEffect } from 'react'
import './HomePage.scss'
import AssetFeed from 'components/feeds/AssetFeed/AssetFeed'
import { useCollections, ZeroStateToShowEnum } from 'store'

const HomePage = () => {
  const { getPaginatedCollectionAssets, featuredCollection, getFeatCollection } = useCollections()

  useEffect(() => {
    getFeatCollection()
  }, [])

  return (
    <BasePage crumbs={{}}>
      <HeroSection />
      <div className="hc-pb-2x"></div>
      <AssetFeed
        pageName="homePage"
        isLoading={featuredCollection.collectionAssets.isLoading}
        assets={featuredCollection.collectionAssets.assets}
        totalCount={featuredCollection.collectionAssets.totalAssets}
        zeroStateToShow={ZeroStateToShowEnum.SEARCHPAGE}
        infiniteScrollMethod={getPaginatedCollectionAssets}
      />
    </BasePage>
  )
}

export default HomePage
