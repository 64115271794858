import { STORAGE_KEYS, COOKIE_KEYS } from 'config'
import queryString from 'query-string'
import jwt from 'jsonwebtoken'

// grabs info off end of url, and sets in local storage
export const getQueryStringInformation = () => {
  // Check to see if it's in the query string on the tail end of auth.
  const parsedQueryString = queryString.parse(window.location.search)
  let parsedAccessToken: string = parsedQueryString.access_token as string
  let parsedDisplayName: string = parsedQueryString.displayname as string
  let parsedCompanyName: string = parsedQueryString.company as string

  if (parsedDisplayName && parsedCompanyName) {
    let cleanedDisplayName = parsedDisplayName.replaceAll('.', ' ').replaceAll('"', '')
    let cleanedCompanyName = parsedCompanyName.replaceAll('"', '')
    localStorage.setItem(STORAGE_KEYS.DISPLAY_USER_NAME, cleanedDisplayName)
    localStorage.setItem(STORAGE_KEYS.DISPLAY_COMPANY_NAME, cleanedCompanyName)
  }
  if (parsedAccessToken) {
    // We're on the tail end of the auth process.
    let decodedAccessToken: any = jwt.decode(parsedAccessToken)

    if (decodedAccessToken) {
      localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, parsedAccessToken)
      // Check to see if we have a stored session cookie defining where the user was initially trying to go before they were bounced to auth.
      const initialLocation: string = localStorage.getItem(COOKIE_KEYS.INITIAL_LOCATION) || '/'
      // Clean up & redirect.
      localStorage.removeItem(COOKIE_KEYS.INITIAL_LOCATION)
      window.location.replace(initialLocation)
    }
  }
}

// When the site is accessed using a third-party auth ID, it will store location cookie and redirect following authentication.
export const detectServiceRedirect = () => {
  const { hash } = window.location
  const serviceIdRE: RegExp = /#service-redirect\/(.+)/
  if (hash.match(serviceIdRE)) {
    const matches: any = serviceIdRE.exec(hash)
    if (matches && matches.length === 2) {
      const id: string = matches[1]
      switch (id) {
        case 'gallery-cli':
          // Session cookie for redirect.
          localStorage.setItem(COOKIE_KEYS.REDIRECT_SERVICE, id)
          break
      }
    }
  }
}

// redirect to secondary or third-party service
export const applyServiceRedirect = (accessToken: string) => {
  const redirectServiceId: string | null = localStorage.getItem(COOKIE_KEYS.REDIRECT_SERVICE)
  if (redirectServiceId && accessToken) {
    localStorage.removeItem(COOKIE_KEYS.REDIRECT_SERVICE)
    switch (redirectServiceId) {
      case 'gallery-cli':
        window.location.replace(`http://localhost:9876/auth/${accessToken.replace('Bearer ', '')}`)
        return
      case 'unreal':
        alert('Not yet implemented.')
        break
    }
  }
}
