import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'

import {
  Anchor,
  Avatar,
  Button,
  Caption,
  Form,
  Grid,
  Heading,
  Modal,
  Spinner,
  Tooltip,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { ActionMeta, components, MultiValue } from 'react-select'
import './EditCollectionManagerModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { some } from 'lodash'
import { convertToProperCase, includesCaseInsensitive } from 'utils/string'
import { IPotentialManagerOption, IUser, processError, useCollections } from 'store'
import { PermissionView } from 'gallerydigitalassets-v1-client'

export interface IEditCollectionManagerModalValues {
  collectionId: string
  userToBeRemoved?: string
  usersToBeAdded?: string[]
}

export interface IEditCollectionManagerModalProps {
  closeModal: () => void
  data: IEditCollectionManagerModalValues
}

const EditCollectionManagerModal: React.FC<IEditCollectionManagerModalProps> = ({ closeModal, data }) => {
  const [options, setOptions] = useState<IPotentialManagerOption[]>([])
  const [potentialCollectionManagers, setPotentialCollectionManagers] = useState<IUser[]>([])
  const [collectionOwners, setCollectionOwners] = useState<IUser[]>([])
  const [collectionManagers, setCollectionManagers] = useState<IUser[]>([])
  const [selectedOption, setSelectedOption] = useState<IPotentialManagerOption | {}>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false)
  const initialValues: IEditCollectionManagerModalValues = {
    collectionId: data.collectionId!!,
    usersToBeAdded: data.usersToBeAdded,
    userToBeRemoved: '',
  }
  const { getGalleryUsers, addGalleryUser, deleteGalleryUser, collectionUsers, resetCollectionUsers } = useCollections()

  useEffect(() => {
    getGalleryUsers(data.collectionId, 'EDIT')
    // resets collection users so old collection users wont show on new page
    return () => {
      resetCollectionUsers()
    }
  }, [])

  useEffect(() => {
    if (some(collectionUsers)) {
      let collectionUserData = [...collectionUsers]
      collectionUserData = collectionUserData.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.toLowerCase() < `${b.firstName} ${b.lastName}`.toLowerCase() ? 1 : -1
      )
      setPotentialCollectionManagers(
        collectionUserData.filter(
          (user: IUser) =>
            user.access!! !== PermissionView.AccessEnum.GalleryCreator &&
            user.access!! !== PermissionView.AccessEnum.GalleryManager
        )
      )
      setCollectionOwners(
        collectionUserData.filter((user: IUser) => user.access!! === PermissionView.AccessEnum.GalleryCreator)
      )
      setCollectionManagers(
        collectionUserData.filter((user: IUser) => user.access!! === PermissionView.AccessEnum.GalleryManager)
      )
    }
  }, [collectionUsers])

  useEffect(() => {
    if (some(potentialCollectionManagers)) {
      const options: IPotentialManagerOption[] = potentialCollectionManagers.map(
        (user: IUser): IPotentialManagerOption => {
          return {
            value: user.id,
            label: `${convertToProperCase(user.firstName)} ${convertToProperCase(user.lastName)}`,
            subLabel: user.mail,
          }
        }
      )
      setOptions(options.sort((a, b) => (a.label > b.label ? 1 : -1)))
    }
  }, [potentialCollectionManagers])

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <Grid.Container direction="row" spacing="none">
          <Grid.Item className="hc-fs-xs hc-clr-black hc-pr-min">{props.data.label}</Grid.Item>
          <Grid.Item className="hc-fs-xs hc-clr-grey01">{props.data.subLabel}</Grid.Item>
        </Grid.Container>
      </components.Option>
    )
  }

  let loadOptions = (searchTerm: string, callback: (options: any) => void) => {
    setTimeout(() => {
      callback(filterOptions(searchTerm))
    }, 1000)
  }

  let filterOptions = (searchTerm: string) => {
    if (searchTerm === undefined || searchTerm === '') {
      return options
    }
    return options.filter((option: IPotentialManagerOption) => includesCaseInsensitive(option.label, searchTerm))
  }

  useEffect(() => {
    if (options.length !== 0) {
      setOptionsLoaded(!optionsLoaded)
    }
  }, [options])

  const handleInputChange = (newValue: string) => {
    const searchTerm = newValue.replace(/\W/g, '')
    return searchTerm
  }

  const handleChange = (value: MultiValue<IPotentialManagerOption>, action: ActionMeta<any>) => {
    if (value !== undefined && value.length > 0) {
      const usersToBeAdded = value.map((option: IPotentialManagerOption) => option.value)
      usersToBeAdded.forEach((userToBeAdded: String) => setSelectedOption(userToBeAdded))
      setSelectedOption(usersToBeAdded)
      formik.setFieldValue('usersToBeAdded', usersToBeAdded)
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      minHeight: 40,
      borderStyle: 'solid',
      borderColor: 'black',
      borderRadius: 16,
    }),
    placeholder: (base: any) => ({
      ...base,
      fontColor: '#666666',
      fontSize: '14px',
      lineHeight: '21px',
      textAlign: 'left',
    }),
    noOptionsMessage: (base: any) => ({
      ...base,
      fontColor: '#666666',
      textAlign: 'left',
      fontSize: '14px',
    }),
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: IEditCollectionManagerModalValues) => {
      const { usersToBeAdded } = values
      setIsSubmitting(true)
      let message: string
      if (usersToBeAdded !== undefined && usersToBeAdded.length > 0) {
        try {
          addGalleryUser(collectionId, usersToBeAdded || [], 'GALLERY_MANAGER')
          closeModal()
        } catch (error) {
          if (error instanceof Error) {
            processError(error, error.message, true)
          } else {
            console.log(error)
          }
          closeModal()
        }
      } else {
        message = 'Something went wrong and  collection users cannot be found.  You may want to report this issue.'

        const error = new Error(message)
        processError(error)
      }
    },
    validate: (values: IEditCollectionManagerModalValues) => {
      const errors: any = {}
      if (values.usersToBeAdded === undefined || values.usersToBeAdded?.length < 1) {
        errors.users_being_added = 'Must have users being added, maybe you want to create a new collection instead'
        console.log('invalid')
      }
      return errors
    },
    validateOnMount: true,
    isInitialValid: !!data.usersToBeAdded,
  })

  const { usersToBeAdded, collectionId } = formik.values

  useEffect(() => {
    formik.validateForm()
  }, [usersToBeAdded, selectedOption])

  return (
    <>
      <Modal className="edit-collection-user-modal hc-pb-dense" isVisible onRefuse={() => closeModal()}>
        <div>
          <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">Edit collection managers</h1>
        </div>
        <div className="hc-pa-normal edit-collection-user-container hc-pb-dense ">
          <Form onSubmit={formik.handleSubmit} data-testid="form" role="form">
            <Grid.Container direction="column">
              <Grid.Item xs={12} className="hc-pl-expanded hc-pb-expanded hc-pt-expanded hc-mr-normal">
                <Input.Label htmlFor="addUser" data-testid="form-label">
                  {' '}
                  Add collection managers
                </Input.Label>
                <AsyncSelect
                  isMulti={true}
                  data-testid="select-component"
                  key={JSON.stringify(optionsLoaded)}
                  noOptionsMessage={() => 'No users found'}
                  loadOptions={loadOptions}
                  defaultOptions={true}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  isClearable={true}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Option,
                  }}
                  placeholder="Type to search names"
                  styles={customStyles}
                  name="addUser"
                  inputId="addUser"
                />
              </Grid.Item>
              {some(collectionOwners || collectionManagers) && (
                <Grid.Item className="collection-user-list hc-pt-none">
                  <Grid.Container direction="column">
                    {some(collectionOwners) && (
                      <Grid.Item className="hc-pb-expanded hc-pl-expanded">
                        <Grid.Container direction="column" spacing="none">
                          <Grid.Container direction="row" spacing="none" align="center">
                            <Grid.Item>
                              <Heading size={5}>Owner</Heading>
                            </Grid.Item>
                            <Grid.Item className="hc-pl-min">
                              <Tooltip
                                data-testid="tooltip-collection-owner"
                                location="bottom-left"
                                wrap
                                content="Collection Owners can edit collection details, settings, add/remove files, delete the collection, & add/remove Collection Managers."
                              >
                                <FontAwesomeIcon icon="info-circle" className="fa-xs" />
                              </Tooltip>
                            </Grid.Item>
                          </Grid.Container>
                          {collectionOwners.map((owner: IUser) => (
                            <Grid.Item key={owner.id} className="hc-pt-min">
                              <Grid.Container direction="row" spacing="none" align="center">
                                <Grid.Item>
                                  <Avatar
                                    aria-label={`Collection Owner: ${owner.firstName} avatar`}
                                    data-testid={`collection-owner-avatar-${owner.firstName.charAt(
                                      0
                                    )}${owner.lastName.charAt(0)}`}
                                    className="hc-bg-grey01"
                                  >{`${owner.firstName.charAt(0)}${owner.lastName.charAt(0)}`}</Avatar>
                                </Grid.Item>
                                <Grid.Item className="hc-pl-xs">
                                  <Caption
                                    data-testid={`collection-owner-email-${owner.mail}`}
                                    below={<p className="hc-clr-grey01 hc-fs-xs">{`${owner.mail}`}</p>}
                                  >
                                    <Heading
                                      data-testid={`collection-owner-name-${convertToProperCase(
                                        owner.firstName
                                      )} ${convertToProperCase(owner.lastName)}`}
                                      size={9}
                                      className="owner-name hc-pa-none hc-fs-xs hc-clr-black"
                                    >
                                      {`${convertToProperCase(owner.firstName)} ${convertToProperCase(owner.lastName)}`}
                                    </Heading>
                                  </Caption>
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </Grid.Container>
                      </Grid.Item>
                    )}
                    {some(collectionManagers) && (
                      <Grid.Item className="hc-pt-none hc-pl-expanded">
                        <Grid.Container direction="column" spacing="none">
                          <Grid.Item>
                            <Grid.Container direction="row" spacing="none" align="center">
                              <Grid.Item>
                                <Heading size={5}>Managers</Heading>
                              </Grid.Item>
                              <Grid.Item className="hc-pl-min">
                                <Tooltip
                                  data-testid="tooltip-collection-manager"
                                  location="bottom-left"
                                  wrap
                                  content="Collection Managers can edit collection details, settings, &/or remove files."
                                >
                                  <FontAwesomeIcon icon="info-circle" className="fa-xs" />
                                </Tooltip>
                              </Grid.Item>
                            </Grid.Container>
                          </Grid.Item>
                          {collectionManagers.map((manager: IUser) => (
                            <Grid.Item key={manager.id} className="hc-pt-min">
                              <Grid.Container direction="row" justify="space-between" align="center">
                                <Grid.Item>
                                  <Grid.Container direction="row" spacing="none" align="center">
                                    <Grid.Item>
                                      <Avatar
                                        aria-label={`Collection Manager: ${manager.firstName} avatar`}
                                        data-testid={`collection-manager-avatar-${manager.firstName.charAt(
                                          0
                                        )}${manager.lastName.charAt(0)}`}
                                        className="hc-bg-grey01"
                                      >{`${manager.firstName.charAt(0)}${manager.lastName.charAt(0)}`}</Avatar>
                                    </Grid.Item>
                                    <Grid.Item className="hc-pl-xs">
                                      <Caption
                                        data-testid={`collection-manager-email-${manager.mail}`}
                                        below={<p className="hc-clr-grey01 hc-fs-xs">{`${manager.mail}`}</p>}
                                      >
                                        <Heading
                                          data-testid={`collection-manager-name-${convertToProperCase(
                                            manager.firstName
                                          )} ${convertToProperCase(manager.lastName)}`}
                                          size={9}
                                          className="manager-names hc-pa-none hc-fs-xs hc-clr-black"
                                        >
                                          {`${convertToProperCase(manager.firstName)} ${convertToProperCase(
                                            manager.lastName
                                          )}`}
                                        </Heading>
                                      </Caption>
                                    </Grid.Item>
                                  </Grid.Container>
                                </Grid.Item>
                                <Grid.Item className="hc-mr-normal">
                                  <Anchor
                                    data-testid={`remove-manager-button-${manager.id}`}
                                    onClick={() => deleteGalleryUser(collectionId, manager.id)}
                                  >
                                    Remove
                                  </Anchor>
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                          ))}
                        </Grid.Container>
                      </Grid.Item>
                    )}
                  </Grid.Container>
                </Grid.Item>
              )}
              <Grid.Item xs={12} className="bottom-buttons">
                <Grid.Container direction="row" justify="flex-end" align="center" className="bottom-container">
                  <Grid.Item className="hc-pr-md hc-mt-lg hc-mb-xs">
                    <Button className="cancel-button" onClick={() => closeModal()}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => formik.submitForm()}
                      disabled={isSubmitting || !formik.isValid}
                      data-testid="edit-collection-manager-button"
                      className="save-button"
                    >
                      {isSubmitting && <Spinner size="dense" />}
                      {!isSubmitting && 'Save'}
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
          </Form>
        </div>
      </Modal>
    </>
  )
}
export default EditCollectionManagerModal
