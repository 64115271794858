import { createSelector } from 'reselect'
import { RootState } from 'store'

export const tagsSelector = createSelector(
  (state: RootState) => state.tags,
  state => state
)
export const customAssetTagsSelector = createSelector(tagsSelector, state => state.customAssetTagsFeed)
export const assetTagsSelector = createSelector(tagsSelector, state => state.assetDetailTagsFeed)
export const assetTagsIsLoadingSelector = createSelector(tagsSelector, state => state.loading)
