import React, { useState, useEffect } from 'react'

import { Grid, Anchor, Heading } from '@enterprise-ui/canvas-ui-react'
import { some } from 'lodash'
import DetailDrawer from './DetailDrawer'
import './DetailView.scss'
import { useTags } from 'store'

type DetailViewProps = {
  assetId: string
}

const DetailView: React.FC<DetailViewProps> = ({ assetId }) => {
  const { getAssetDetailTags, assetTags } = useTags()
  // allows opending or closing all drawers, and forceRender allows overwriting maually opened drawers
  const [openDrawer, setOpenDrawer] = useState(false)
  const [forceRender, setForceRender] = useState(false)

  useEffect(() => {
    getAssetDetailTags(assetId)
  }, [assetId])

  const emptyDetailDrawers = () => {
    return (
      <div data-testid="empty-detail-drawers" className="hc-pa-normal hc-mb-lg">
        <Heading size={5} className="hc-clr-grey03 hc-pl-normal">
          No details for this asset.
        </Heading>
      </div>
    )
  }

  const detailDrawers = () => {
    return (
      <div className="hc-pa-normal hc-mb-md hc-pv-none">
        <Grid.Container direction="column" spacing="normal">
          <Grid.Item xs={12} spacing="none" className="asset-icons-container">
            <Grid.Container spacing="dense">
              <Grid.Item className="asset-table-icons">
                <div>
                  <Anchor
                    data-testid="btn_open_all_drawers"
                    size="dense"
                    onClick={() => {
                      forceRender ? setOpenDrawer(false) : setOpenDrawer(true)
                      setForceRender(!forceRender)
                    }}
                  >
                    {forceRender ? 'Collapse All' : 'Expand All'}
                  </Anchor>
                </div>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container
          data-testid={`detail-drawer open-drawer-${openDrawer} force-render-${forceRender}`}
          direction="column"
          className="hc-pl-normal"
        >
          <Grid.Item xs={12}>
            {some(assetTags) &&
              Object.keys(assetTags!!)
                .sort((a, b) => (a > b ? 1 : -1))
                .map((detailTagKey: string, index: number) => {
                  return (
                    <DetailDrawer
                      key={index}
                      title={detailTagKey}
                      tags={[
                        ...assetTags[detailTagKey].filter(
                          tag => tag.value.length > 0 && tag.value.toLowerCase() !== 'null'
                        ),
                      ]}
                      openDrawer={openDrawer}
                      forceRender={forceRender}
                      startExpanded={index === 0}
                    />
                  )
                })}
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  }

  return !some(assetTags) ? emptyDetailDrawers() : detailDrawers()
}
export default DetailView
