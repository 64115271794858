import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useDispatch } from 'react-redux'
import { IToast, removeToast, useToasts } from 'store'

const GalleryToaster = () => {
  const { toasts } = useToasts()
  const dispatch = useDispatch()
  const makeToast = ToastProvider.useToaster()

  return (
    <>
      {toasts.map((toast: IToast, index: number) => {
        makeToast({
          ...toast,
          type: toast.color,
          onComplete: () => dispatch(removeToast(toast)),
        })
        return null
      })}
    </>
  )
}
export default GalleryToaster
