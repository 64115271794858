import BasePage from 'components/pages/BasePage/BasePage'
import { Heading, Grid } from '@enterprise-ui/canvas-ui-react'
import { BreadCrumbPriority } from 'store'

const SystemErrorPage = () => (
  <BasePage crumbs={{ name: 'System Error', href: '', priority: BreadCrumbPriority.High }}>
    <Grid.Container className="hc-pa-normal">
      <Grid.Item xs={12} className="hc-ta-center">
        <Heading>System Error</Heading>
      </Grid.Item>
      <Grid.Item xs={12} className="hc-ta-center">
        <p>
          Please ensure you have access to the APP-OAUTH2-GALLERY-USER entitlement from{' '}
          <a className="hyperlink" href="https://myaccess.prod.target.com/dashboard">
            MyAccess.
          </a>{' '}
          <p>
            If you believe this occurred in error, please email{' '}
            <a href="mailto:gallery-team@target.com">gallery-team@target.com</a> or reachout on the{' '}
            <a className="hyperlink" href="https://target.slack.com/archives/C017CBY3TB7">
              #gallery_support
            </a>{' '}
            Slack channel.
          </p>
        </p>
      </Grid.Item>
    </Grid.Container>
  </BasePage>
)

export default SystemErrorPage
