import {
  CollectionAssetView,
  CollectionEditRequest,
  HttpError,
  MayBeAssetUserView,
  UserBaseView,
} from 'gallerydigitalassets-v1-client'
import { ICollection, ICollectionResult } from './types'
import {
  axiosSearchClient,
  collectionDownloadApi,
  collectionUserApi,
  digitalCollectionApi,
  digitalCollectionAssetsApi,
  digitalCollectionFavoriteApi,
  digitalCollectionUserApi,
  ISearchRequest,
  ISearchSort,
  IUser,
} from 'store'
import { collectionApi, collectionAssetApi } from '../client'
import processError from '../utils/processError'
import { keysToCamel } from '../../utils/object'
import {
  CollectionCreateRequestV2,
  CreateCollectionV2Request,
  FavoriteCollectionRequest,
  RemoveAssetsFromCollectionRequest,
  UnFavoriteCollectionRequest,
} from '@galleryjs/api-digital-assets'

export const getOwnCollectionsAPI = async (
  xTgtLanId: string,
  searchServer: string,
  query: string,
  page: number,
  size?: number,
  sort?: ISearchSort[]
) => {
  try {
    const requestBody = await getSearchRequestBody('EDIT', query, sort)
    const { data } = await axiosSearchClient.post(`${searchServer}/search/collections?per_page=${size}`, requestBody)

    const dataToCamel = keysToCamel(data)
    const collectionSearchResults = dataToCamel.collections as unknown[] as ICollection[]
    const totalItems = dataToCamel.totalItems

    const newFeed: ICollectionResult = {
      criteria: { q: query, p: page, size: size, f: {}, sort: dataToCamel.sort },
      feed: collectionSearchResults,
      total: totalItems,
    }
    return newFeed
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const getPublicCollectionsAPI = async (
  xTgtLanId: string,
  searchServer: string,
  page: number,
  size?: number,
  sort?: ISearchSort[],
  query?: string
) => {
  try {
    const publicFilter = { filter_chip_key: 'gallery_collection_orgs', filter_chip_value: ['app-oauth2-gallery-user'] }
    const requestBody = await getSearchRequestBody('VIEW', query, sort, [publicFilter])
    const { data } = await axiosSearchClient.post(`${searchServer}/search/collections?per_page=${size}`, requestBody)

    const dataToCamel = keysToCamel(data)
    const collectionSearchResults = dataToCamel.collections as unknown[] as ICollection[]
    const totalItems = dataToCamel.totalItems

    const newFeed: ICollectionResult = {
      criteria: { q: '', p: page, size: size, f: {}, sort: dataToCamel.sort },
      feed: collectionSearchResults,
      total: totalItems,
    }
    return newFeed
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const getFavoriteCollectionsAPI = async (
  xTgtLanId: string,
  searchServer: string,
  page: number,
  size?: number,
  sort?: ISearchSort[],
  query?: string
) => {
  try {
    const favoriteFilter = { filter_chip_key: 'gallery_collection_fav', filter_chip_value: [xTgtLanId] }
    const requestBody = await getSearchRequestBody('VIEW', query, sort, [favoriteFilter])
    const { data } = await axiosSearchClient.post(`${searchServer}/search/collections?per_page=${size}`, requestBody)

    const dataToCamel = keysToCamel(data)
    const collectionSearchResults = dataToCamel.collections as unknown[] as ICollection[]
    const totalItems = dataToCamel.totalItems

    const newFeed: ICollectionResult = {
      criteria: { q: '', p: page, size: size, f: {}, sort: dataToCamel.sort },
      feed: collectionSearchResults,
      total: totalItems,
    }
    return newFeed
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const getCollectionByIdAPI = async (searchServer: string, xTgtLanId: string, galleryId: string) => {
  try {
    const filterRequest = { filter_chip_key: 'gallery_collection_id', filter_chip_value: [galleryId] }
    const requestBody = { filters: [filterRequest], permission: 'VIEW', sort: [] }
    const { data } = await axiosSearchClient.post(`${searchServer}/search/collections?per_page=${3}`, requestBody)
    const dataToCamel = keysToCamel(data)
    const searchResults = dataToCamel.collections as unknown[] as ICollection[]
    return searchResults[0]
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const getCollectionDetailsAPI = async (xTgtLanId: string, galleryId: string) => {
  try {
    const { body } = await collectionApi.getCollectionDetails(galleryId, xTgtLanId)
    return body.data
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const getAssetsByCollectionIdApi = async (
  xTgtLanId: string,
  galleryId: string,
  page: number = 0,
  size: number = 25
) => {
  try {
    const { body } = await collectionAssetApi.getAssetsByCollectionId(galleryId, xTgtLanId, page, size)
    const assets: CollectionAssetView[] = body.data
    return {
      page,
      size,
      totalAssets: body.totalItems,
      assets: assets,
    }
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const addAssetsToCollectionAPI = async (xTgtLanId: string, galleryId: string, assetIds: string[]) => {
  try {
    await collectionAssetApi.addAssetsToCollection(galleryId, xTgtLanId, assetIds)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const deleteGalleryAssetAPI = async (xTgtLanId: string, ids: string[], collectionId: string) => {
  const removeAssetsFromCollectionRequest: RemoveAssetsFromCollectionRequest = {
    collectionId: collectionId,
    requestBody: ids,
    softDelete: true,
    xTgtLANID: xTgtLanId,
  }
  try {
    await digitalCollectionAssetsApi.removeAssetsFromCollection(removeAssetsFromCollectionRequest)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const deleteCollectionAPI = async (xTgtLanId: string, id: string) => {
  try {
    await collectionApi.deleteCollection(id, xTgtLanId)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const downloadGalleryAPI = async (xTgtLanId: string, id: string) => {
  try {
    await collectionDownloadApi.downloadCollection(id, xTgtLanId)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const createGalleryAPI = async (
  xTgtLanId: string,
  name: string,
  description: string = '',
  make_asset_public: boolean,
  asset_ids?: string[],
  managers?: string[],
  makeCollectionFav?: boolean
) => {
  const collectionCreateRequestV2: CollectionCreateRequestV2 = {
    assetId: asset_ids,
    description: description,
    makeGalleryPublic: make_asset_public,
    name: name,
    collectionManagers: managers,
    makeCollectionFav: makeCollectionFav,
  }
  const createCollectionV2Request: CreateCollectionV2Request = {
    collectionCreateRequestV2: collectionCreateRequestV2,
    xTgtLANID: xTgtLanId,
  }
  try {
    const response = await digitalCollectionApi.createCollectionV2(createCollectionV2Request)
    return response.data
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const editGalleryAPI = async (
  xTgtLanId: string,
  id: string,
  name: string,
  description: string = '',
  make_collection_public: boolean,
  assetToRemove?: string[]
) => {
  const request: CollectionEditRequest = new CollectionEditRequest()
  request.name = name
  request.description = description
  request.makePublic = make_collection_public
  request.assetsToRemove = assetToRemove
  try {
    const { body } = await collectionApi.editCollection(id, xTgtLanId, request)
    return body.data
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const getGalleryUsersApi = async (
  xTgtLanId: string,
  galleryId: string,
  permission: 'CREATE' | 'VIEW' | 'EDIT' | 'DELETE' | 'LINK' | 'SHARE'
) => {
  try {
    const response = await collectionUserApi.getCollectionUsers(galleryId, permission, xTgtLanId)
    if (response.body.data) {
      let galleryUserViews = response.body.data
      return galleryUserViews.map((galleryUserView: MayBeAssetUserView): IUser => {
        let user = {} as IUser
        let userView: UserBaseView = galleryUserView.user!!
        user.id = userView.id!!
        user.firstName = userView.firstName!!
        user.lastName = userView.lastName!!
        user.lanId = userView.lanId!!
        user.mail = userView.email
        user.access = galleryUserView.access
        return user
      })
    }
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const addGalleryUserApi = async (
  xTgtLanId: string,
  galleryId: string,
  userId: string[],
  access:
    | 'NONE'
    | 'ADMIN'
    | 'ORGANIZATION_ADMIN'
    | 'WORKFLOW_ADMIN'
    | 'ORGANIZATION_ASSET_CREATOR'
    | 'ORGANIZATION_ASSET_MANAGER'
    | 'ORGANIZATION_ASSET_VIEWER'
    | 'ORGANIZATION_GALLERY_VIEWER'
    | 'ORGANIZATION_GALLERY_MANAGER'
    | 'ORGANIZATION_GALLERY_CREATOR'
    | 'GALLERY_CREATOR'
    | 'GALLERY_MANAGER'
    | 'GALLERY_VIEWER'
    | 'ASSET_CREATOR'
    | 'ASSET_MANAGER'
    | 'ASSET_VIEWER'
    | 'WORKFLOW_REVIEWER'
    | 'WORKFLOW_RENDER_CREATOR'
    | 'WORKFLOW_DEFAULT' = 'ASSET_MANAGER'
) => {
  try {
    await digitalCollectionUserApi.addCollectionUser({
      collectionId: galleryId,
      userId: userId,
      access: access,
      xTgtLANID: xTgtLanId,
    })
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const favoriteGalleryApi = async (xTgtLanId: string, galleryId: string) => {
  const request: FavoriteCollectionRequest = {
    collectionId: galleryId,
    xTgtLANID: xTgtLanId,
  }
  try {
    return await digitalCollectionFavoriteApi.favoriteCollection(request)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const unFavoriteGalleryApi = async (xTgtLanId: string, galleryId: string) => {
  const request: UnFavoriteCollectionRequest = {
    collectionId: galleryId,
    xTgtLANID: xTgtLanId,
  }
  try {
    return await digitalCollectionFavoriteApi.unFavoriteCollection(request)
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

export const deleteGalleryUserApi = async (xTgtLanId: string, galleryId: string, userId: string) => {
  try {
    await digitalCollectionUserApi.deleteCollectionUser({
      collectionId: galleryId,
      userId: userId,
      softDelete: true,
      xTgtLANID: xTgtLanId,
    })
  } catch (error) {
    if (error instanceof HttpError) {
      throw error
    } else {
      processError(error as unknown as Error)
      //console.error(error)
    }
  }
}

const getSearchRequestBody = async (
  permission: string,
  searchQuery?: string,
  searchSort?: ISearchSort[],
  searchFilters?: [{ filter_chip_key: string; filter_chip_value: string[] }]
) => {
  const sort: { field: String; direction: String; field_value: String | undefined }[] = []
  const filters: { filter_chip_key: string; filter_chip_value: string[] }[] = []

  if (searchSort) {
    searchSort.forEach(s => {
      let searchSort = {
        field: s.field,
        direction: s.direction,
        field_value: s.fieldValue,
      }
      sort.push(searchSort)
    })
  }

  if (searchFilters) {
    searchFilters.forEach(f => {
      let filter: { filter_chip_key: string; filter_chip_value: string[] } = {
        filter_chip_key: f.filter_chip_key,
        filter_chip_value: f.filter_chip_value,
      }
      filters.push(filter)
    })
  }

  const requestBody: ISearchRequest = {
    permission: permission,
    sort: sort,
    filters: filters,
  }
  if (searchQuery) requestBody.query = searchQuery
  return requestBody
}
