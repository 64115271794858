import { createSlice } from '@reduxjs/toolkit'

export interface IModalData {
  type?: string // ModalType
  data?: any
}

export const modalInitState: IModalData = {
  type: '',
  data: undefined,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    value: modalInitState,
  },

  reducers: {
    setModalData(state, action) {
      state.value.data = action.payload!
    },
    setModalType(state, action) {
      state.value.type = action.payload!
    },
  },
})

// Action creators are generated for each case reducer function
export const { setModalData, setModalType } = modalSlice.actions

export default modalSlice.reducer
