import React, { useEffect, useState } from 'react'

import { Modal, Grid, Form, Button, Table, Spinner, Input } from '@enterprise-ui/canvas-ui-react'
import { useFormik } from 'formik'
import { some } from 'lodash'
import './EditCollectionModal.scss'
import CollectionPublicPrivate from 'components/collectionPublicPrivate/CollectionPublicPrivate'
import { findMismatches } from 'utils/array'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { match, descriptionMatcher } from 'utils/validation'

import { IAsset, useCollections } from 'store'
import { useEnv } from '@praxis/component-runtime-env'
export interface ICollectionModalValues {
  id: string
  name: string
  description: string
  isPrivate: boolean
  assets: IAsset[]
  canEditCollectionOrgs: boolean
  organizations: null
}

export interface ICollectionEditModalProps {
  data: ICollectionModalValues
  closeModal: () => void
}

const EditCollectionModal: React.FC<ICollectionEditModalProps> = ({ data, closeModal }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isPublic, setIsPublic] = useState<boolean>(!data.isPrivate)
  const { editGallery } = useCollections()
  const { nameMatcher } = useEnv()

  useEffect(() => {}, [data])

  const initialValues: ICollectionModalValues = {
    id: data.id,
    name: data.name,
    description: data.description,
    isPrivate: data.isPrivate,
    assets: data.assets,
    canEditCollectionOrgs: data.canEditCollectionOrgs,
    organizations: data.organizations,
  }

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: ICollectionModalValues) => {
      // current files array and name string of modal
      const { id: collectionId, name: newCollectionName, assets: editedAssets, description: newDescription } = values
      setIsSubmitting(true)

      // finds discrepencies in file arrays for deleted/added files
      const { newArray1: assetsToRemove } = findMismatches(data.assets, editedAssets, 'id')

      editGallery(
        onSubmitSuccess,
        collectionId,
        newCollectionName,
        newDescription,
        isPublic,
        assetsToRemove.map(asset => asset.id)
      )
    },
    validate: (values: ICollectionModalValues) => {
      const errors: any = {}
      if (!values.name.trim()) {
        errors.name = 'Required'
      }
      if (!match(values.name, nameMatcher.expression)) {
        errors.name = nameMatcher.message
      }
      if (!match(values.description, descriptionMatcher.expression)) {
        errors.description = descriptionMatcher.message
      }
      if (
        data.name === values.name &&
        data.description === values.description &&
        data.assets === values.assets &&
        data.isPrivate === !isPublic
      ) {
        errors.error = 'Request cannot be sent with same values.'
      }
      return errors
    },
    validateOnMount: true,
  })

  const onSubmitSuccess = () => {
    closeModal()
  }

  useEffect(() => {
    formik.validateForm()
  }, [formik.values.name, formik.values.description, formik.values.assets, isPublic])

  return (
    <Modal className="modal-parent-container" isVisible onRefuse={() => closeModal()} onApproveModal={() => {}}>
      <div>
        <h1 className="hc-fs-lg hc-pl-lg hc-pt-sm">Edit collection</h1>
      </div>
      <div className="hc-pa-normal">
        <form data-testid="edit-collection-form" onSubmit={formik.handleSubmit} className="this-form">
          <Grid.Container className="hc-pa-normal">
            <Grid.Item xs={12}>
              <Grid.Container direction="row">
                <Grid.Item xs={6}>
                  <Grid.Container direction="column">
                    <Grid.Item xs={12} className="hc-pb-none">
                      <Form.Field
                        error={!!(formik.touched.name && formik.errors.name)}
                        errorText={formik.errors.name}
                        type="text"
                        name="name"
                        placeholder="Collection name"
                        label="Collection name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        data-testid="edit-collection-name-input"
                      />
                    </Grid.Item>
                    <Grid.Item xs={12} className="hc-pb-normal">
                      <Form.Field
                        type="textarea"
                        error={!!(formik.touched.description && formik.errors.description)}
                        errorText={formik.errors.description}
                        label="Collection description"
                        name="description"
                        placeholder="Briefly describe your collection"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        disabled={isSubmitting}
                        onBlur={formik.handleBlur}
                        data-testid="edit-collection-description-input"
                      />
                    </Grid.Item>
                    {formik.values.canEditCollectionOrgs && (
                      <Grid.Item xs={12} className="hc-pv-none">
                        <CollectionPublicPrivate makeCollectionPublic={setIsPublic} collectionPublic={isPublic} />
                      </Grid.Item>
                    )}
                  </Grid.Container>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Grid.Container direction="column">
                    <Grid.Item xs={12}>
                      <Input.Label className="hc-mb-none hc-pb-sm bold-asset-text">
                        Assets in collection ({formik.values.assets.length})
                      </Input.Label>
                      <Grid.Container direction="column">
                        {some(formik.values.assets) && (
                          <Grid.Item xs={12} className="hc-pt-dense">
                            <div className="edit-collection-assetList" data-testid="edit-collection-asset-list">
                              <Table>
                                <Table.Body>
                                  {formik.values.assets.map((asset, index) => (
                                    <Table.Row key={index} align="center">
                                      <Table.Data
                                        xs={9}
                                        className="hc-pl-none collection-asset"
                                        data-testid={`asset_in_collection_${asset.id}`}
                                      >
                                        {asset.name}
                                      </Table.Data>
                                      <Table.Data xs={3} className="hc-ta-right">
                                        <Button
                                          iconOnly
                                          size="dense"
                                          data-testid={`remove_asset_${asset.id}`}
                                          onClick={() => {
                                            const newAssets = [...formik.values.assets]
                                            newAssets.splice(index, 1)
                                            formik.setFieldValue('assets', newAssets)
                                          }}
                                        >
                                          <FontAwesomeIcon icon="trash" className="trash-icon" />
                                        </Button>
                                      </Table.Data>
                                    </Table.Row>
                                  ))}
                                </Table.Body>
                              </Table>
                            </div>
                          </Grid.Item>
                        )}
                      </Grid.Container>
                    </Grid.Item>
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Grid.Container direction="row" justify="flex-end" align="center" className="hc-mb-none hc-mt-none">
                <Button type="secondary" data-testid="edit-collection-cancel" onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="submit-button"
                  onClick={() => formik.submitForm()}
                  disabled={isSubmitting || (!formik.isValid && !!formik.touched)}
                  data-testid="edit-collection-submit"
                >
                  {isSubmitting && <Spinner size="dense" />}
                  {!isSubmitting && 'Save'}
                </Button>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </form>
      </div>
    </Modal>
  )
}

export default EditCollectionModal
